import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

import { updateWhitelist, removeWhitelist } from "../../services";
import getIcon from "../../helpers/getIcon";
import handleNotify from "../../helpers/handleNotify";
import styles from "./WhitelistItem.module.scss";

import Modal from "../Modal/Modal";
import ConfirmAction from "../ConfirmAction/ConfirmAction";

const WhitelistItem = ({ whitelistItem, _getWhitelist }) => {
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const getNetwork = (name) => {
    switch (name) {
      case "matic":
        return "MATIC_MAINNET";
      case "bsc":
        return "BSC_MAINNET";
      case "arbitrum":
        return "ARBITRUM_MAINNET";
      default:
        return name;
    }
  };

  const { isLoading: isRemovingWhitelist, mutate: _removeWhitelist } =
    useMutation(
      ["remove whitelist Item"],
      () => removeWhitelist(whitelistItem?.id),
      {
        onSuccess: (data) => {
          handleNotify("success", "Whitelist deleted successfully");
          setLoading(false);
          _getWhitelist();
        },
        onError: (error) => {
          console.log({ error });
          handleNotify(
            "error",
            error?.response?.data?.message ?? "An error occurred"
          );
        },
      }
    );

  const { isLoading: isUpdatingWhitelist, mutate: _updateWhitelist } =
    useMutation(
      ["update whitelist"],
      (payload) => updateWhitelist(whitelistItem?.id, payload),
      {
        onSuccess: (data) => {
          _getWhitelist();
          handleNotify("success", "Item updated successfully");
        },
        onError: (error) => {
          console.log({ error });
          console.log(
            error?.response?.data?.message,
            "error occurred on update"
          );
          handleNotify(
            "error",
            error?.response?.data?.message
              ? Array.isArray(error?.response?.data?.message)
                ? error?.response?.data?.message[0]
                : error?.response?.data?.message
              : "An error occurred"
          );
        },
      }
    );

  return (
    <>
      <div
        className={
          whitelistItem?.isDefault
            ? `${styles.whitelistItem} ${styles.whitelistItem__active}`
            : `${styles.whitelistItem}`
        }
      >
        {isRemovingWhitelist || isUpdatingWhitelist ? (
          <div className={styles.whitelistItem__activate}>
            <button>
              <p>Please wait</p>{" "}
              <ReactLoading
                type="bubbles"
                width={32}
                height={32}
                color="#4438cb"
              />
            </button>
          </div>
        ) : null}
        <div className={styles.whitelistItem__group}>
          <div className={styles.whitelistItem__group__item}>
            <div>
              {whitelistItem?.coin && whitelistItem?.network ? (
                <>
                  <img
                    src={getIcon(getNetwork(whitelistItem?.network))}
                    alt="network"
                  />
                  <img src={getIcon(whitelistItem?.coin)} alt="token" />
                </>
              ) : (
                "-"
              )}
            </div>
            <p>
              {whitelistItem?.address?.slice(0, 10)}...
              {whitelistItem?.address?.slice(-10)}
            </p>
          </div>
        </div>

        <div className={styles.whitelistItem__actions}>
          {whitelistItem?.isDefault ? (
            <button
              onClick={() =>
                _updateWhitelist({
                  isDefault: false,
                  address: whitelistItem?.address,
                })
              }
              className={styles.whitelistItem__removeAsDefault}
            >
              Remove as default
            </button>
          ) : (
            <button
              onClick={() =>
                _updateWhitelist({
                  isDefault: true,
                  address: whitelistItem?.address,
                })
              }
              className={styles.whitelistItem__actions__setDefault}
            >
              Set as default
            </button>
          )}

          <button
            onClick={() => setShowConfirmDeleteModal(true)}
            className={styles.whitelistItem__actions__remove}
          >
            Delete
          </button>
        </div>
      </div>
      {showConfirmDeleteModal && (
        <Modal
          showModal={showConfirmDeleteModal}
          setShowModal={setShowConfirmDeleteModal}
        >
          <ConfirmAction
            action={() => {
              _removeWhitelist();
            }}
            heading="Are you sure you want to delete item?"
            // desc="This action is irriversible"
            setShowConfirmActionModal={setShowConfirmDeleteModal}
          />
        </Modal>
      )}
    </>
  );
};

export default WhitelistItem;
