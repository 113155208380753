import React, { useState } from "react";
import { X } from "phosphor-react";
import styles from "./Tag.module.scss";
import PasteIcon from "../../assets/icons/paste.svg";

const Tag = ({heading, desc, action}) => {
  const [showTag, setShowTag] = useState(true);
  return (
    <>
      {showTag ?
      (
        <div onClick={() => action()} className={styles.tag}>
          <div className={styles.tag__icon}>
            <img src={PasteIcon} alt="paste icon" />
          </div>
          <div className={styles.tag__content}>
            <h3>{heading}</h3>
            <p>{desc}</p>
          </div>
          <div onClick={() => setShowTag(false)} className={styles.tag__close}>
            <X size={17} color="#686868" />
          </div>
        </div>
      ): null}
    </>
  );
};

export default Tag;
