import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import ReactLoading from "react-loading";
import getIcon from "../../../helpers/getIcon";
import { getAllRates } from "../../../services";
import handleNotify from "../../../helpers/handleNotify";
import styles from "./Rates.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
import ArrowRight from "../../../assets/icons/arrow-right-variant.svg";

//components
import BottomNav from "../../../components/BottomNav/BottomNav";

const Rates = ({
  data: user,
  setEnabled: setEnabledGetUser,
  getUserDetails,
  isLoadingUserDetails,
}) => {
  const navigate = useNavigate();
  const [rates, setRates] = useState([]);

  const {
    isLoading: isGettingRates,
    refetch: _getRates,
    data: allRates,
  } = useQuery(["get all exchange rates"], () => getAllRates(), {
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.log({ error });
      handleNotify(
        "error",
        error?.response?.data?.message ?? "An error occurred"
      );
    },
  });

  const handleSendRedirect = (currency) => {
    switch (currency) {
      case "KES":
        navigate(`/dashboard/send/foreign-africa/form?country=KE&currency=KES`);
        break;
      case "GHS":
        navigate(`/dashboard/send/foreign-africa/form?country=GH&currency=GHS`);
        break;
      case "NGN":
        navigate(`/dashboard/send/form?country=NG&currency=NGN`);
        break;
      case "GBP":
        navigate(`/dashboard/send/form?country=GB&currency=GBP`);
        break;
      default:
        break;
    }
  };

  const handleReceiveRedirect = (currency) => {
    switch (currency) {
      case "NGN":
        navigate(`/dashboard/receive?active=NGN`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setEnabledGetUser(true);
    getUserDetails();
  }, [getUserDetails]);

  useEffect(() => {
    if (allRates && Array.isArray(allRates)) {
      const _rates = allRates?.flatMap((rate) =>
        rate?.currency === "NGN"
          ? [
              {
                rate: rate?.rates?.sellRate,
                to: rate?.currency,
                from: "USDT",
                type: "sell",
              },
              {
                rate: rate?.rates?.buyRate,
                to: "USDT",
                from: rate?.currency,
                type: "buy",
              },
            ]
          : [
              {
                rate: rate?.rates?.sellRate,
                to: rate?.currency,
                from: "USDT",
                type: "sell",
              },
            ]
      );
      console.log(_rates);
      setRates(_rates);
    }
  }, [allRates]);

  return (
    <>
      <div className={styles.rates}>
        <div className={styles.rates__content}>
          <div onClick={() => navigate(-1)} className={styles.rates__back}>
            <img src={BackIcon} alt="back icon" />
          </div>

          <h3 className={styles.rates__heading}>Rates</h3>
          <p className={styles.rates__desc}>
            Below are the current market rates
          </p>
          {isGettingRates ? (
            <p className={styles.rates__loading}>
              <span>Loading</span>{" "}
              <ReactLoading
                type="bubbles"
                width={32}
                height={32}
                color="#4438cb"
              />
            </p>
          ) : null}

          <div className={styles.rates__items}>
            {rates?.map((rate) => (
              <div className={styles.rates__item}>
                <div className={styles.rates__item__content}>
                  <img
                    className={styles.rates__item__fromIcon}
                    src={getIcon(rate?.from)}
                    alt="from currency icon"
                  />
                  <p className={styles.rates__item__fromCurrency}>
                    {rate?.from}
                  </p>
                  <img
                    className={styles.rates__item__arrow}
                    src={ArrowRight}
                    alt="arrow right"
                  />
                  <img
                    className={styles.rates__item__toIcon}
                    src={getIcon(rate?.to)}
                    alt="to currency icon"
                  />
                  <p className={styles.rates__item__toCurrency}>{rate?.to}</p>
                  <h3>{Number(rate?.rate).toFixed(2)}</h3>
                </div>

                {rate?.type === "sell" ? (
                  <button
                    onClick={() =>
                      user?.user?.userSettings?.kycTeaserStatus !==
                        "SUCCESSFUL" &&
                      user?.user?.userSettings?.kycStandardStatus !==
                        "SUCCESSFUL"
                        ? handleNotify(
                            "error",
                            "K.Y.C verification required to access this page, kindly visit the verification page to complete this process"
                          )
                        : handleSendRedirect(rate?.to)
                    }
                  >
                    send {rate?.to}
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      user?.user?.userSettings?.kycTeaserStatus !==
                        "SUCCESSFUL" &&
                      user?.user?.userSettings?.kycStandardStatus !==
                        "SUCCESSFUL"
                        ? handleNotify(
                            "error",
                            "K.Y.C verification required to access this page, kindly visit the verification page to complete this process"
                          )
                        : handleReceiveRedirect(rate?.from)
                    }
                  >
                    receive {rate?.to}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
        <BottomNav active="rates" />
      </div>
    </>
  );
};

export default Rates;
