import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import Guard from "./Guard";
import Layout from "../layout/Layout";

// pages
import Home from "../pages/Dashboard/Home/Home";
import Login from "../pages/Login/Login";
import OTP from "../pages/OTP/OTP";
import SetPin from "../pages/SetPin/SetPin";
import ConfirmPin from "../pages/ConfirmPin/ConfirmPin";
import Registration from "../pages/Registration/Registration";
import Transactions from "../pages/Dashboard/Transactions/Transactions";
import CardDetails from "../pages/Dashboard/CardDetails/CardDetails";
import NotAvailable from "../pages/NotAvailable/NotAvailable";
import KYC from "../pages/KYC/KYC";
import Processing from "../components/Processing/Processing";
import Failed from "../components/Failed/Failed";
import LandingPage from "../pages/Home/Home";
import Send from "../pages/Dashboard/Send/Send";
import More from "../pages/Dashboard/More/More";
import Verification from "../pages/Dashboard/Verification/Verification";
import SendForm from "../pages/Dashboard/SendForm/SendForm";
import SendGhana from "../pages/Dashboard/SendGhana/SendGhana";
import SendStepTwo from "../pages/Dashboard/SendStepTwo/SendStepTwo";
import SendOTP from "../pages/Dashboard/SendOTP/SendOTP";
import TransferDetails from "../pages/Dashboard/TransferDetails/TransferDetails";
import Receive from "../pages/Dashboard/Receive/Receive";
import Authorizations from "../pages/Dashboard/Authorizations/Authorizations";
import Whitelist from "../pages/Dashboard/Whitelist/Whitelist";
import Rates from "../pages/Dashboard/Rates/Rates";
import EarnMoney from "../pages/Dashboard/EarnMoney/EarnMoney";
import AccountDetails from "../components/AccountDetails/AccountDetails";
import TransactionDetails from "../pages/Dashboard/TransactionDetails/TransactionDetails";
import BVN from "../pages/Dashboard/BVN/BVN";
import ForAndroid from "../pages/ForAndroid/ForAndroid";
import ForIOS from "../pages/ForIOS/ForIOS";

const routes = [
  {
    path: "/dashboard/transactions",
    name: "Transactions",
    component: Transactions,
    guard: true,
  },
  {
    path: "/dashboard/transaction",
    name: "TransactionDetails",
    component: TransactionDetails,
    guard: true,
  },
  {
    path: "/dashboard/authorizations",
    name: "Authorizations",
    component: Authorizations,
    guard: true,
  },
  {
    path: "/dashboard/whitelist",
    name: "Whitelist",
    component: Whitelist,
    guard: true,
  },
  {
    path: "/dashboard/earn-money",
    name: "EarnMoney",
    component: EarnMoney,
    guard: true,
  },
  {
    path: "/dashboard/rates",
    name: "Rates",
    component: Rates,
    guard: true,
  },
  {
    path: "/dashboard/account-details",
    name: "AccountDetails",
    component: AccountDetails,
    guard: true,
  },
  {
    path: "/dashboard/send",
    name: "Send",
    component: Send,
    guard: true,
  },
  {
    path: "/dashboard/more",
    name: "More",
    component: More,
    guard: true,
  },
  {
    path: "/dashboard/verification",
    name: "Verification",
    component: Verification,
    guard: true,
  },
  {
    path: "/dashboard/send/form",
    name: "SendForm",
    component: SendForm,
    guard: true,
  },
  {
    path: "/dashboard/send/foreign-africa/form",
    name: "SendGhana",
    component: SendGhana,
    guard: true,
  },
  {
    path: "/dashboard/send/form-two",
    name: "SendStepTwo",
    component: SendStepTwo,
    guard: true,
  },
  {
    path: "/dashboard/send/pin",
    name: "SendOTP",
    component: SendOTP,
    guard: true,
  },
  {
    path: "/dashboard/send/transfer-details",
    name: "TransferDetails",
    component: TransferDetails,
    guard: true,
  },
  {
    path: "/dashboard/receive",
    name: "Receive",
    component: Receive,
    guard: true,
  },
  {
    path: "/dashboard/card-details",
    name: "CardDetails",
    component: CardDetails,
    guard: true,
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Home,
    guard: true,
  },
  {
    path: "/auth/otp",
    name: "OTP",
    component: OTP,
    guard: false,
  },
  {
    path: "/auth/bvn",
    name: "BVN",
    component: BVN,
    guard: true,
  },
  {
    path: "/auth/kyc",
    name: "KYC",
    component: KYC,
    guard: true,
  },
  {
    path: "/auth/kyc/processing",
    name: "Processing",
    component: Processing,
    guard: true,
  },
  {
    path: "/auth/kyc/failed",
    name: "Failed",
    component: Failed,
    guard: true,
  },
  {
    path: "/auth/set-pin",
    name: "SetPin",
    component: SetPin,
    guard: false,
  },
  {
    path: "/auth/confirm-pin",
    name: "ConfirmPin",
    component: ConfirmPin,
    guard: false,
  },
  {
    path: "/auth/login",
    name: "Registration",
    component: Registration,
    guard: false,
  },
  {
    path: "/add/for-android",
    name: "ForAndroid",
    component: ForAndroid,
    guard: false,
  },
  {
    path: "/add/for-ios",
    name: "ForIOS",
    component: ForIOS,
    guard: false,
  },
  // {
  //   path: "/",
  //   name: "LandingPage",
  //   component: LandingPage,
  //   guard: false,
  // },
];
const Routes = () => {
  return (
    <>
      <Switch>
        {/* <Route path="/" element={<LandingPage />} /> */}
        <Route path="/not-available" element={<NotAvailable />} />
        {routes.map((route, index) => (
          <Route
            path={route?.path}
            key={index}
            element={
              <Guard guard={route?.guard} path={route?.path}>
                <Layout>
                  <route.component />
                </Layout>
              </Guard>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Switch>
    </>
  );
};

export default Routes;
