import React, { useState } from "react";
import styles from "./Notification.module.scss";

const Notification = ({ heading, content }) => {
  return (
    <div className={styles.notification}>
      <h3 className={styles.notification__heading}>{heading}</h3>
      <p className={styles.notification__desc}>{content}</p>
    </div>
  );
};

export default Notification;
