import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import getSymbol from "../../helpers/getSymbol";
import styles from "./Transaction.module.scss";

//images
import AppleLogo from "../../assets/images/apple.svg";
import TypeArrow from "../../assets/icons/left-arrow-circle.svg";

const Transaction = ({ transaction, currency }) => {
  const [imageIsBroken, setImageIsBroken] = useState(false);
  const navigate = useNavigate();

  const getColorCode = (status) => {
    const colorCode = {
      pending: {
        color: "#333333",
        background: "#33333309",
      },
      success: {
        color: " #27AE60",
        background: "#27ae5f09",
      },
      successful: {
        color: " #27AE60",
        background: "#27ae5f09",
      },
      failed: {
        color: "#E74C3C",
        background: "#E74C3C09",
      },
      unsuccessful: {
        color: "#E74C3C",
        background: "#E74C3C09",
      },
      initiated: {
        color: "#333333",
        background: "#33333309",
      },
      processing: {
        color: "#ffc400",
        background: "#ffc40009",
      },
    };
    return colorCode[status];
  };
  return (
    <div
      onClick={() => navigate(`/dashboard/transaction?id=${transaction?.id}`)}
      className={styles.transaction}
    >
      {imageIsBroken || !transaction?.logo ? (
        <div className={styles.transaction__logoBox}>
          <div className={styles.transaction__logoBox__logo}>
            <h3>
              {transaction?.info
                ? `${transaction?.info[0]}${transaction?.info[1]}`
                : `Ni`}
            </h3>
          </div>
          <div
            className={
              transaction?.category === "SEND" || transaction?.type === "DEBIT"
                ? `${styles.transaction__logoBox__arrow}`
                : `${styles.transaction__logoBox__arrow} ${styles.transaction__logoBox__arrow__received}`
            }
          >
            <img src={TypeArrow} alt="arrow for type" />
          </div>
        </div>
      ) : (
        <div className={styles.transaction__iconBox}>
          <div className={styles.transaction__icon}>
            <img
              onError={(e) => setImageIsBroken(true)}
              src={transaction?.logo}
              alt="icon for a transaction"
            />
          </div>
          <div
            className={
              transaction?.category === "SEND" || transaction?.type === "DEBIT"
                ? `${styles.transaction__iconBox__arrow}`
                : `${styles.transaction__iconBox__arrow} ${styles.transaction__iconBox__arrow__received}`
            }
          >
            <img src={TypeArrow} alt="arrow for type" />
          </div>
        </div>
      )}

      <div className={styles.transaction__desc}>
        <h4>{transaction ? transaction?.info ?? "UNKNOWN" : `UNKNOWN`}</h4>
        {/* Jan 25, 00:02 */}
        <p>{dateFormat(transaction?.updatedAt, "mmm dS, h:MM TT")}</p>
        <button
          style={{
            background: getColorCode(
              transaction?.status?.toLowerCase() ?? "pending"
            )?.background,
            color: getColorCode(transaction?.status?.toLowerCase() ?? "pending")
              ?.color,
          }}
        >
          {transaction?.status?.toLowerCase()}
        </button>
      </div>

      <div className={styles.transaction__amount}>
        <span>{transaction?.type === "DEBIT" ? "-" : "+"}</span>
        {getSymbol(transaction?.fiatCurrency)}
        {Number(transaction?.fiatAmount)?.toLocaleString()}
      </div>
    </div>
  );
};

export default Transaction;
