import React, { useState, useEffect } from "react";
import styles from "./SetPin.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputPin from "../../components/InputPin/InputPin";

const SetPin = ({ setNewPin, data }) => {
  const [pin, setPin] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(data, "set-pin");
    if (!data || data?.user?.userSettings?.hasSetTransactionPin) {
      navigate("/dashboard");
    }
  }, [data, navigate]);

  const handleVerification = () => {
    if (!pin || pin?.length !== 6) {
      toast.error("Kindly provided a valid six digit pin", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    setNewPin(pin);
    navigate("/auth/confirm-pin");
  };

  return (
    <div className={styles.setPin}>
      <h2 className={styles.setPin__heading}>Set Pin</h2>
      <p className={styles.setPin__desc}>
        Create a 6 digit security pin for your Spend account. You will require
        this pin whenever you login.
      </p>

      <div className={styles.setPin__input}>
        <InputPin value={pin} setValue={setPin} />
      </div>

      <button
        onClick={() => handleVerification()}
        className={styles.setPin__submit}
      >
        Continue
      </button>
    </div>
  );
};

export default SetPin;
