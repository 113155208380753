import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ReceiveDirectDebit.module.scss";

const ReceiveDirectDebit = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.receiveDirectDebit}>
      <h3 className={styles.receiveDirectDebit__heading}>
        Important: Action Required
      </h3>
      <p className={styles.receiveDirectDebit__desc}>
        To ensure a smooth and timely transfer of your received funds, you need
        to set up your Direct Debit wallet. If you haven't done so yet, please
        take a few minutes to complete this crucial step. All your received
        funds will be directly transferred to this as stablecoin.
      </p>
      <div className={styles.receiveDirectDebit__actions}>
        <button
          onClick={() => navigate("/dashboard/authorizations")}
          className={styles.receiveDirectDebit__submit}
        >
          Set Up Direct Debit Now
        </button>
      </div>
    </div>
  );
};

export default ReceiveDirectDebit;
