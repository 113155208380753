import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { verifyBvn } from "../../../services";
import handleNotify from "../../../helpers/handleNotify";
import styles from "./BVN.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";

//components
import InputPin from "../../../components/InputPinVariant/InputPin";

const BVN = ({ data: user }) => {
  const [pin, setPin] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const navigate = useNavigate();

  const { isLoading, mutate: _verifyBvn } = useMutation(
    ["send fait"],
    (payload) => verifyBvn(payload),
    {
      onSuccess: (data) => {
        console.log(data);

        handleNotify("success", "BVN verification successful");

        navigate("/auth/kyc");
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleVerification = () => {
    if (!pin || pin?.length !== 11) {
      handleNotify("error", "BVN number isn't complete");
      return;
    }

    _verifyBvn({
      bvn: pin,
    });
  };

  useEffect(() => {
    if (pin?.length === 11) {
      handleVerification();
    }
  }, [pin]);

  useEffect(() => {
    console.log(user, "kyc");
    if (!user) {
      navigate("/dashboard");
    } else if (user?.user?.kycStatus === "SUCCESSFUL") {
      navigate("/auth/kyc");
    }
  }, [user, navigate]);

  return (
    <>
      <div className={styles.bvn}>
        <div onClick={() => navigate(-1)} className={styles.bvn__back}>
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.bvn__heading}>Add Your B.V.N</h3>
        <p className={styles.bvn__desc}>
          Enter your 11 digit bank verification number.{" "}
        </p>

        <div className={styles.bvn__input}>
          <InputPin
            value={pin}
            setValue={setPin}
            style={{ background: "#fff" }}
          />
        </div>

        <button
          onClick={() => handleVerification()}
          className={styles.bvn__submit}
        >
          {isLoading ? (
            <ReactLoading type="spin" width={28} height={28} />
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </>
  );
};

export default BVN;
