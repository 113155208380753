import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import getIcon from "../../../helpers/getIcon";
import { momoOperators, validateMobileMoneyDetails } from "../../../services";
import handleNotify from "../../../helpers/handleNotify";
import ReactLoading from "react-loading";
import styles from "./SendGhana.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";

//components
import SelectWallet from "../../../components/SelectWallet/SelectWallet";
import Modal from "../../../components/Modal/Modal";
import UpgradeAccount from "../../../components/UpgradeAccount/UpgradeAccount";
import Transfer from "../SendStepTwo/Transfer";
import Beneficiaries from "../../../components/Beneficiaries/Beneficiaries";
const SendGhana = ({
  data: user,
  setEnabled: setEnabledGetUser,
  getUserDetails,
  isLoadingUserDetails,
}) => {
  const navigate = useNavigate();
  const [activeOperator, setActiveOperator] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [country, setCountry] = useState();
  const [currency, setCurrency] = useState();
  const [operators, setOperators] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState();
  const [accountName, setAccountName] = useState();
  const [upgradeRequired, setUpgradeRequired] = useState();

  useEffect(() => {
    if (!user) {
      setEnabledGetUser(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  const getCountryCode = (country) => {
    const countryCode = {
      NG: "+234",
      KE: "+254",
      GH: "+233",
    };
    return countryCode[country];
  };

  const { isLoading: isGettingOperators } = useQuery(
    ["get momo operators", country],
    () => momoOperators(country),
    {
      enabled: country ? true : false,
      onSuccess: (data) => {
        console.log(data, "operators");
        const _operators = data?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?.id,
        }));
        setOperators(_operators);
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const {
    isLoading: isValidatingDetails,
    refetch: _validateMobileMoneyDetails,
    data,
  } = useQuery(
    ["validate details"],
    () =>
      validateMobileMoneyDetails(
        currency,
        fullName,
        encodeURIComponent(`${getCountryCode(country)}${phoneNumber}`),
        activeOperator?.id
      ),
    {
      enabled: enabled,
      cacheTime: 0,
      onSuccess: (data) => {
        const _details = {
          momoOperatorId: activeOperator?.id,
          beneficiaryName: fullName,
          accountName: fullName,
          mobileNumber: encodeURIComponent(
            `${getCountryCode(country)}${phoneNumber}`
          ),
          beneficiaryCurrency: currency,
          currency,
        };

        setTimeout(() => {
          navigate(
            `/dashboard/send/form-two?details=${JSON?.stringify(_details)}`
          );
        }, 1000);
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleSubmit = () => {
    if (!activeOperator) {
      handleNotify("error", "Operator is required");
      return;
    }
    if (!phoneNumber) {
      handleNotify("error", "Account number is required");
      return;
    }
    if (!fullName) {
      handleNotify("error", "Full name is required");
      return;
    }
    setEnabled(true);
    _validateMobileMoneyDetails();
  };

  useEffect(() => {
    const _country = searchParams.get("country");
    const _currency = searchParams.get("currency");
    if (_country) {
      setCountry(_country);
      setCurrency(_currency);
      return;
    }
    navigate("/dashboard/send");
  }, []);

  const handleRedirect = () => {
    if (
      user?.locale === "NG" ||
      user?.locale === "KE" ||
      user?.locale === "GH"
    ) {
      navigate(`/auth/kyc?kyc_tier=TEASER`);
    } else {
      navigate(`/auth/kyc?kyc_tier=STANDARD`);
    }
  };

  useEffect(() => {
    if (
      user &&
      user?.user?.userSettings?.kycTeaserStatus !== "SUCCESSFUL" &&
      user?.user?.userSettings?.kycStandardStatus !== "SUCCESSFUL"
    ) {
      setUpgradeRequired(true);
    } else {
      setUpgradeRequired(false);
    }
  }, [user]);

  return (
    <>
      <div className={styles.sendGhana}>
        <div onClick={() => navigate(-1)} className={styles.sendGhana__back}>
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.sendGhana__heading}>Send</h3>
        <p className={styles.sendGhana__desc}>
          Send money to fiat bank accounts and wallets worldwide using crypto.
        </p>

        <Beneficiaries type="momo" country={country} currency={currency} />

        <div className={styles.sendGhana__form}>
          <div className={styles.sendGhana__form__item}>
            <label>Mobile Money Operator</label>
            <SelectWallet
              options={operators}
              active={activeOperator}
              setActive={setActiveOperator}
              placeholder="Select mobile money operator"
            />
          </div>

          <div className={styles.sendGhana__form__item}>
            <label>Full Name</label>
            <input
              className={styles.sendGhana__form__item__input}
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className={styles.sendGhana__form__item}>
            <label>Phone Number</label>
            <div className={styles.sendGhana__form__item__phone}>
              <div className={styles.sendGhana__form__item__phone__countryCode}>
                <img src={getIcon(currency)} alt={`${country}'s flag`} />
                <input
                  className={styles.sendGhana__form__item__input}
                  type="string"
                  value={getCountryCode(country)}
                  onChange={() => {}}
                />
              </div>
              <input
                className={styles.sendGhana__form__item__input}
                type="number"
                value={phoneNumber}
                onChange={(e) =>
                  e.target?.value?.length > 10
                    ? null
                    : setPhoneNumber(e.target.value)
                }
              />
            </div>
          </div>

          <p className={styles.sendGhana__form__data}>{accountName}</p>

          <div
            onClick={() => handleSubmit()}
            className={styles.sendGhana__form__submit}
          >
            {isValidatingDetails ? (
              <ReactLoading type="spin" width={28} height={28} />
            ) : (
              "Continue"
            )}
          </div>
        </div>
      </div>
      {upgradeRequired && (
        <Modal
          showModal={upgradeRequired}
          setShowModal={setUpgradeRequired}
          cancelDisabled={true}
        >
          <UpgradeAccount
            heading="K.Y.C verification required"
            desc="K.Y.C verification is required to access this page"
            action={handleRedirect}
            setShowModal={setUpgradeRequired}
          />
        </Modal>
      )}
    </>
  );
};

export default SendGhana;
