import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import cookie from "../../utils/cookie";
import { getAccessToken, onboardingUploadEnded } from "../../services";
import styles from "./KYC.module.scss";

import SumsubWebSdk from "@sumsub/websdk-react";

import Processing from "../Processing/Processing";
import Loader from "../Loader/Loader";

const KYC = ({ user }) => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  const [applicantId, setApplicantId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(user, "kyc");
    if (!user || user?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL") {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const {
    isLoading: isGettingToken,
    data,
    mutate: _getAccessToken,
  } = useMutation(["get access token"], (payload) => getAccessToken(payload), {
    onSuccess: (data) => {
      console.log(data, "access token");
      setAccessToken(data?.kycAccessToken);
      setApplicantId(data?.applicantId);
    },
    onError: (error) => {
      console.log({ error });
      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  const { isLoading: isLoadingUploadEnded, mutate: _onboardingUploadEnded } =
    useMutation(["upload ended"], (payload) => onboardingUploadEnded(payload), {
      onSuccess: (data) => {
        console.log(data, "onboarding upload completed");
        setLoading(false);
        navigate("/dashboard");
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    });

  useEffect(() => {
    _getAccessToken({});
  }, []);

  return (
    <>
      {user && user?.user?.userSettings?.kycStandardStatus === "PROCESSING" ? (
        <Navigate to="/auth/kyc/processing?kyc_tier=STANDARD" />
      ) : (
        <div className={styles.kyc}>
          {isGettingToken ? (
            <div className={styles.kyc__main}>
              <p>Loading...</p>
            </div>
          ) : (
            <SumsubWebSdk
              //testEnv={true}
              accessToken={accessToken}
              applicantId={applicantId}
              expirationHandler={() => accessToken}
              // config={{
              //   lang: "zh-tw",
              //   // email: "test@gmail.com",
              //   // phone: "0912234456"
              // }}
              // options={{ addViewportTag: false, adaptIframeHeight: true }}
              onMessage={(data, payload) => {
                console.log("onMessage", data, payload);
                if (
                  payload?.reviewResult?.reviewAnswer === "GREEN" ||
                  payload?.reviewStatus === "completed"
                ) {
                  setLoading(true);
                  setTimeout(() => {
                    _onboardingUploadEnded({});
                  }, 5000);
                }
              }}
              onError={(data) => {
                console.log("onError", data);
                toast.error(
                  data?.response?.data?.message ?? "An error occurred",
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  }
                );
                // window.location.href = `/auth/kyc/failed?kyc_tier=STANDARD`;
              }}
            />
          )}
        </div>
      )}
      {isLoadingUploadEnded ? <Loader /> : null}
    </>
  );
};

export default KYC;
