import React, { useState, useEffect } from "react";
import styles from "./OTP.module.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { userLoginFinalize } from "../../services";
import cookie from "../../utils/cookie";
import InputPin from "../../components/InputPin/InputPin";

const OTP = ({ setEnabled }) => {
  const [pin, setPin] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const [otpIdentifier, setOtpIdentifier] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const { isLoading, mutate: _userLoginFinalize } = useMutation(
    ["user login finalize"],
    (payload) => userLoginFinalize(payload),
    {
      onSuccess: (data) => {
        cookie().setCookie("spend-token", data?.access_token);
       // setEnabled(true);
        console.log(data);
       
          //navigate("/auth/set-pin");
        navigate("/dashboard");
    
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const handleVerification = () => {
    if (!pin || pin?.length !== 6) {
      toast.error("Kindly provided a valid OTP code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    _userLoginFinalize({
      code: Number(pin),
      otp_identifier: otpIdentifier,
    });
  };

  useEffect(() => {
    const otp_identifier = searchParams.get("otp_identifier");
    const _email = searchParams.get("email");
    if (otp_identifier && _email) {
      setOtpIdentifier(otp_identifier);
      setEmail(_email);
      return;
    }
    navigate("/auth/login");
  }, []);

  useEffect(() => {
    const token = cookie().getCookie("spend-token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (pin?.length === 6) {
      handleVerification();
    }
  }, [pin]);

  return (
    <div className={styles.otp}>
      <h2 className={styles.otp__heading}>O.T.P</h2>
      <p className={styles.otp__desc}>
        Enter the 6 digit confirmation code we sent to your email{" "}
        {email} below.
      </p>

      <div className={styles.otp__input}>
        <InputPin value={pin} setValue={setPin} />
      </div>

      <button
        onClick={() => handleVerification()}
        className={styles.otp__submit}
      >
        {isLoading ? (
          <ReactLoading type="spin" width={28} height={28} />
        ) : (
          "Continue"
        )}
      </button>
    </div>
  );
};

export default OTP;
