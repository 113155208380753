import { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import Routes from "./router/Routes";
import styles from "./App.module.scss";
import "react-toastify/dist/ReactToastify.css";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, bsc } from "wagmi/chains";

const chains = [arbitrum, bsc, polygon];
const projectId = "4c1e2dfad5809ec3cb44fe7e754a6445";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  const queryClient = new QueryClient();

  // useEffect(() => {
  //   boot();

  //   return () => {
  //     shutdown();
  //   };
  // }, []);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig config={wagmiConfig}>
          <div className={styles.spend}>
            <div className={styles.spend__main}>
              <Router>
                <Routes />
              </Router>
            </div>
          </div>
        </WagmiConfig>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
