import React from "react";
import Dojah from "react-dojah";
import handleNotify from "../../helpers/handleNotify";

const KYCDojah = ({ user, isSuccessful, setIsSuccessful, kycTier }) => {
  const appID = "64de050b461185004097d067";

  const publicKey = "prod_pk_lyFb4Q61Mz2ENgakG8NtrhzJV";

  const type = "verification";

  const config = {
    debug: true,
    widget_id:
      kycTier === "TEASER" || true
        ? "64de06894611850040982789"
        : "64df5fc34611850040b2d38c",
    webhook: true,
  };

  const metadata = {
    user_id: user?.id,
    kyc_tier: kycTier,
  };

  const response = (type, data) => {
    console.log(type, data);
    if (type === "success") {
      setIsSuccessful(true);
      window.location.href = `/auth/kyc/processing?kyc_tier=${kycTier}`;
      return;
    }
    if (type === "error") {
      handleNotify("error", "An error occurred");
      window.location.href = `/auth/kyc/failed?kyc_tier=${kycTier}`;
      return;
    }
    if (type === "close") {
      isSuccessful
        ? (window.location.href = `/auth/kyc/processing?kyc_tier=${kycTier}`)
        : (window.location.href = `/auth/kyc/failed?kyc_tier=${kycTier}`);

      return;
    }
  };

  return (
    <Dojah
      response={response}
      appID={appID}
      publicKey={publicKey}
      metadata={metadata}
      type={type}
      config={config}
    />
  );
};

export default KYCDojah;
