import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getBanks,
  getAccountDetails,
  getBeneficiaries,
} from "../../../services";
import handleNotify from "../../../helpers/handleNotify";
import ReactLoading from "react-loading";
import styles from "./SendForm.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";

//components
import SelectWallet from "../../../components/SelectWallet/SelectWallet";
import Modal from "../../../components/Modal/Modal";
import UpgradeAccount from "../../../components/UpgradeAccount/UpgradeAccount";
import Beneficiaries from "../../../components/Beneficiaries/Beneficiaries";

const SendForm = ({
  data: user,
  setEnabled: setEnabledGetUser,
  getUserDetails,
  isLoadingUserDetails,
}) => {
  const navigate = useNavigate();
  const [activeBank, setActiveBank] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [country, setCountry] = useState();
  const [currency, setCurrency] = useState();
  const [banks, setBanks] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [accountNumber, setAccountNumber] = useState();
  const [accountName, setAccountName] = useState();
  const [upgradeRequired, setUpgradeRequired] = useState(false);

  const { isLoading: isGettingBanks, refetch: _getBanks } = useQuery(
    ["get banks", country],
    () => getBanks(country),
    {
      enabled: country ? true : false,
      onSuccess: (data) => {
        console.log(data, "countries");
        const _banks = data?.map((item) => ({ ...item, label: item?.name }));
        setBanks(_banks);
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const {
    isLoading: isGettingAccountDetails,
    refetch: _getAccountDetails,
    data,
  } = useQuery(
    ["get account details"],
    () =>
      getAccountDetails(
        activeBank?.id,
        accountNumber,
        currency,
        accountName,
        country
      ),
    {
      enabled: enabled,
      cacheTime: 0,
      onSuccess: (data) => {
        if (country !== "GB") {
          setAccountName(data?.account_name);
        }
        const _details = {
          bankId: activeBank?.id,
          accountNumber: data?.account_number,
          accountName: accountName ?? data?.account_name,
          currency: currency,
        };

        setTimeout(() => {
          navigate(
            `/dashboard/send/form-two?details=${JSON?.stringify(_details)}`
          );
        }, 1000);
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleSubmit = () => {
    if (!activeBank) {
      handleNotify("error", "Bank is required");
      return;
    }
    if (!accountNumber && country === "GB") {
      handleNotify("error", "Account name is required");
      return;
    }
    if (!accountNumber) {
      handleNotify("error", "Account number is required");
      return;
    }
    setEnabled(true);
    _getAccountDetails();
  };

  useEffect(() => {
    const _country = searchParams.get("country");
    const _currency = searchParams.get("currency");
    if (_country && _currency) {
      setCountry(_country);
      setCurrency(_currency);
      return;
    }
    navigate("/dashboard/send");
  }, []);

  useEffect(() => {
    if (!user) {
      setEnabledGetUser(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  useEffect(() => {
    if (
      user &&
      user?.user?.userSettings?.kycTeaserStatus !== "SUCCESSFUL" &&
      user?.user?.userSettings?.kycStandardStatus !== "SUCCESSFUL"
    ) {
      setUpgradeRequired(true);
    } else {
      setUpgradeRequired(false);
    }
  }, [user]);

  const handleRedirect = () => {
    if (
      user?.locale === "NG" ||
      user?.locale === "KE" ||
      user?.locale === "GH"
    ) {
      navigate(`/auth/kyc?kyc_tier=TEASER`);
    } else {
      navigate(`/auth/kyc?kyc_tier=STANDARD`);
    }
  };

  return (
    <>
      <div className={styles.sendForm}>
        <div onClick={() => navigate(-1)} className={styles.sendForm__back}>
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.sendForm__heading}>Send</h3>
        <p className={styles.sendForm__desc}>
          Send money to fiat bank accounts and wallets worldwide using crypto.
        </p>
        <Beneficiaries country={country} currency={currency} />

        <div className={styles.sendForm__form}>
          <div className={styles.sendForm__form__item}>
            <label>Bank</label>
            <SelectWallet
              options={banks}
              active={activeBank}
              setActive={setActiveBank}
              placeholder="Select Bank"
            />
          </div>

          {country === "GB" && (
            <div className={styles.sendForm__form__item}>
              <label>Account name</label>
              <input
                className={styles.sendForm__form__item__input}
                type="text"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </div>
          )}

          <div className={styles.sendForm__form__item}>
            <label>Account number</label>
            <input
              className={styles.sendForm__form__item__input}
              type="tel"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </div>

          {country === "NG" && (
            <p className={styles.sendForm__form__data}>{accountName}</p>
          )}

          <div
            onClick={() => handleSubmit()}
            className={styles.sendForm__form__submit}
          >
            {isGettingAccountDetails ? (
              <ReactLoading type="spin" width={28} height={28} />
            ) : (
              "Continue"
            )}
          </div>
        </div>
      </div>
      {upgradeRequired && (
        <Modal
          showModal={upgradeRequired}
          setShowModal={setUpgradeRequired}
          cancelDisabled={true}
        >
          <UpgradeAccount
            heading="K.Y.C verification required"
            desc="K.Y.C verification is required to access this page"
            action={handleRedirect}
            setShowModal={setUpgradeRequired}
          />
        </Modal>
      )}
    </>
  );
};

export default SendForm;
