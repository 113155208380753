import React, { useState } from "react";
import styles from "./TransactionFilter.module.scss";

import FilterVirtualCards from "../FilterVirtualCards/FilterVirtualCards";

const TransactionFilter = ({
  handleFilterByType,
  setShowTransactionFilter,
}) => {
  const [step, setStep] = useState("options");
  return (
    <>
      {step === "options" && (
        <div className={styles.transactionFilter}>
          <div className={styles.transactionFilter__heading}>
            <h3>Transaction Filter</h3>
            <p>Filter transactions for all your virtual cards.</p>
          </div>

          <div className={styles.transactionFilter__options}>
            <div
              onClick={() => setStep("filter-virtual-cards")}
              className={styles.transactionFilter__option}
            >
              <p>Virtual Cards</p>
            </div>

            <div
              onClick={() => {
                handleFilterByType("DEBIT");
                setShowTransactionFilter(false);
              }}
              className={styles.transactionFilter__option}
            >
              <p>Send</p>
            </div>

            <div
              onClick={() => {
                handleFilterByType("CREDIT");
                setShowTransactionFilter(false);
              }}
              className={styles.transactionFilter__option}
            >
              <p>Receive</p>
            </div>
          </div>
        </div>
      )}

      {step === "filter-virtual-cards" && (
        <FilterVirtualCards setStep={setStep} />
      )}
    </>
  );
};

export default TransactionFilter;
