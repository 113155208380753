import React from "react";
import styles from "./QuickAccess.module.scss";

//images
import DataIcon from "../../assets/icons/data.png";
import AirtimeIcon from "../../assets/icons/airtime.png";
import BetIcon from "../../assets/icons/bet.png";
import GiftcardIcon from '../../assets/icons/git-card.png'

const QuickAccess = ({ setQuickAccessPending }) => {
  return (
    <div className={styles.quickAccess}>
      <h3 className={styles.quickAccess__heading}>Quick Access</h3>
      <div className={styles.quickAccess__items}>
        <div
          onClick={() => setQuickAccessPending(true)}
          className={styles.quickAccess__item}
        >
          <img src={AirtimeIcon} alt="airtime" />
          <p>Airtime</p>
        </div>
        <div  onClick={() => setQuickAccessPending(true)} className={styles.quickAccess__item}>
          <img src={DataIcon} alt="data bundle" />
          <p>Data Bundle</p>
        </div>
        <div  onClick={() => setQuickAccessPending(true)} className={styles.quickAccess__item}>
          <img src={BetIcon} alt="betting" />
          <p>Betting</p>
        </div>
        <div  onClick={() => setQuickAccessPending(true)} className={styles.quickAccess__item}>
          <img src={GiftcardIcon} alt="gift cards" />
          <p>Gift Cards</p>
        </div>
      </div>
    </div>
  );
};

export default QuickAccess;
