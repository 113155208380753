import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getIcon from "../../../helpers/getIcon";
import styles from "./Send.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";

//components
import BottomNav from "../../../components/BottomNav/BottomNav";
import UpgradeAccount from "../../../components/UpgradeAccount/UpgradeAccount";
import Modal from "../../../components/Modal/Modal";
import Loader from "../../../components/Loader/Loader";

const Send = ({
  data: user,
  setEnabled,
  getUserDetails,
  isLoadingUserDetails,
}) => {
  const navigate = useNavigate();
  const [upgradeRequired, setUpgradeRequired] = useState(false);

  const destinations = [
    {
      title: "NGN",
      desc: "Nigerian Naira",
      short: "NG",
      onClick: () => navigate(`/dashboard/send/form?country=NG&currency=NGN`),
    },
    {
      title: "GHS",
      desc: "Ghana Cedis",
      short: "GH",
      onClick: () =>
        navigate(`/dashboard/send/foreign-africa/form?country=GH&currency=GHS`),
    },
    {
      title: "KES",
      desc: "Kenyan Shilling",
      short: "KE",
      onClick: () =>
        navigate(`/dashboard/send/foreign-africa/form?country=KE&currency=KES`),
    },
    {
      title: "GBP",
      desc: "British Pound",
      short: "GBP",
      onClick: () => navigate(`/dashboard/send/form?country=GB&currency=GBP`),
    },
    {
      title: "CAD",
      desc: "Coming Soon",
      onClick: () => {},
    },
    {
      title: "EUR",
      desc: "Coming Soon",
      onClick: () => {},
    },
    // {
    //   title: "CAD",
    //   desc: "Coming Soon",
    // },
    // {
    //   title: "AED",
    //   desc: "Coming Soon",
    // },
    // {
    //   title: "AUD",
    //   desc: "Coming Soon",
    // },
    // {
    //   title: "CHF",
    //   desc: "Coming Soon",
    // },
  ];

  useEffect(() => {
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  // useEffect(() => {
  //   if (
  //     user?.user?.userSettings?.kycTeaserStatus !== "SUCCESSFUL" &&
  //     user?.user?.userSettings?.kycStandardStatus !== "SUCCESSFUL"
  //   ) {
  //     setUpgradeRequired(true);
  //     // console.log(user?.user?.userSettings?.kycStandardStatus);
  //   }
  // }, [user]);

  const handleRedirect = () => {
    if (
      user?.locale === "NG" ||
      user?.locale === "KE" ||
      user?.locale === "GH"
    ) {
      navigate(`/auth/kyc?kyc_tier=TEASER`);
    } else {
      navigate(`/auth/kyc?kyc_tier=STANDARD`);
    }
  };

  return (
    <>
      <div className={styles.send}>
        <div className={styles.send__content}>
          <div onClick={() => navigate(-1)} className={styles.send__back}>
            <img src={BackIcon} alt="back icon" />
          </div>

          <h3 className={styles.send__heading}>Send</h3>
          <p className={styles.send__desc}>
            Send money to fiat bank accounts and wallets worldwide using crypto.
          </p>

          <div className={styles.send__destinations}>
            {destinations?.map((destination) => (
              <div
                onClick={() => {
                  if (
                    user?.user?.userSettings?.kycTeaserStatus !==
                      "SUCCESSFUL" &&
                    user?.user?.userSettings?.kycStandardStatus !== "SUCCESSFUL"
                  ) {
                    setUpgradeRequired(true);
                    return;
                  }
                  destination?.onClick();
                }}
                className={
                  destination?.desc === "Coming Soon"
                    ? `${styles.send__destination} ${styles.send__destination__blurred}`
                    : `${styles.send__destination}`
                }
              >
                <img src={getIcon(destination?.title)} alt="united states" />
                <div className={styles.send__destination__content}>
                  <h4>{destination?.title}</h4>
                  <p>{destination?.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BottomNav active="send" />
      </div>
      {upgradeRequired && (
        <Modal
          showModal={upgradeRequired}
          setShowModal={setUpgradeRequired}
          cancelDisabled={true}
        >
          <UpgradeAccount
            heading="K.Y.C verification required"
            desc="K.Y.C verification is required to access this page"
            action={handleRedirect}
            setShowModal={setUpgradeRequired}
          />
        </Modal>
      )}
      {isLoadingUserDetails ? <Loader /> : null}
    </>
  );
};

export default Send;
