import ProfileOne from "../assets/profiles/profile-1.jpg";
import ProfileTwo from "../assets/profiles/profile-2.jpg";
import ProfileThree from "../assets/profiles/profile-3.jpg";
import ProfileFour from "../assets/profiles/profile-4.jpg";
import ProfileFive from "../assets/profiles/profile-5.jpg";
import ProfileSix from "../assets/profiles/profile-6.jpg";
import ProfileSeven from "../assets/profiles/profile-7.jpg";
import ProfileEight from "../assets/profiles/profile-8.jpg";

export default function getProfile() {
  const profiles = [
    ProfileOne,
    ProfileTwo,
    ProfileThree,
    ProfileSix,
    ProfileSeven,
    ProfileEight,
  ];

  return profiles[Math.floor(Math.random() * profiles.length)];
}
