import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { formatUnits, parseUnits } from "viem";
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
} from "wagmi";
import {
  setDefaultWallet,
  getContractAddress,
  removeAuthorization,
} from "../../services";
import getIcon from "../../helpers/getIcon";
import handleNotify from "../../helpers/handleNotify";
import styles from "./Authorization.module.scss";

import Modal from "../Modal/Modal";
import DeleteWallet from "../DeleteWallet/DeleteWallet";

const Authorization = ({
  authorization,
  handleActivate,
  handleIncreaseLimit,
  _getAuthorizations,
  account,
  index,
  setShowSetDefaultNotification,
}) => {
  const { chain } = useNetwork();
  const [enabled, setEnabled] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [contractAddress, setContractAddress] = useState();
  const [tokenUnit, setTokenUnit] = useState();
  const selectOptions = ["USDC", "USDT"];
  const [token, setToken] = useState(authorization?.tokenName);
  const [loading, setLoading] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  useEffect(() => {
    if (authorization) {
      setRecipientAddress(authorization?.spenderAddress);
    }
  }, [authorization]);

  const getNetwork = (name) => {
    switch (name) {
      case "matic":
        return "MATIC_MAINNET";
      case "bsc":
        return "BSC_MAINNET";
      case "arbitrum":
        return "ARBITRUM_MAINNET";
      default:
        return name;
    }
  };

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: erc20ABI,
    functionName: "approve",
    enabled: contractAddress ? true : false,
    args: [recipientAddress, parseUnits(`0`, tokenUnit)],

    onSuccess(data) {
      console.log(data, "success");
    },
    onError(error) {
      console.log(error, "error");
    },
    //value: parseEther(`${amount}`),
  });

  const { isLoading, mutate } = useMutation(
    ["remove authorization"],
    (payload) => removeAuthorization(payload),
    {
      onSuccess: (data) => {
        console.log(data, "verify approval response");
        handleNotify("success", "Wallet deleted successfully");
        setLoading(false);
        _getAuthorizations();
      },
      onError: (error) => {
        console.log({ error });
        setLoading(false);
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const {
    data: writeContractResult,
    write,
    error,
    isLoading: isWritingTransaction,
  } = useContractWrite(config);
  // {
  //   addressOrName: recipientAddress,
  //   contractInterface: erc20ABI,
  // },
  // "approve",
  // { args: [contractAddress, amount] }

  const { isLoading: transactionLoading, isSuccess: transactionSuccess } =
    useWaitForTransaction({
      hash: writeContractResult?.hash,
    });

  useEffect(() => {
    if (transactionSuccess) {
      console.log("success");
      setLoading(true);
      setTimeout(() => {
        mutate({
          walletAuthorizationId: authorization?.id,
        });
      }, 10000);
    }
    if (error) {
      handleNotify(
        "error",
        error?.error?.shortMessage ||
          error?.error?.message ||
          "An error occurred"
      );
    }
  }, [transactionSuccess, error, writeContractResult]);

  //call write
  useEffect(() => {
    if (contractAddress) {
      console.log("write called");
      write?.();
    }
  }, [contractAddress, write]);

  const { isLoading: isGettingContractAddress, refetch: _getContractAddress } =
    useQuery(
      [`get contract address ${index}`],
      () => getContractAddress(getNetwork(chain?.network), token),
      {
        enabled: enabled,
        onSuccess: (data) => {
          console.log(data, "contract address");
          setContractAddress(data?.contractAddress);
          setTokenUnit(data?.tokenUnits);
        },
        onError: (error) => {
          console.log({ error });
          handleNotify(
            "error",
            error?.response?.data?.message ?? "An error occurred"
          );
        },
      }
    );

  const { isLoading: isSettingDefaultWallet, mutate: _setDefaultWallet } =
    useMutation(
      ["set default wallet"],
      (payload) => setDefaultWallet(payload),
      {
        onSuccess: (data) => {
          _getAuthorizations();
          handleNotify("success", "new default wallet set successfully");
        },
        onError: (error) => {
          console.log({ error });
          handleNotify(
            "error",
            error?.response?.data?.message ?? "An error occurred"
          );
        },
      }
    );

  const removeAuth = () => {
    console.log(authorization?.walletAddress, "called");
    if (!account || !account?.isConnected) {
      handleNotify(
        "error",
        "Please kindly connect wallet to remove authorization"
      );

      return;
    }

    if (authorization?.walletAddress !== account?.address) {
      handleNotify(
        "error",
        `You cannot delete this authorization with current wallet, kindly switch to wallet ${authorization?.walletAddress?.slice(
          0,
          5
        )}...${authorization?.walletAddress?.slice(-5)}`
      );

      return;
    }
    if (!authorization?.network) {
      mutate({ walletAuthorizationId: authorization?.id });
    }

    if (authorization?.network !== getNetwork(chain?.network)) {
      handleNotify(
        "error",
        "Your current network doesn't match the network used to create this authorization"
      );
      return;
    }
    if (contractAddress) {
      setContractAddress();
    }

    setEnabled(true);
    _getContractAddress();
  };

  return (
    <>
      <div className={styles.authorization}>
        {isSettingDefaultWallet ||
        isLoading ||
        loading ||
        transactionLoading ||
        isGettingContractAddress ||
        isWritingTransaction ? (
          <div className={styles.authorization__activate}>
            <button>
              <p>Please wait</p>{" "}
              <ReactLoading
                type="bubbles"
                width={32}
                height={32}
                color="#4438cb"
              />
            </button>
          </div>
        ) : null}
        <div className={styles.authorization__group}>
          <div className={styles.authorization__group__item}>
            <p>
              <b>Limit: </b>
              {/* {authorization?.amountLimit ?? "-"} */}
              {authorization?.amountLimit && authorization?.tokenUnits
                ? Number(
                    formatUnits(
                      authorization?.amountLimit,
                      authorization?.tokenUnits
                    )
                  ).toFixed(3)
                : "-"}{" "}
            </p>
            <div>
              {authorization?.tokenName && authorization?.network ? (
                <>
                  <img
                    src={getIcon(getNetwork(authorization?.network))}
                    alt="network"
                  />
                  <img src={getIcon(authorization?.tokenName)} alt="token" />
                </>
              ) : (
                "-"
              )}
            </div>
          </div>

          <div className={styles.authorization__group__item}>
            <p>
              <b>Limit Left: </b>
              {/* {authorization?.amountLimit ?? "-"} */}
              {authorization?.amountLimit && authorization?.tokenUnits
                ? Number(
                    formatUnits(
                      authorization?.amountLimit,
                      authorization?.tokenUnits
                    )
                  ).toFixed(3)
                : "-"}{" "}
            </p>
            <p>
              {authorization?.walletAddress?.slice(0, 10)}...
              {authorization?.walletAddress?.slice(-10)}
            </p>
          </div>
        </div>

        {authorization?.isDefault  ? (
          <div className={styles.authorization__actions}>
            <button
              onClick={() => setShowSetDefaultNotification(true)}
              className={styles.authorization__removeAsDefault}
            >
              Remove as default
            </button>
            <button
              onClick={() =>
                handleIncreaseLimit(authorization, getNetwork(chain?.network))
              }
              className={styles.authorization__actions__complete}
            >
              Increase Limit
            </button>
            <button
              onClick={() => setShowConfirmDeleteModal(true)}
              className={styles.authorization__actions__remove}
            >
              Remove Wallet
            </button>
          </div>
        ) : (
          <div className={styles.authorization__actions}>
            {authorization?.network ? (
              <>
                <button
                  onClick={() =>
                    _setDefaultWallet({
                      walletAuthorizationId: authorization?.id,
                    })
                  }
                  className={styles.authorization__actions__setDefault}
                >
                  Set as default
                </button>
                <button
                  onClick={() =>
                    handleIncreaseLimit(
                      authorization,
                      getNetwork(chain?.network)
                    )
                  }
                  className={styles.authorization__actions__complete}
                >
                  Increase Limit
                </button>
              </>
            ) : (
              <button
                onClick={() => handleActivate(authorization)}
                className={styles.authorization__actions__complete}
              >
                Complete Auth.
              </button>
            )}

            <button
              onClick={() => setShowConfirmDeleteModal(true)}
              className={styles.authorization__actions__remove}
            >
              Remove Wallet
            </button>
          </div>
        )}
      </div>
      {showConfirmDeleteModal && (
        <Modal
          showModal={showConfirmDeleteModal}
          setShowModal={setShowConfirmDeleteModal}
        >
          <DeleteWallet
            removeAuth={removeAuth}
            setShowConfirmDeleteModal={setShowConfirmDeleteModal}
          />
        </Modal>
      )}
    </>
  );
};

export default Authorization;
