import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Verification.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";

//components
import BottomNav from "../../../components/BottomNav/BottomNav";
import VerificationStep from "../../../components/VerificationStep/VerificationStep";

const Verification = ({
  setEnabled,
  getUserDetails,
  isLoadingUserDetails,
  data: user,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log(user, " verification");
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  return (
    <>
      <div className={styles.verification}>
        <div className={styles.verification__content}>
          <div
            onClick={() => navigate(-1)}
            className={styles.verification__back}
          >
            <img src={BackIcon} alt="back icon" />
          </div>

          <h3 className={styles.verification__heading}>Verification</h3>
          <p className={styles.verification__desc}>
            K.Y.C verification allows us to better serve you
          </p>
          {isLoadingUserDetails ? (
            <p className={styles.verification__loading}>Loading..</p>
          ) : (
            <div className={styles.verification__steps}>
              {" "}
              {user?.user?.locale === "NG" ||
              user?.user?.locale === "KE" ||
              user?.user?.locale === "GH" || true ? (
                <VerificationStep
                  heading={"Teaser Tier"}
                  limit={2000}
                  completed={
                    user?.user?.userSettings?.kycTeaserStatus ===
                      "SUCCESSFUL" ||
                    user?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL"
                  }
                  tier={"TEASER"}
                  documents="NIN, BVN"
                />
              ) : null}
{false && <VerificationStep
                heading={"Standard Tier"}
                limit={10000}
                completed={
                  user?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL"
                }
                tier={"STANDARD"}
                documents="Intl. Passport, Intl. Drivers license"
              />}
              <VerificationStep
                heading={"Premium Tier"}
                limit={30000}
                action={"Email info@useknit.io"}
                tier={"PREMIUM"}
              />
            </div>
          )}
        </div>
        <BottomNav active="verification" />
      </div>
    </>
  );
};

export default Verification;
