import React from "react";
import ReactLoading from "react-loading";
import handleNotify from "../../helpers/handleNotify";
import SelectField from "../SelectVariantTwo/SelectVariantTwo";
import styles from "./CreateWhitelist.module.scss";

//images
import PasteIcon from "../../assets/icons/paste.svg";

const StepTwo = ({ address, setAddress, handleCreateWhitelist, isLoading }) => {
  const validateForm = () => {
    if (!address) {
      handleNotify("error", "Address is required");
      return;
    }
    handleCreateWhitelist();
  };
  return (
    <div>
      <div className={styles.createWhitelist__form__item}>
        <label>Address</label>

        <div className={styles.createWhitelist__form__item__paste}>
          <input value={address} onChange={(e) => setAddress(e.target.value)} />
          <div
            onClick={async () => {
              const text = await navigator.clipboard.readText();
              if (text) {
                setAddress(text);
                handleNotify("success", "Address pasted from clipboard");
              }
            }}
            className={styles.createWhitelist__form__item__paste__icon}
          >
            <img src={PasteIcon} alt="paste" />
          </div>
        </div>
      </div>
      <button onClick={validateForm} className={styles.createWhitelist__submit}>
        {isLoading ? (
          <ReactLoading type="spin" width={32} height={32} />
        ) : (
          "Complete"
        )}
      </button>
    </div>
  );
};

export default StepTwo;
