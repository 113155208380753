import React, { useState } from "react";
import { X } from "phosphor-react";
import styles from "./ReferTag.module.scss";
import CoinsImage from "../../assets/images/make-money.svg";

const ReferTag = ({ action }) => {
  const [showReferTag, setShowReferTag] = useState(true);
  return (
    <>
      {showReferTag ? (
        <div className={styles.referTag}>
          <img
            className={styles.referTag__image}
            src={CoinsImage}
            alt="paste icon"
          />

          <div className={styles.referTag__content}>
            <h3>Refer and earn!</h3>
            <p>Receive 10USDT when someone you refer sends 150USDT or more</p>
            <a onClick={() => action()}>Learn more</a>
          </div>
          <div
            onClick={() => setShowReferTag(false)}
            className={styles.referTag__close}
          >
            <X size={17} color="#fff" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ReferTag;
