import { Http } from "../utils/http";

export const userLogin = (payload) => Http.post(`/auth/login`, payload);

export const userLoginFinalize = (payload) =>
  Http.post(`/auth/login/finalize`, payload);

export const verifySignature = (payload) =>
  Http.post(`/wallet-auhtorizations/verify-signature`, payload);

export const verifyApproval = (payload) =>
  Http.post(`/wallet-auhtorizations/verify-approval`, payload);

export const validateMobileMoneyDetails = (
  currency,
  fullName,
  mobileNumber,
  operatorId
) =>
  Http.get(
    `/send/validate-mobile-money-details?beneficiaryCurrency=${currency}&beneficiaryName=${fullName}&mobileNumber=${mobileNumber}&momoOperatorId=${operatorId}`
  );

export const setTransactionPin = (payload) =>
  Http.patch(`/users/transaction-pin`, payload);

export const getAccessToken = (payload) =>
  Http.post(`/users/kyc/start`, payload);

export const onboardingUploadEnded = (payload) =>
  Http.post(`/users/kyc/onboarding-upload-ended`, payload);

export const generateLink = (payload) =>
  Http.post(`/transactions/create/link`, payload);

export const userDetails = () => Http.get(`/auth/profile`);

export const getContractAddress = (network, coin) =>
  Http.get(
    `/wallet-auhtorizations/get-contract-address?network=${network}&coin=${coin}`
  );

export const getWallets = () => Http.get(`/wallets`);

export const getVirtualAccounts = (country) =>
  Http.get(`/virtual-accounts/get-accounts?country=${country}`);

export const getBanks = (country) => Http.get(`/banks?country=${country}`);

export const getTransactions = () => Http.get(`/customer-transactions`);

export const getTransaction = (id) => Http.get(`/customer-transactions/${id}`);

export const getAccountDetails = (
  bankId,
  accountNumber,
  currency,
  accountName,
  country
) =>
  Http.get(
    `/send/get-account-number-details/${
      currency ?? "NGN"
    }?bankId=${bankId}&accountNumber=${accountNumber}${
      country === "GB" ? `&accountName=${accountName}` : ""
    }`
  );

export const createVirtualAccount = (payload) =>
  Http.post(`/virtual-accounts/create/NGN`, payload);

export const sendFait = (payload) => Http.post(`/send/send-fiat`, payload);

export const sendFaitTransfer = (payload) =>
  Http.post(`/send/send-fiat/transfer`, payload);

export const sendToMobileMoney = (payload) =>
  Http.post(`/send/send-to-mobile-money`, payload);

export const setDefaultWallet = (payload) =>
  Http.post(`/wallet-auhtorizations/set-default-wallet`, payload);

export const removeAuthorization = (payload) =>
  Http.post(`/wallet-auhtorizations/remove-authorization`, payload);

export const removeWhitelist = (id) => Http.delete(`/wallet-whitelist/${id}`);

export const updateWhitelist = (id, payload) =>
  Http.put(`/wallet-whitelist/${id}`, payload);

export const createWhitelist = (payload) =>
  Http.post(`/wallet-whitelist`, payload);

export const getWhitelist = () => Http.get(`/wallet-whitelist`);

export const verifyBvn = (payload) =>
  Http.post(`/onboard/kyc/bvn/submit`, payload);

export const getSpenderAddress = () =>
  Http.get(`/wallet-auhtorizations/spender-address`);

export const getAuthorizations = () =>
  Http.get(`/wallet-auhtorizations/authorizations`);

export const getExchangeRate = (currency) =>
  Http.get(`/send/get-exchange-rate?currency=${currency}`);

export const getBeneficiaries = (country) =>
  Http.get(`/beneficiary?country=${country}`);

export const getReferrals = (currency) => Http.get(`/referrals`);

export const getAllRates = () => Http.get(`/send/get-all-exchange-rate`);

export const momoOperators = (country) =>
  Http.get(`/momo-operator?country=${country}`);
