import React, { useState } from "react";
import styles from "./UnlinkCard.module.scss";

const UnlinkCard = () => {
  const [cardLabel, setCardLabel] = useState();
  return (
    <div className={styles.unlinkCard}>
      <h3 className={styles.unlinkCard__heading}>
        Are you sure you want to unlink this card?
      </h3>
      {/* <p className={styles.unlinkCard__desc}>
        Create a new virtual card and give it a label.
      </p> */}
      <div className={styles.unlinkCard__actions}>
        <button className={styles.unlinkCard__submit}>Yes, unlink card</button>
        <button className={styles.unlinkCard__cancel}>Cancel</button>
      </div>
    </div>
  );
};

export default UnlinkCard;
