import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useSignMessage } from "wagmi";
import { verifySignature } from "../../services";
import styles from "./Sign.module.scss";

const Sign = ({
  account,
  user,
  setShowSign,
  setShowApproveTransfer,
  setActiveAuthorization,
}) => {
  const [message, setMessage] = useState("");

  const { isLoading, signMessage } = useSignMessage({
    onSuccess(data) {
      const payload = {
        address: account.address,
        message: message,
        signature: data,
      };

      mutate(payload);
    },
    onError(error) {
      console.log("Error", error);
      toast.error(
        error?.response?.data?.message ?? "An error occurred during signing",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    },
  });

  useEffect(() => {
    user?.id &&
      setMessage(
        `I am the owner of this wallet and my unique Knit user ID is: ${user?.id}`
      );
  }, [user]);

  const { isLoading: isSigning, mutate } = useMutation(
    ["verify signature"],
    (payload) => verifySignature(payload),
    {
      onSuccess: (data) => {
        console.log(data, "signing response");
        setActiveAuthorization({
          ...data,
          id: data?.id,
          spenderAddress: data?.spenderAddress,
        });
        setShowSign(false);
        setShowApproveTransfer(true);
      },
      onError: (error) => {
        if (
          error?.response?.data?.message === "Address [redacted] authorised"
        ) {
          setShowSign(false);
          // setShowApproveTransfer(true);
          console.log("signed");
          toast.error(
            "Address already authorized, kindly check the authorizations list to activate",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          return;
        }
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const signMessageHandler = async () => {
    if (!account) {
      toast.error("Please connect to the wallet first.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    signMessage({ message });
  };

  return (
    <div className={styles.sign}>
      <h3 className={styles.sign__heading}>Sign Message</h3>
      <p className={styles.sign__desc}>
        Sign a message to prove that you indeed own this address
      </p>
      {/* <div className={styles.sign__form}>
        <div className={styles.sign__input}>
          <label>Account</label>
          <input value={account?.address} />
        </div>

        <div className={styles.sign__input}>
          <label>Message</label>
          <textarea value={message} />
        </div>
      </div> */}

      <button
        onClick={() => signMessageHandler()}
        className={styles.sign__submit}
      >
        {isSigning || isLoading ? (
          <ReactLoading type="spin" width={32} height={32} />
        ) : (
          "Sign"
        )}
      </button>
    </div>
  );
};

export default Sign;
