import React, { useState, useEffect } from "react";
import styles from "./Registration.module.scss";
import { useMutation } from "react-query";
import { userLogin } from "../../services";
import cookie from "../../utils/cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import handleNotify from "../../helpers/handleNotify";
import ReactLoading from "react-loading";

import IOSIcon from "../../assets/icons/ios.png";
import AndriodIcon from "../../assets/icons/andriod.png";

const Registration = () => {
  const [email, setEmail] = useState();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [getNewsUpdate, setGetNewsUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [referral, setReferral] = useState();
  const navigate = useNavigate();

  const { isLoading, mutate: _userLogin } = useMutation(
    ["user login"],
    (payload) => userLogin(payload),
    {
      onSuccess: (data) => {
        setErrorMessage("");
        // localStorage.setItem("spend-token", data?.access_token)
        navigate(
          `/auth/otp?otp_identifier=${data?.otp_identifier}&email=${email}`
        );
        console.log(data);
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const handleSubmission = () => {
    if (!email) {
      handleNotify("error", "Please enter a valid email address");
      return;
    }
    if (!agreedToTerms) {
      handleNotify(
        "error",
        "Kindly confirm that you've read out terms of service"
      );
      return;
    }
    // if (!getNewsUpdate) {
    //   handleNotify("error", "sample");
    // }
    _userLogin({ username: email, referralId: referral });
  };

  useEffect(() => {
    const token = cookie().getCookie("spend-token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    const _ref = searchParams.get("ref");
    if (_ref) {
      setReferral(_ref);
    }
  }, []);

  return (
    <div className={styles.registration}>
      <h2 className={styles.registration__heading}>Email</h2>
      <p className={styles.registration__desc}>
        Enter your email to login to your Spend account.
      </p>

      <div className={styles.registration__input}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <button
        onClick={() =>
          // navigate("/auth/set-pin")
          handleSubmission()
        }
        className={styles.registration__submit}
      >
        {isLoading ? (
          <ReactLoading type="spin" width={28} height={28} />
        ) : (
          "Continue"
        )}
      </button>

      <div className={styles.registration__checks}>
        <div className={styles.registration__check}>
          <input
            type="checkbox"
            onChange={(e) => setAgreedToTerms(e.target.checked)}
          />
          <p>
            I have read and agree to Knit’s{" "}
            <a href="https://www.notion.so/Terms-Conditions-0b867bfd0ec243398dde03e79c50f4a7?pvs=4">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://www.notion.so/Privacy-Security-a8fd95813e0043278b820ad28e33e202?pvs=4">
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className={styles.registration__check}>
          <input
            type="checkbox"
            onChange={(e) => setGetNewsUpdate(e.target.checked)}
          />
          <p>
            Sign me up to receive news, offers and tips from Send and its
            partners (you can opt out at any time).
          </p>
        </div>
      </div>

      <div className={styles.registration__mobile}>
        <h3 className={styles.registration__mobile__heading}>
          Add Knit app to your homescreen
        </h3>
        <div className={styles.registration__mobile__links}>
          <div
            onClick={() => navigate("/add/for-ios")}
            className={styles.registration__mobile__link}
          >
            <img src={IOSIcon} alt="ios icon" />
            <p>For IOS</p>
          </div>
          <div
            onClick={() => navigate("/add/for-android")}
            className={styles.registration__mobile__link}
          >
            <img src={AndriodIcon} alt="android icon" />
            <p>For Android</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
