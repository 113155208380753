import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Referrals.module.scss";

import BackIcon from "../../assets/icons/back.svg";
import WhitelistEmptyImage from "../../assets/images/sad emoji 1.png";

//components

const Referrals = ({ referrals, setShowReferrals }) => {
  const getColorCode = (status) => {
    const colorCode = {
      pending: {
        color: "#333333",
        background: "#33333309",
      },
      success: {
        color: " #27AE60",
        background: "#27ae5f09",
      },
      successful: {
        color: " #27AE60",
        background: "#27ae5f09",
      },
      failed: {
        color: "#E74C3C",
        background: "#E74C3C09",
      },
      unsuccessful: {
        color: "#E74C3C",
        background: "#E74C3C09",
      },
      initiated: {
        color: "#333333",
        background: "#33333309",
      },
      processing: {
        color: "#ffc400",
        background: "#ffc40009",
      },
    };
    return colorCode[status];
  };

  return (
    <>
      <div className={styles.referrals}>
        <div className={styles.referrals__content}>
          <div className={styles.referrals__header}>
            <div
              onClick={() => setShowReferrals(false)}
              className={styles.referrals__back}
            >
              <img src={BackIcon} alt="back icon" />
            </div>
          </div>
          <h3 className={styles.referrals__heading}>Referrals</h3>

          <p className={styles.referrals__desc}>
            Below is a list of all your referrals
          </p>

          <div className={styles.referrals__items}>
            {referrals?.length > 0 ? (
              referrals?.map((item) => (
                <div className={styles.referrals__item}>
                  <div>
                    <p>{item?.referred?.name ?? item?.referred?.email}</p>
                    <button
                      style={{
                        background: getColorCode(
                          item?.status?.toLowerCase() ?? "pending"
                        )?.background,
                        color: getColorCode(
                          item?.status?.toLowerCase() ?? "pending"
                        )?.color,
                      }}
                    >
                      {item?.status?.toLowerCase()}
                    </button>
                  </div>
                  <h3>{item?.status === "SUCCESSFUL" ? "10USDT" : "0USDT"}</h3>
                </div>
              ))
            ) : (
              <div className={` ${styles.referrals__empty}`}>
                <div className={styles.referrals__empty__content}>
                  <img src={WhitelistEmptyImage} alt="empty" />
                  <p>You don't have any referrals</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Referrals;
