import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BottomNav.module.scss";

//images
import sendIcon from "../../assets/icons/send.js";
import SupportIcon from "../../assets/icons/headset-alt.svg";
import homeIcon from "../../assets/icons/home.js";
import directDebitIcon from "../../assets/icons/directDebit";
import moreIcon from "../../assets/icons/more";
import receiveIcon from "../../assets/icons/receive";

const BottomNav = ({ active }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.bottomNav}>
      <div className={styles.bottomNav__main}>
        <div
          onClick={() => navigate("/dashboard")}
          className={styles.bottomNav__item}
        >
          {/* <img src={homeIcon(active === "home" ? "red" : "teal")} alt="home" /> */}
          <div
            className={
              active === "home"
                ? `${styles.bottomNav__item__icon} ${styles.bottomNav__item__icon__active}`
                : `${styles.bottomNav__item__icon}`
            }
          >
            <picture>
              {homeIcon(active === "home" ? "#4438CB" : "#a5a5a5")}
            </picture>
          </div>
          <p
            style={
              active === "home" ? { color: "#4438CB" } : { color: "#a5a5a5" }
            }
          >
            Home
          </p>
        </div>

        <div
          onClick={() => navigate("/dashboard/send")}
          className={styles.bottomNav__item}
        >
          {/* <img src={SendIcon} alt="send" /> */}
          <div
            className={
              active === "send"
                ? `${styles.bottomNav__item__icon} ${styles.bottomNav__item__icon__active}`
                : `${styles.bottomNav__item__icon}`
            }
          >
            <picture>
              {sendIcon(active === "send" ? "#4438CB" : "#a5a5a5")}
            </picture>
          </div>
          <p
            style={
              active === "send" ? { color: "#4438CB" } : { color: "#a5a5a5" }
            }
          >
            Send
          </p>
        </div>
        <div
          onClick={() => navigate("/dashboard/receive")}
          className={styles.bottomNav__item}
        >
          {/* <img src={ReceiveIcon} alt="receive" /> */}
          <div
            className={
              active === "receive"
                ? `${styles.bottomNav__item__icon} ${styles.bottomNav__item__icon__active}`
                : `${styles.bottomNav__item__icon}`
            }
          >
            <picture>
              {receiveIcon(active === "receive" ? "#4438CB" : "#a5a5a5")}
            </picture>
          </div>
          <p
            style={
              active === "receive" ? { color: "#4438CB" } : { color: "#a5a5a5" }
            }
          >
            Receive
          </p>
        </div>
        <div
          onClick={() => navigate("/dashboard/more")}
          className={styles.bottomNav__item}
        >
          {/* <img src={KeyIcon} alt="send" /> */}
          <div
            className={
              active === "more"
                ? `${styles.bottomNav__item__icon} ${styles.bottomNav__item__icon__active}`
                : `${styles.bottomNav__item__icon}`
            }
          >
            <div
              className={
                active === "more"
                  ? `${styles.bottomNav__item__icon__more} ${styles.bottomNav__item__icon__more__active}`
                  : `${styles.bottomNav__item__icon__more}`
              }
            >
              <picture>
                {moreIcon(active === "more" ? "#4438CB" : "#a5a5a5")}
              </picture>
            </div>
          </div>
          <p
            style={
              active === "more" ? { color: "#4438CB" } : { color: "#a5a5a5" }
            }
          >
            More
          </p>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
