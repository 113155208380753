import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import getIcon from "../../helpers/getIcon";
import styles from "./ForIOS.module.scss";

//images and icons
import BackIcon from "../../assets/icons/back.svg";
import IOSIcon from "../../assets/icons/ios.png";
import AndriodIcon from "../../assets/icons/andriod.png";
import StepOneImg from "../../assets/images/fi-1.png";
import StepTwoImg from "../../assets/images/fi-2.png";
import StepThreeImg from "../../assets/images/fi-1.png";
import StepFourImg from "../../assets/images/fi-4.png";

const ForIOS = () => {
  const navigate = useNavigate();

  const steps = [
    {
      index: "01",
      image: StepOneImg,
      desc: "Open Knit on Safari browser, and find the Share Icon.",
    },
    {
      index: "02",
      image: StepTwoImg,
      desc: "Find the “Add to Home Screen” option on the pop-up menu.",
    },
    {
      index: "03",
      image: StepThreeImg,
      desc: `Give it your preferred name and click the “Add” button.`,
    },
    {
      index: "04",
      image: StepFourImg,
      desc: "Check your iPhone’s home-screen to access the web app.",
    },
  ];

  return (
    <>
      <div className={styles.forIOS}>
        <div className={styles.forIOS__content}>
          <div onClick={() => navigate(-1)} className={styles.forIOS__back}>
            <img src={BackIcon} alt="back icon" />
          </div>

          <h3 className={styles.forIOS__heading}>Add Knit to your phone</h3>
          <div className={styles.forIOS__mobile}>
            <div className={styles.forIOS__mobile__links}>
              <div className={styles.forIOS__mobile__link}>
                <img src={IOSIcon} alt="ios icon" />
                <p>For IOS</p>
              </div>
              <div
                onClick={() => navigate("/add/for-android")}
                className={styles.forIOS__mobile__link}
              >
                <img src={AndriodIcon} alt="android icon" />
                <p>For Android</p>
              </div>
            </div>
          </div>

          <div className={styles.forIOS__steps}>
            {steps?.map((step) => (
              <div className={styles.forIOS__step}>
                <div className={styles.forIOS__step__text}>
                  <div className={styles.forIOS__step__number}>
                    <p>{step?.index}</p>
                  </div>
                  <div className={styles.forIOS__step__desc}>
                    <p>{step?.desc}</p>
                    <img src={step?.image} alt="image description of step" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForIOS;
