import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { CaretRight } from "phosphor-react";
import { getReferrals } from "../../../services";
import handleNotify from "../../../helpers/handleNotify";
import getSymbol from "../../../helpers/getSymbol";
import styles from "./EarnMoney.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
import GroupImage from "../../../assets/images/group.svg";
import InvitesIcon from "../../../assets/icons/invites.png";
import MoneyIcon from "../../../assets/icons/money.png";
import MoneyVariantIcon from "../../../assets/icons/money-variant.png";

//components
import BottomNav from "../../../components/BottomNav/BottomNav";
import Modal from "../../../components/Modal/Modal";
import Invite from "../../../components/Invite/Invite";
import Loader from "../../../components/Loader/Loader";
import Referrals from "../../../components/Referrals/Referrals";

const EarnMoney = ({
  data: user,
  setEnabled,
  getUserDetails,
  isLoadingUserDetails,
}) => {
  const navigate = useNavigate();
  const [showCode, setShowCode] = useState(false);
  const [showReferrals, setShowReferrals] = useState(false);

  useEffect(() => {
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  const { isLoading, data } = useQuery(["get referrals"], getReferrals, {
    onSuccess: (data) => {
      console.log(data, "referrals");
    },
    onError: (error) => {
      console.log({ error });
      handleNotify(
        "error",
        error?.response?.data?.message ?? "An error occurred"
      );
    },
  });

  return (
    <>
      {showReferrals ? (
        <Referrals setShowReferrals={setShowReferrals} referrals={data} />
      ) : (
        <div className={styles.earnMoney}>
          <div className={styles.earnMoney__content}>
            <div className={styles.earnMoney__header}>
              <div
                onClick={() => navigate(-1)}
                className={styles.earnMoney__back}
              >
                <img src={BackIcon} alt="back icon" />
              </div>
            </div>
            <h3 className={styles.earnMoney__heading}>Earn Money</h3>
            <img
              src={GroupImage}
              className={styles.earnMoney__groupImage}
              alt="group image"
            />
            <p className={styles.earnMoney__desc}>
              You will receive <b>10USDT</b> on your whitelist wallet when
              someone you refer sends/receives <b>150USDT</b> and above on Knit
            </p>

            <div className={styles.earnMoney__points}>
              <div
                className={styles.earnMoney__point}
                onClick={() => setShowReferrals(true)}
              >
                <div className={styles.earnMoney__point__icon}>
                  <img src={InvitesIcon} alt="invites" />
                </div>
                <div className={styles.earnMoney__point__desc}>
                  <h3>People you signed up</h3>
                  <p>This puts you in the top 99%</p>
                </div>
                <div className={styles.earnMoney__point__value}>
                  <p>
                    <span>{data?.length}</span>
                    <span>
                      <CaretRight size={18} />
                    </span>
                  </p>
                </div>
              </div>

              <div className={styles.earnMoney__point}>
                <div className={styles.earnMoney__point__icon}>
                  <img src={MoneyIcon} alt="earn" />
                </div>
                <div className={styles.earnMoney__point__desc}>
                  <h3>Money earned</h3>
                  <p>From all your invites</p>
                </div>
                <div className={styles.earnMoney__point__value}>
                  <p>
                    {Number(
                      data?.filter((item) => item?.status === "SUCCESSFUL")
                        ?.length
                    ) * 10}
                    USDT
                  </p>
                </div>
              </div>

              {/* <div className={styles.earnMoney__point}>
                <div className={styles.earnMoney__point__icon}>
                  <img src={MoneyVariantIcon} alt="earn" />
                </div>
                <div className={styles.earnMoney__point__desc}>
                  <h3>Money you could earn</h3>
                  <p>If you refer your other 0 contacts</p>
                </div>
                <div className={styles.earnMoney__point__value}>
                  <p>0USDT</p>
                </div>
              </div> */}
            </div>

            <button
              onClick={() => setShowCode(true)}
              className={styles.earnMoney__create}
            >
              Invite Friends
            </button>
          </div>
          <BottomNav active="more" />
        </div>
      )}

      {showCode && (
        <Modal showModal={showCode} setShowModal={setShowCode}>
          <Invite
            user={user}
            isGettingUser={isLoadingUserDetails}
            setEnabled={setEnabled}
            getUserDetails={getUserDetails}
          />
        </Modal>
      )}
      {isLoadingUserDetails || isLoading ? <Loader /> : null}
    </>
  );
};

export default EarnMoney;
