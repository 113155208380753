import React, { useState } from "react";
import styles from "./FilterVirtualCards.module.scss";

import ArrowLeft from "../../assets/icons/arrow-left-2.svg";

const Filter = ({ setStep }) => {
  return (
    <div className={styles.filter}>
      <div className={styles.filter__heading}>
        <img onClick={() => setStep("options")} src={ArrowLeft} alt="back" />
        <h3>Filter by Virtual Cards</h3>
      </div>

      <div className={styles.filter__inputs}>
        <div className={styles.filter__input}>
          <input type="checkbox" />
          <div className={styles.filter__input__image}></div>
          <p>GBP - Travel</p>
        </div>

        <div className={styles.filter__input}>
          <input type="checkbox" />
          <div className={styles.filter__input__image}></div>
          <p>GBP - Insurance</p>
        </div>

        <div className={styles.filter__input}>
          <input type="checkbox" />
          <div className={styles.filter__input__image}></div>
          <p>GBP - Games</p>
        </div>

        <div className={styles.filter__input}>
          <input type="checkbox" />
          <div className={styles.filter__input__image}></div>
          <p>GBP - Enjoyment</p>
        </div>
        <button className={styles.filter__submit}>Filter</button>
      </div>
    </div>
  );
};

export default Filter;
