import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./KYC.module.scss";
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import KYCDojah from "../../components/KYCDojah/KYCDojah";
import KYCSub from "../../components/KYCSub/KYC";
import handleNotify from "../../helpers/handleNotify";
import cookie from "../../utils/cookie";

import Menu from "../../assets/icons/menu-alt.svg";
import SupportIcon from "../../assets/icons/support.png";
import LogoutIcon from "../../assets/icons/power.png";
import BackIcon from '../../assets/icons/back.svg'

const KYC = ({ data: user, setEnabled: setUserFetchEnabled }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState("initial");
  const [searchParams] = useSearchParams();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [kycTier, setKycTier] = useState();
  const [proceedToKyc, setProceedToKyc] = useState(false);
  const menuRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(menuRef);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowMenu(false);
    }
  }, [isOutOfFocus, setIsOutOfFocus]);

  useEffect(() => {
    console.log(user, "kyc");
    if (!user) {
      navigate("/dashboard");
    } else if (user?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL") {
      navigate("/dashboard");
    } else if (
      user?.user?.userSettings?.kycTeaserStatus === "SUCCESSFUL" &&
      user?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL"
    ) {
      navigate("/dashboard");
    } else if (
      (user?.user?.userSettings?.kycTeaserStatus === "PROCESSING" ||
        user?.user?.userSettings?.kycStandardStatus === "PROCESSING") &&
      kycTier
    ) {
      navigate(`/auth/kyc/processing?kyc_tier=${kycTier}`);
    }
  }, [user, navigate, kycTier]);

  const handleLogout = () => {
    cookie().deleteCookie("spend-token");
    setUserFetchEnabled(false);
    navigate("/auth/login");
  };

  useEffect(() => {
    const _kycTier = searchParams.get("kyc_tier");
    if (_kycTier && _kycTier !== "undefined") {
      setKycTier(_kycTier);
      return;
    }

    handleNotify("error", "KYC tier not found");
    window.location.href = `/auth/kyc/failed?kyc_tier=${kycTier}`;
  }, []);

  return (
    <div className={styles.kyc}>
      {user && proceedToKyc ? (
        kycTier === "STANDARD" ? (
          <KYCSub user={user} />
        ) : (
          <KYCDojah
            user={user?.user}
            isSuccessful={isSuccessful}
            setIsSuccessful={setIsSuccessful}
            setStep={setStep}
            step={step}
            kycTier={kycTier}
          />
        )
      ) : null}

      {proceedToKyc ? (
        kycTier !== "STANDARD" && (
          <div className={styles.kyc__loading}>
            <p>Loading...</p>
          </div>
        )
      ) : (
        <div className={styles.kyc__main}>
          <div className={styles.kyc__nav}>
          <div
            onClick={() => navigate(-1)}
            className={styles.kyc__back}
          >
            <img src={BackIcon} alt="back icon" />
          </div>
            <div
              ref={menuRef}
              onClick={() => setShowMenu(!showMenu)}
              className={styles.kyc__menu}
            >
              <img
                className={styles.kyc__menu__icon}
                alt="menu icon"
                src={Menu}
              />

              {showMenu && (
                <div className={styles.kyc__menu__items}>
                  <div
                    id="launcher"
                    onClick={() => {
                      window.Intercom("boot", {
                        api_base: "https://api-iam.intercom.io",
                        custom_launcher_selector: "#launcher",
                        hide_default_launcher: true,
                        app_id: "yvi7xvf6",
                        user_id: user?.user?.id,
                        email: user?.user?.email,
                      });
                      // window.Intercom("hide");
                      window.Intercom("showMessages");
                    }}
                    className={styles.kyc__menu__item}
                  >
                    <img src={SupportIcon} alt="support icon" />
                    <p>Support</p>
                  </div>
                  <div
                    onClick={() => handleLogout()}
                    className={styles.kyc__menu__item}
                  >
                    <img src={LogoutIcon} alt="logout icon" />
                    <p>Log Out</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <h2 className={styles.kyc__main__heading}>K.Y.C Verification</h2>
          <p className={styles.kyc__main__desc}>
            You are about to begin a verification process. This is to help us
            serve you better. The process will take approximately three
            minutes to complete.
          </p>

          <button
            onClick={() => setProceedToKyc(true)}
            className={styles.kyc__main__submit}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default KYC;
