import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { getAuthorizations, setDefaultWallet } from "../../../services";
import getIcon from "../../../helpers/getIcon";
import handleNotify from "../../../helpers/handleNotify";
import styles from "./Authorizations.module.scss";

//images and icons
import MetaMaskIcon from "../../../assets/icons/MetaMask_Fox 1.svg";
import VerifiedIcon from "../../../assets/icons/verified.svg";
import AuthorizationEmptyImage from "../../../assets/images/sad emoji 1.png";
import BackIcon from "../../../assets/icons/back.svg";
import ConnectedIcon from "../../../assets/icons/connected.svg";
import DisconnectedIcon from "../../../assets/icons/Disconnected.svg";

//components
import Sign from "../../../components/Sign/Sign";
import ApproveTransfer from "../../../components/ApproveTransfer/ApproveTransfer";
import IncreaseLimit from "../../../components/IncreaseLimit/IncreaseLimit";
import Modal from "../../../components/Modal/Modal";
import Loader from "../../../components/Loader/Loader";
import BottomNav from "../../../components/BottomNav/BottomNav";
import Authorization from "../../../components/Authorization/Authorization";
import Notification from "../../../components/Notification/Notification";

const Authorizations = ({ data: user, setEnabled, getUserDetails }) => {
  const [showSign, setShowSign] = useState(false);
  const [showApproveTransfer, setShowApproveTransfer] = useState(false);
  const [showIncreaseLimit, setShowIncreaseLimit] = useState(false);
  const [showSetDefaultNotification, setShowSetDefaultNotification] =
    useState(false);
  const [activeAuthorization, setActiveAuthorization] = useState({});
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const { open, close } = useWeb3Modal();
  const account = useAccount();

  useEffect(() => {
    setEnabled(true);
    getUserDetails();
  }, [setEnabled, getUserDetails]);

  const {
    isLoading: isGettingAuthorizations,
    data: authorizations,
    refetch: _getAuthorizations,
  } = useQuery(["get authorizations"], getAuthorizations, {
    onSuccess: (data) => {
      console.log(data, "authorizations");
    },
    onError: (error) => {
      console.log({ error });
      handleNotify(
        "error",
        error?.response?.data?.message ?? "An error occurred"
      );
    },
  });

  const handleAuthorization = () => {
    if (!account || !account?.isConnected) {
      handleNotify(
        "error",
        "Wallet not connected, click the top-right icon above to connect wallet"
      );

      return;
    }
    if (!authorizations) {
      handleNotify("error", "Authorizations not found");
    }

    const incompleteAuth = authorizations?.find(
      (item) => item?.walletAddress === account?.address && !item?.network
    );

    console.log(incompleteAuth);

    if (incompleteAuth && !incompleteAuth?.network) {
      handleNotify(
        "error",
        "connected wallet has a pending authorization that is yet to be completed"
      );
      return;
    }

    setShowSign(true);
  };

  const handleActivate = (authorization) => {
    if (!account?.isConnected) {
      handleNotify(
        "error",
        "Wallet not connected, click the connect icon above to connect wallet"
      );
      return;
    }

    if (authorization?.walletAddress !== account?.address) {
      handleNotify(
        "error",
        `You cannot activate with current wallet, kindly switch to wallet ${authorization?.walletAddress?.slice(
          0,
          5
        )}...${authorization?.walletAddress?.slice(-5)}`
      );
      return;
    }

    setActiveAuthorization({
      ...authorization,
      id: authorization?.id,
      spenderAddress: authorization?.spenderAddress,
    });
    setShowApproveTransfer(true);
  };

  const handleIncreaseLimit = (authorization, network) => {
    if (!account?.isConnected) {
      handleNotify(
        "error",
        "Wallet not connected, click the connect icon above to connect wallet"
      );
      return;
    }

    if (authorization?.walletAddress !== account?.address) {
      handleNotify(
        "error",
        `You cannot increase limit with current wallet, kindly switch to wallet ${authorization?.walletAddress?.slice(
          0,
          5
        )}...${authorization?.walletAddress?.slice(-5)}`
      );

      return;
    }

    if (authorization?.network !== network) {
      handleNotify(
        "error",
        "Your current network doesn't match the network used to create this authorization"
      );
      return;
    }

    setActiveAuthorization({
      ...authorization,
      id: authorization?.id,
      spenderAddress: authorization?.spenderAddress,
    });
    setShowIncreaseLimit(true);
  };

  return (
    <>
      <div className={styles.authorizations}>
        <div className={styles.authorizations__content}>
          <div className={styles.authorizations__header}>
            <div
              onClick={() => navigate(-1)}
              className={styles.authorizations__back}
            >
              <img src={BackIcon} alt="back icon" />
            </div>
            <div className={styles.authorizations__header__profile__box}>
              {account?.isConnected && (
                <p className={styles.authorizations__header__profile__address}>
                  {account?.address?.slice(0, 5)}
                  ...
                </p>
              )}
              <div
                onClick={() => open()}
                className={styles.authorizations__header__profile}
              >
                <div className={styles.authorizations__header__profile__image}>
                  <img
                    alt="metamask icon"
                    src={
                      account?.isConnected ? ConnectedIcon : DisconnectedIcon
                    }
                  />
                </div>
                {/* {account?.isConnected && (
                <img
                  className={styles.authorizations__header__profile__tag}
                  alt="verified tag"
                  src={VerifiedIcon}
                />
              )} */}
              </div>
            </div>
          </div>

          <h3 className={styles.authorizations__heading}>Direct Debit</h3>
          <p className={styles.authorizations__desc}>
            Direct Debit authorisation allows you to give us permission to
            initiate transactions on your behalf, using ERC20 tokens, with your
            preferred limits set. This will help automate your transactions,
            without need for manual intervention each time. It will also ensure
            your funds are always in your custody at anytime.
            {!showMore && "..."}
            <div
              className={
                showMore
                  ? `${styles.authorizations__desc__more} ${styles.authorizations__desc__more__open}`
                  : `${styles.authorizations__desc__more}`
              }
            >
              <p className={styles.authorizations__desc__more__content}>
                Your security is our priority. You can also revoke this
                authorisation at anytime, without our permission even. It is an
                entirely decentralised process. You are providing us access to
                initiate transactions, only up to the limit you have set.
              </p>
            </div>
            <span onClick={() => setShowMore(!showMore)}>
              {showMore ? "show less" : `show more`}
            </span>
          </p>
          <div className={styles.authorizations__items}>
            {authorizations && authorizations?.length > 0 ? (
              authorizations?.map((authorization, index) => (
                <Authorization
                  authorization={authorization}
                  handleActivate={handleActivate}
                  account={account}
                  _getAuthorizations={_getAuthorizations}
                  index={index}
                  setShowSetDefaultNotification={setShowSetDefaultNotification}
                  handleIncreaseLimit={handleIncreaseLimit}
                />
              ))
            ) : (
              <div
                className={`${styles.authorizations__authorization} ${styles.authorizations__authorization__empty}`}
              >
                <div
                  className={
                    styles.authorizations__authorization__empty__content
                  }
                >
                  <img src={AuthorizationEmptyImage} alt="empty" />
                  <p>You haven’t setup any direct debit.</p>
                </div>
              </div>
            )}
          </div>
          {account?.isConnected ? (
            <button
              onClick={() => handleAuthorization()}
              className={styles.authorizations__create}
            >
              Create Direct Debit Authorization
            </button>
          ) : (
            <button
              onClick={() => open()}
              className={styles.authorizations__create}
            >
              Connect Wallet
            </button>
          )}
        </div>
        <BottomNav active="more" />
      </div>

      {showSign && (
        <Modal showModal={showSign} setShowModal={setShowSign}>
          <Sign
            account={account}
            user={user?.user}
            setShowSign={setShowSign}
            setShowApproveTransfer={setShowApproveTransfer}
            setActiveAuthorization={setActiveAuthorization}
          />
        </Modal>
      )}
      {showApproveTransfer && (
        <Modal
          showModal={showApproveTransfer}
          setShowModal={setShowApproveTransfer}
        >
          <ApproveTransfer
            account={account}
            user={user?.user}
            setShowApproveTransfer={setShowApproveTransfer}
            activeAuthorization={activeAuthorization}
            _getAuthorizations={_getAuthorizations}
            authorizations={authorizations}
          />
        </Modal>
      )}

      {showIncreaseLimit && (
        <Modal
          showModal={showIncreaseLimit}
          setShowModal={setShowIncreaseLimit}
        >
          <IncreaseLimit
            account={account}
            user={user?.user}
            setShowIncreaseLimit={setShowIncreaseLimit}
            activeAuthorization={activeAuthorization}
            _getAuthorizations={_getAuthorizations}
          />
        </Modal>
      )}
      {showSetDefaultNotification && (
        <Modal setShowModal={setShowSetDefaultNotification}>
          <Notification
            heading="Want to switch default wallet?"
            content="Kindly set a new wallet of your choice as your default"
          />
        </Modal>
      )}
      {isGettingAuthorizations && !authorizations ? <Loader /> : null}
    </>
  );
};

export default Authorizations;
