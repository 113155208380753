import React, { useState } from "react";
import styles from "./DeleteCard.module.scss";

const DeleteCard = () => {
  return (
    <div className={styles.deleteCard}>
      <h3 className={styles.deleteCard__heading}>
        Are you sure you want to delete this card?
      </h3>
      <p className={styles.deleteCard__desc}>
        You will not be able to recover this card once it’s deleted.
      </p>
      <div className={styles.deleteCard__actions}>
        <button className={styles.deleteCard__submit}>Yes, delete card</button>
        <button className={styles.deleteCard__cancel}>Cancel</button>
      </div>
    </div>
  );
};

export default DeleteCard;
