import React, { useState, useRef, useEffect } from "react";
import { formatUnits } from "viem";
import styles from "./SelectWallet.module.scss";

//helpers
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import getIcon from "../../helpers/getIcon";

//images and icons
import ArrowDown from "../../assets/icons/arrow-down.svg";

const Select = ({ options, active, setActive, placeholder }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState();
  const [activeOptions, setActiveOptions] = useState(options);
  const selectRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(selectRef);
  useEffect(() => {
    console.log(options, "options");
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowOptions(false);
    }
  }, [isOutOfFocus, setShowOptions]);

  const handleSetActiveOptions = (value) => {
    const _activeOptions = options?.filter((option) =>
      option?.label?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setActiveOptions(_activeOptions);
  };

  useEffect(() => {
    setActiveOptions(options);
  }, [options]);

  return (
    <div ref={selectRef} className={styles.select}>
      <div className={styles.select__inputBox}>
        <input
          className={styles.select__inputBox__input}
          placeholder={placeholder}
          type="text"
          value={value}
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
          onChange={(e) => {
            setValue(e.target.value);
            setShowOptions(true);
            handleSetActiveOptions(e.target.value);
          }}
          onBlur={() => setValue(active?.label ?? "")}
        />
        <div
          onClick={() => setShowOptions(!showOptions)}
          className={styles.select__inputBox__arrow}
        >
          <img src={ArrowDown} alt="arrow down" />
        </div>
      </div>
      {/* && !isOutOfFocus */}
      {showOptions && !isOutOfFocus ? (
        <div className={styles.select__options}>
          {activeOptions &&
            activeOptions?.length > 0 &&
            activeOptions?.map((option, index) => (
              <div
                onClick={() => {
                  setActive(option);
                  setValue(option?.label);
                  setShowOptions(false);
                }}
                key={index}
                className={styles.select__option}
              >
                <div className={styles.select__option__token}>
                  {option?.network && (
                    <img src={getIcon(option?.network)} alt="network" />
                  )}
                  {option?.tokens &&
                    option?.tokens?.map((token) => (
                      <>
                        <img src={getIcon(token)} alt="token" />
                        <p>
                          {option?.amountLimit && option?.tokenUnits
                            ? Number(
                                formatUnits(
                                  option?.amountLimit,
                                  option?.tokenUnits
                                )
                              ).toFixed(3)
                            : "0"}
                        </p>
                      </>
                    ))}
                </div>
                <p>{option?.label} </p>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default Select;
