import React, { useEffect } from "react";
import { toast } from "react-toastify";
import styles from "./Invite.module.scss";

const Invite = ({ user, setEnabled, getUserDetails, isGettingUser }) => {
  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);

    toast.success("Link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user]);

  return (
    <div className={styles.invite}>
      <h3 className={styles.invite__heading}>Referral code</h3>
      <p className={styles.invite__desc}>
        Copy your referral code below and share with friends
      </p>
      {isGettingUser ? (
        <p className={styles.invite__loading}>Loading...</p>
      ) : (
        <div className={styles.invite__input}>
          <input
            type="text"
            value={`https://app.useknit.io/auth/login?ref=${user?.user?.referralId}`}
          />
          <button
            onClick={() =>
              handleCopy(
                `https://app.useknit.io/auth/login?ref=${user?.user?.referralId}`
              )
            }
          >
            Copy
          </button>
        </div>
      )}
    </div>
  );
};

export default Invite;
