import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import getIcon from "../../../helpers/getIcon";
import styles from "./More.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
import ArrowRight from "../../../assets/icons/forward.svg";
import EarnIcon from "../../../assets/icons/money-variant.png";
import VerificationIcon from "../../../assets/icons/verification.png";
import DirectDebitIcon from "../../../assets/icons/direct-debit.png";
import WhitelistIcon from "../../../assets/icons/whitelist.png";
import RateIcon from "../../../assets/icons/exchange.png";

//components
import BottomNav from "../../../components/BottomNav/BottomNav";

const More = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.more}>
        <div className={styles.more__content}>
          <div onClick={() => navigate(-1)} className={styles.more__back}>
            <img src={BackIcon} alt="back icon" />
          </div>

          <h3 className={styles.more__heading}>More</h3>
          <p className={styles.more__desc}>
            Setup your preferred wallets for funding and receiving.
          </p>
          <div className={styles.more__content__links}>
            <div
              onClick={() => navigate("/dashboard/rates")}
              className={styles.more__content__link}
            >
              <div className={styles.more__content__link__desc}>
                <img src={RateIcon} alt="exchange rates" />
                <h3>Exchange Rates</h3>
              </div>
              <img src={ArrowRight} alt="arrow right" />
            </div>
            <div
              onClick={() => navigate("/dashboard/whitelist")}
              className={styles.more__content__link}
            >
              <div className={styles.more__content__link__desc}>
                <img src={WhitelistIcon} alt="whitelist" />
                <h3>Whitelisting</h3>
              </div>
              <img src={ArrowRight} alt="arrow right" />
            </div>
            <div
              onClick={() => navigate("/dashboard/authorizations")}
              className={styles.more__content__link}
            >
              <div className={styles.more__content__link__desc}>
                <img src={DirectDebitIcon} alt="direct debit" />
                <h3>Direct Debit</h3>
              </div>
              <img src={ArrowRight} alt="arrow right" />
            </div>
            <div
              onClick={() => navigate("/dashboard/verification")}
              className={styles.more__content__link}
            >
              <div className={styles.more__content__link__desc}>
                <img src={VerificationIcon} alt="verification" />
                <h3>Verification</h3>
              </div>
              <img src={ArrowRight} alt="arrow right" />
            </div>
            <div
              onClick={() => navigate("/dashboard/earn-money")}
              className={styles.more__content__link}
            >
              <div className={styles.more__content__link__desc}>
                <img src={EarnIcon} alt="earn" />
                <h3>Earn</h3>
              </div>

              <img src={ArrowRight} alt="arrow right" />
            </div>
          </div>
        </div>
        <BottomNav active="more" />
      </div>
    </>
  );
};

export default More;
