import React, { useState, useEffect } from "react";

const useAmountFormat = (initialValue) => {
  const [amount, setAmount] = useState(0);
  const [inputValue, setInputValue] = useState("");

  const handleAmountChange = (value, fieldName) => {
    const _value = value.replace(/,/g, "");

    if (Number(_value) > 0) {
      const _amount = _value.toString().replace(/\./g, "");
      setAmount(_amount);
    } else {
      setAmount("");
    }
  };

  useEffect(() => {
    if (initialValue && Number(initialValue)) {
      setAmount(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (Number(amount) > 0) {
      const _value = Number(amount / 100).toFixed(2);

      const _valueWithComma = _value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      setInputValue(_valueWithComma);
    } else {
      setInputValue("");
    }
  }, [amount]);

  return [inputValue, handleAmountChange, amount];
};

export default useAmountFormat;
