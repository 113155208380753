import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getTransactions } from "../../../services";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import styles from "./Transactions.module.scss";

//images and icons
import FilterIcon from "../../../assets/icons/filter.svg";
import BackIcon from "../../../assets/icons/back.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import TransactionEmptyImage from "../../../assets/images/transaction-empty.png";

//components
import Loader from "../../../components/Loader/Loader";
import TransactionGroup from "../../../components/TransactionGroup/TransactionGroup";
import Modal from "../../../components/Modal/Modal";
import TransactionFilter from "../../../components/TransactionFilter/TransactionFilter";

const Transactions = () => {
  const [showTransactionFilter, setShowTransactionFilter] = useState(false);
  const [transactions, setTransactions] = useState();
  const [groupedTransactions, setGroupedTransactions] = useState();
  const navigate = useNavigate();

  function groupByDay(arr, property) {
    //group by date with date as key
    let groupedObj = arr.reduce(function (memo, x) {
      let _value = dateFormat(x[property], "paddedShortDate");
      if (!memo[_value]) {
        memo[_value] = [];
      }
      memo[_value].push(x);
      return memo;
    }, {});

    let groupedArray = [];

    for (const key in groupedObj) {
      groupedArray.push({ label: key, data: groupedObj[key] });
    }

    return groupedArray;
  }

  const {
    isLoading: isGettingTransactions,
    data: transactionsData,
    refetch: _getTransactions,
  } = useQuery(["get transactions"], () => getTransactions(), {
    onSuccess: (data) => {
      console.log(data, "transactions");
      setTransactions(data);
    },
    onError: (error) => {
      console.log({ error });
      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  const getMonthTransactions = (transactions, month) => {
    if (!month || !transactions || transactions?.length < 1) {
      return null;
    }
    const transactionExistForMonth = transactions?.find(
      (transaction) => dateFormat(transaction?.updatedAt, "mmmm") === month
    );

    if (!transactionExistForMonth) {
      return null;
    }

    const transactionsForMonth = transactions?.filter(
      (transaction) => dateFormat(transaction?.updatedAt, "mmmm") === month
    );

    return transactionsForMonth;
  };

  useEffect(() => {
    if (transactions) {
      const _groupedTransactions = groupByDay(transactions, "updatedAt");
      setGroupedTransactions(_groupedTransactions);
    }
  }, [transactions]);

  const handleFilterByType = (type) => {
    const _transactions = transactionsData;

    const _transactionFiltered = _transactions?.filter(
      (transaction) => transaction?.type === type
    );

    if (_transactionFiltered) {
      setTransactions(_transactionFiltered);
    }
  };

  return (
    <>
      <div className={styles.transactions}>
        <div onClick={() => navigate(-1)} className={styles.transactions__back}>
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.transactions__heading}>Transactions</h3>

        <div className={styles.transactions__actions}>
          <div className={styles.transactions__actions__search}>
            <img src={SearchIcon} alt="search icon" />
            <input type="text" />
          </div>
          <div
            onClick={() => setShowTransactionFilter(true)}
            className={styles.transactions__actions__filter}
          >
            <img src={FilterIcon} alt="filter icon" />
          </div>
        </div>

        {isGettingTransactions ? (
          <div> {/* <p>Loading...</p> */}</div>
        ) : groupedTransactions && groupedTransactions?.length > 0 ? (
          groupedTransactions?.map((item) => (
            <TransactionGroup transactions={item?.data} label={item?.label} />
          ))
        ) : (
          <div className={styles.transactions__group}>
            <div className={styles.transactions__group__content}>
              <div className={styles.transactions__group__empty}>
                <img
                  src={TransactionEmptyImage}
                  alt="transaction is empty icon"
                />
                <p>No transaction yet</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {isGettingTransactions && <Loader />}
      {showTransactionFilter && (
        <Modal
          showModal={showTransactionFilter}
          setShowModal={setShowTransactionFilter}
        >
          <TransactionFilter
            handleFilterByType={handleFilterByType}
            setShowTransactionFilter={setShowTransactionFilter}
          />
        </Modal>
      )}
    </>
  );
};

export default Transactions;
