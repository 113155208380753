import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getBeneficiaries } from "../../services";
import handleNotify from "../../helpers/handleNotify";
import ReactLoading from "react-loading";
import styles from "./Beneficiaries.module.scss";
import getIcon from "../../helpers/getIcon";

const Beneficiaries = ({ country, currency, type }) => {
  const navigate = useNavigate();
  const [imageIsBroken, setImageIsBroken] = useState(false);
  const {
    isLoading,
    refetch: _getBeneficaries,
    data,
  } = useQuery(
    ["get beneficiaries", country],
    () => getBeneficiaries(country),
    {
      enabled: country ? true : false,
      onSuccess: (item) => {
        console.log(item, "beneficiaries");
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.item?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleRedirect = (details) => {
    const _momoDetails = {
      momoOperatorId: Number(details?.momoOperator?.id),
      beneficiaryName: details?.accountName,
      accountName: details?.accountName,
      mobileNumber: encodeURIComponent(`${details?.mobileNumber}`),
      beneficiaryCurrency: currency,
      currency,
    };
    const _details = {
      bankId: Number(details?.bankId),
      accountNumber: details?.accountNumber,
      accountName: details?.accountName,
      currency: currency,
      beneficiaryCurrency: currency,
    };
    if (type === "momo") {
      navigate(
        `/dashboard/send/form-two?details=${JSON?.stringify(_momoDetails)}`
      );
      return;
    }
    navigate(`/dashboard/send/form-two?details=${JSON?.stringify(_details)}`);
  };
  return (
    <>
      {data && data?.length < 1 ? null : (
        <div className={styles.beneficiaries}>
          <h3 className={styles.beneficiaries__heading}>Send to</h3>
          <div className={styles.beneficiaries__items}>
            {isLoading && (
              <p className={styles.beneficiaries__items__loading}>
                <ReactLoading color="#8d8e92" width={20} height={20} />
              </p>
            )}
            {data?.map((item) => (
              <div
                onClick={() => handleRedirect(item)}
                className={styles.beneficiaries__item}
              >
                <div className={styles.beneficiaries__item__profile}>
                  {imageIsBroken ?? !item?.bank?.website ? (
                    <div className={styles.beneficiaries__item__profile__dummy}>
                      <h3>
                        {item?.accountName
                          ? `${item?.accountName[0]}${item?.accountName[1]}`
                          : `Ni`}
                      </h3>
                    </div>
                  ) : (
                    <img
                      className={styles.beneficiaries__item__profile__bank}
                      src={item?.bank?.website}
                      alt="bank"
                      onError={(e) => setImageIsBroken(true)}
                    />
                  )}

                  <img
                    className={styles.beneficiaries__item__profile__country}
                    src={getIcon(item?.bank?.country)}
                    alt="country"
                  />
                </div>
                <h3>{item?.accountName}</h3>
                <p>{item?.bank?.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Beneficiaries;
