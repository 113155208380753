import React, { useState } from "react";
import styles from "./ConfirmAction.module.scss";

const ConfirmAction = ({
  action,
  setShowConfirmActionModal,
  desc,
  heading,
}) => {
  return (
    <div className={styles.confirmAction}>
      <h3 className={styles.confirmAction__heading}>{heading}</h3>
      <p className={styles.confirmAction__desc}>{desc}</p>
      <div className={styles.confirmAction__actions}>
        <button
          onClick={() => {
            action();
            setShowConfirmActionModal(false);
          }}
          className={styles.confirmAction__submit}
        >
          Yes, delete 
        </button>
        <button
          onClick={() => setShowConfirmActionModal(false)}
          className={styles.confirmAction__cancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmAction;
