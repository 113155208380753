export default function receiveIcon(color) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="primary"
        d="M11 5.44444V11M11 1C12.9778 1 14.9112 1.58649 16.5557 2.6853C18.2002 3.78412 19.4819 5.3459 20.2388 7.17316C20.9957 9.00042 21.1937 11.0111 20.8078 12.9509C20.422 14.8907 19.4696 16.6725 18.0711 18.0711C16.6725 19.4696 14.8907 20.422 12.9509 20.8078C11.0111 21.1937 9.00042 20.9957 7.17316 20.2388C5.3459 19.4819 3.78412 18.2002 2.6853 16.5557C1.58649 14.9112 0.999999 12.9778 0.999999 11C0.999999 8.34783 2.05357 5.80429 3.92893 3.92893C5.8043 2.05357 8.34783 1 11 1ZM10.2333 16.1444L7.85555 12.6667C7.76263 12.5116 7.70892 12.3363 7.69908 12.1558C7.68925 11.9754 7.72358 11.7952 7.79911 11.631C7.87464 11.4668 7.98909 11.3235 8.13252 11.2136C8.27595 11.1036 8.44404 11.0303 8.62222 11H13.3778C13.5559 11.0303 13.724 11.1036 13.8675 11.2136C14.0109 11.3235 14.1254 11.4668 14.2009 11.631C14.2764 11.7952 14.3107 11.9754 14.3009 12.1558C14.2911 12.3363 14.2374 12.5116 14.1444 12.6667L11.7667 16.1333C11.6851 16.2624 11.5725 16.3689 11.439 16.443C11.3056 16.5172 11.1557 16.5566 11.003 16.5577C10.8504 16.5588 10.6999 16.5216 10.5654 16.4494C10.4309 16.3771 10.3167 16.2723 10.2333 16.1444Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
