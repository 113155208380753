import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { getWhitelist } from "../../../services";
import getIcon from "../../../helpers/getIcon";
import handleNotify from "../../../helpers/handleNotify";
import styles from "./Whitelist.module.scss";

//images and icons
import WhitelistEmptyImage from "../../../assets/images/sad emoji 1.png";
import BackIcon from "../../../assets/icons/back.svg";

//components
import Modal from "../../../components/Modal/Modal";
import Loader from "../../../components/Loader/Loader";
import BottomNav from "../../../components/BottomNav/BottomNav";
import WhitelistItem from "../../../components/WhitelistItem/WhitelistItem";
import CreateWhitelist from "../../../components/CreateWhitelist/CreateWhitelist";

const Whitelist = ({ data: user, setEnabled, getUserDetails }) => {
  const [showCreateWhitelist, setShowCreateWhitelist] = useState(false);
  const [showIncreaseLimit, setShowIncreaseLimit] = useState(false);
  const [showSetDefaultNotification, setShowSetDefaultNotification] =
    useState(false);
  const [activeWhitelist, setActiveWhitelist] = useState({});
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const account = useAccount();

  useEffect(() => {
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  const {
    isLoading: isGettingWhitelist,
    data: whitelist,
    refetch: _getWhitelist,
  } = useQuery(["get whitelist"], getWhitelist, {
    onSuccess: (data) => {
      console.log(data, "whitelist");
      const _defaultWhitelist = data?.find((item) => item?.isDefault);

      if (_defaultWhitelist) {
        console.log(_defaultWhitelist?.address);
      }
    },
    onError: (error) => {
      console.log({ error });
      handleNotify(
        "error",
        error?.response?.data?.message ?? "An error occurred"
      );
    },
  });

  const handleIncreaseLimit = (authorization, network) => {
    setActiveWhitelist({
      ...authorization,
      id: authorization?.id,
      spenderAddress: authorization?.spenderAddress,
    });
    setShowIncreaseLimit(true);
  };

  return (
    <>
      <div className={styles.whitelist}>
        <div className={styles.whitelist__content}>
          <div className={styles.whitelist__header}>
            <div
              onClick={() => navigate(-1)}
              className={styles.whitelist__back}
            >
              <img src={BackIcon} alt="back icon" />
            </div>
          </div>
          <h3 className={styles.whitelist__heading}>Whitelist</h3>
          <p className={styles.whitelist__desc}>
            Add wallet addresses you want to automatically receive stablecoins
            in, when someone sends you fiat via Knit Receive.
            {/* {!showMore && "..."}
            <div
              className={
                showMore
                  ? `${styles.whitelist__desc__more} ${styles.whitelist__desc__more__open}`
                  : `${styles.whitelist__desc__more}`
              }
            >
              <p className={styles.whitelist__desc__more__content}>
                Your security is our priority. You can also revoke this
                authorisation at anytime, without our permission even. It is an
                entirely decentralised process. You are providing us access to
                initiate transactions, only up to the limit you have set.
              </p>
            </div>
            <span onClick={() => setShowMore(!showMore)}>
              {showMore ? "show less" : `show more`}
            </span> */}
          </p>
          <div className={styles.whitelist__items}>
            {whitelist && whitelist?.length > 0 ? (
              whitelist?.map((whitelistItem, index) => (
                <WhitelistItem
                  whitelistItem={whitelistItem}
                  handleActivate={() => {}}
                  account={account}
                  _getWhitelist={_getWhitelist}
                  index={index}
                  setShowSetDefaultNotification={setShowSetDefaultNotification}
                  handleIncreaseLimit={handleIncreaseLimit}
                />
              ))
            ) : (
              <div
                className={`${styles.whitelist__authorization} ${styles.whitelist__authorization__empty}`}
              >
                <div
                  className={styles.whitelist__authorization__empty__content}
                >
                  <img src={WhitelistEmptyImage} alt="empty" />
                  <p>You don't have any whitelist</p>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={() => setShowCreateWhitelist(true)}
            className={styles.whitelist__create}
          >
            Create Whitelist
          </button>
        </div>
        <BottomNav active="more" />
      </div>

      {showCreateWhitelist && (
        <Modal
          showModal={showCreateWhitelist}
          setShowModal={setShowCreateWhitelist}
        >
          <CreateWhitelist
            setShowCreateWhitelist={setShowCreateWhitelist}
            user={user}
            _getWhitelist={_getWhitelist}
          />
        </Modal>
      )}

      {isGettingWhitelist && !whitelist ? <Loader /> : null}
    </>
  );
};

export default Whitelist;
