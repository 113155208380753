import React from "react";
import { Navigate, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import cookie from "../utils/cookie";

export default function Guard({ children, guard, path }) {
  const token = cookie().getCookie("spend-token");
 
  if (
    !isMobile
    && !window.location?.href?.includes("localhost")
  ) {
    return <Navigate to="/not-available" />;
  }
  if (guard) {
    return token ? children : <Navigate to="/auth/login" />;
  } else {
    return children;
  }
}
