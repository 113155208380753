import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import handleNotify from "../../helpers/handleNotify";
import { createWhitelist } from "../../services";
import styles from "./CreateWhitelist.module.scss";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

const CreateWhitelist = ({ setShowCreateWhitelist, user, _getWhitelist }) => {
  const [coin, setCoin] = useState();
  const [network, setNetwork] = useState();
  const [address, setAddress] = useState();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    console.log(user);
  }, [user]);

  const { isLoading, mutate } = useMutation(
    ["create whitelist"],
    (payload) => createWhitelist(payload),
    {
      onSuccess: (data) => {
        console.log(data, "whitelist");
        handleNotify("success", "Whitelist created successfully");
        setShowCreateWhitelist(false);
        _getWhitelist();
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message
            ? Array.isArray(error?.response?.data?.message)
              ? error?.response?.data?.message[0]
              : error?.response?.data?.message
            : "An error occurred"
        );
      },
    }
  );

  const handleCreateWhitelist = () => {
    mutate({
      address: address,
      network: network?.value,
      coin: coin?.value,
      userId: user?.user?.id,
    });
  };

  return (
    <div className={styles.createWhitelist}>
      <h3 className={styles.createWhitelist__heading}>Create Whitelist</h3>

      <div className={styles.createWhitelist__form}>
        {currentStep === 1 && (
          <StepOne
            coin={coin}
            network={network}
            setCoin={setCoin}
            setNetwork={setNetwork}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 2 && (
          <StepTwo
            address={address}
            setAddress={setAddress}
            handleCreateWhitelist={handleCreateWhitelist}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default CreateWhitelist;
