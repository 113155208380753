import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./UpgradeAccount.module.scss";

const UpgradeAccount = ({ setShowModal, desc, heading, action }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.upgradeAccount}>
      <h3 className={styles.upgradeAccount__heading}>
        {heading ?? "Account upgrade required"}
      </h3>
      <p className={styles.upgradeAccount__desc}>
        {desc ?? "Your account needs an upgrade to access this page"}
      </p>
      <div className={styles.upgradeAccount__actions}>
        <button
          onClick={() => {
            // action ? action() : navigate("/auth/kyc?kyc_tier=STANDARD");
            navigate("/dashboard/verification")
          }}
          className={styles.upgradeAccount__submit}
        >
          Upgrade
        </button>
        <button
          onClick={() => {
            setShowModal(false);
            navigate("/dashboard");
          }}
          className={styles.upgradeAccount__cancel}
        >
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default UpgradeAccount;
