import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { formatUnits } from "viem";
import { toast } from "react-toastify";
import { getAuthorizations, getExchangeRate } from "../../../services";
import useAmountFormat from "../../../Hooks/useAmountFormat";
import handleNotify from "../../../helpers/handleNotify";
import getSymbol from "../../../helpers/getSymbol";
import ReactLoading from "react-loading";
import styles from "./SendStepTwo.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
import AuthorizationEmptyImage from "../../../assets/images/sad emoji 1.png";

//components
import SelectWallet from "../../../components/SelectWallet/SelectWallet";
import Transfer from "./Transfer";

const SendForm = ({ data: userDetails, getUserDetails }) => {
  const navigate = useNavigate();
  const [activeWallet, setActiveWallet] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [details, setDetails] = useState();
  const [authorizations, setAuthorizations] = useState([]);
  const [reference, setReference] = useState();
  const [inputValue, handleAmountChange, amount] = useAmountFormat();
  const [activeToggle, setActiveToggle] = useState("transfer");

  useEffect(() => {
    console.log("users", userDetails?.networks, userDetails);
  }, [userDetails]);
  const { isLoading: isGettingAuthorizations, data: _authorizations } =
    useQuery(["get authorizations"], () => getAuthorizations(), {
      enabled: details ? true : false,
      onSuccess: (data) => {
        console.log(data, "authorizations");
        const _authorizations = data?.map((item) => ({
          ...item,
          label: `${item?.walletAddress?.slice(
            0,
            6
          )}...${item?.walletAddress?.slice(-6)}`,
          tokens: item?.tokenName ? [item?.tokenName] : null,
        }));
        setAuthorizations(_authorizations);
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    });

  const { isLoading: isGettingRate, data: exchangeRate } = useQuery(
    ["get exchange rate"],
    () => getExchangeRate(details?.currency ?? details?.beneficiaryCurrency),
    {
      enabled: details ? true : false,
      onSuccess: (data) => {
        console.log(data, "exchange rate");
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleSubmit = () => {
    if (!activeWallet) {
      handleNotify("error", "Wallet address is required");
      return;
    }
    // if (!reference) {
    //   handleNotify("error", "Reference is required");
    //   return;
    // }

    if (!amount || amount === 0) {
      handleNotify("error", "Amount is required");
      return;
    }

    const _details = {
      ...details,
      amount: Number(Number(amount / 100) * exchangeRate?.sellRate)?.toFixed(2),
      walletAuthorizationId: activeWallet?.id,
      rate: exchangeRate?.sellRate,
      tokenName: activeWallet?.tokenName,
      reference,
    };

    navigate(`/dashboard/send/pin?details=${JSON?.stringify(_details)}`);
  };

  useEffect(() => {
    const _details = searchParams.get("details");
    if (_details) {
      console.log(JSON?.parse(_details));
      setDetails(JSON?.parse(_details));
      return;
    }
    navigate("/dashboard/send");
  }, []);

  return (
    <>
      <div className={styles.sendForm}>
        <div onClick={() => navigate(-1)} className={styles.sendForm__back}>
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.sendForm__heading}>Send</h3>
        <p className={styles.sendForm__desc}>
          Send money to fiat bank accounts and wallets worldwide using crypto.
        </p>
        <div className={styles.sendForm__toggle}>
          <div
            onClick={() => setActiveToggle("transfer")}
            className={
              activeToggle === "transfer"
                ? `${styles.sendForm__toggle__item} ${styles.sendForm__toggle__item__active}`
                : `${styles.sendForm__toggle__item}`
            }
          >
            <div
              className={
                activeToggle === "transfer"
                  ? `${styles.sendForm__toggle__item__dot}`
                  : `${styles.sendForm__toggle__item__dot__active}`
              }
            ></div>
            <p>Transfer</p>
          </div>
          <div
            onClick={() => setActiveToggle("direct-debit")}
            // className={styles.sendForm__toggle__item}
            className={
              activeToggle === "direct-debit"
                ? `${styles.sendForm__toggle__item} ${styles.sendForm__toggle__item__active}`
                : `${styles.sendForm__toggle__item}`
            }
          >
            <div
              className={
                activeToggle === "direct-debit"
                  ? `${styles.sendForm__toggle__item__dot}`
                  : `${styles.sendForm__toggle__item__dot__active}`
              }
            ></div>
            <p>Direct Debit</p>
          </div>
        </div>

        {activeToggle === "transfer" ? (
          <Transfer
            user={userDetails}
            type={details?.momoOperatorId ? "MOBILE_MONEY" : "BANK"}
            getUserDetails={getUserDetails}
          />
        ) : !_authorizations || _authorizations?.length < 1 ? (
          <div className={`${styles.sendForm__authorization__empty}`}>
            <div className={styles.sendForm__authorization__empty__content}>
              <img src={AuthorizationEmptyImage} alt="empty" />
              <p>You haven’t setup any direct debit.</p>
            </div>
          </div>
        ) : (
          <div className={styles.sendForm__form}>
            <p className={styles.sendForm__desc}>
              Select a pre-authorized non-custodial wallet to complete your
              transaction.
            </p>
            <div className={styles.sendForm__form__item}>
              <label>Wallet</label>
              <SelectWallet
                options={authorizations}
                active={activeWallet}
                setActive={setActiveWallet}
                placeholder="Select Wallet"
              />
            </div>

            <div className={styles.sendForm__form__item}>
              <label>Amount({activeWallet?.tokenName ?? "USD"})</label>
              <input
                className={styles.sendForm__form__item__input}
                type="tel"
                value={inputValue}
                placeholder="0.00"
                onChange={(e) => handleAmountChange(e?.target?.value)}
              />
            </div>

            <div className={styles.sendForm__form__item}>
              <label>
                Amount(
                {getSymbol(details?.currency ?? details?.beneficiaryCurrency)})
              </label>
              <input
                className={styles.sendForm__form__item__input}
                type="tel"
                value={
                  amount > 0 && exchangeRate
                    ? `${Number(
                        Number(
                          Number(amount / 100) * exchangeRate?.sellRate
                        )?.toFixed(2)
                      )?.toLocaleString()}`
                    : 0
                }
                placeholder="0.00"
                // onChange={(e) => handleAmountChange(e?.target?.value)}
              />
              <p>
                {`1${activeWallet?.tokenName ?? "USD"} ~ ${getSymbol(
                  details?.currency ?? details?.beneficiaryCurrency
                )}${exchangeRate?.sellRate?.toFixed(2) ?? 0}`}
              </p>
            </div>

            <div className={styles.sendForm__form__item}>
              <label>Reference</label>
              <input
                className={styles.sendForm__form__item__input}
                type="text"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
              />
            </div>

            <div
              onClick={() => handleSubmit()}
              className={styles.sendForm__form__submit}
            >
              {false ? (
                <ReactLoading type="spin" width={28} height={28} />
              ) : (
                "Continue"
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SendForm;
