import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { formatUnits } from "viem";
import { toast } from "react-toastify";
import { getExchangeRate, sendFaitTransfer } from "../../../services";
import useAmountFormat from "../../../Hooks/useAmountFormat";
import handleNotify from "../../../helpers/handleNotify";
import getSymbol from "../../../helpers/getSymbol";
import ReactLoading from "react-loading";
import styles from "./SendStepTwo.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
//images
import EthereumLogo from "../../../assets/icons/ethereum.png";
import BinanceLogo from "../../../assets/icons/binance.png";
import BitcoinLogo from "../../../assets/icons/bitcoin.png";
import PolygonLogo from "../../../assets/icons/polygon.png";
import BSCNETWORKLogo from "../../../assets/icons/bsc-network.jpg";
import BNBLogo from "../../../assets/icons/bnb.jpg";
import USDT_BSC from "../../../assets/icons/usdt-bsc.jpg";
import USDT_OTHER from "../../../assets/icons/usdt-other.jpg";
import Dai from "../../../assets/icons/dai.jpg";
import Busd from "../../../assets/icons/busd.png";
import Usdc from "../../../assets/icons/usdc.png";

//components
import SelectWallet from "../../../components/SelectWallet/SelectWallet";
import Select from "../../../components/Select/Select";

const Transfer = ({ user, getUserDetails, type }) => {
  const navigate = useNavigate();
  const [activeWallet, setActiveWallet] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [details, setDetails] = useState();
  const [authorizations, setAuthorizations] = useState([]);
  const [reference, setReference] = useState();
  const [inputValue, handleAmountChange, amount] = useAmountFormat();
  const [content, setContent] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("initials");
  const [coinOptions, setCoinOptions] = useState();
  const [activeNetwork, setActiveNetwork] = useState();
  const [networks, setNetworks] = useState();
  const [currency, setCurrency] = useState();

  useEffect(() => {
    if (!user) {
      getUserDetails();
      return;
    }
    if (user) {
      setCoinOptions(user?.networks);
    }
  }, [user]);

  useEffect(() => {
    if (coinOptions) {
      const _networks = [];
      for (let p in coinOptions) {
        console.log(p, "keys");
        // if (p === "BTC") {
        //   _networks.push({
        //     name: "Bitcoin",
        //     short: "BTC",
        //     key: "BTC",
        //     icon: BitcoinLogo,
        //   });
        // } else if (p === "ETHEREUM_MAINNET") {
        //   _networks.push({
        //     name: "Ethereum",
        //     key: "ETHEREUM_MAINNET",
        //     short: "ETH",
        //     icon: EthereumLogo,
        //   });
        // }
        if (p === "MATIC_MAINNET") {
          _networks.push({
            name: "Polygon",
            key: "MATIC_MAINNET",
            short: "MATIC",
            icon: PolygonLogo,
          });
        } else if (p === "BSC_MAINNET") {
          _networks.push({
            name: "BSC",
            key: "BSC_MAINNET",
            short: "BSC",
            icon: BSCNETWORKLogo,
          });
        } else {
          //   _networks.push({
          //     name: p,
          //     key: p,
          //     short: p,
          //     icon: BitcoinLogo,
          //   });
        }
      }
      setNetworks(_networks);
      setActiveNetwork(_networks[0]);
    }
  }, [coinOptions]);

  const [coins, setCoins] = useState([]);
  const [activeCoin, setActiveCoin] = useState({});
  const [coinValue, setCoinValue] = useState({ number_of_coins: 0, coin: "" });
  const [remotePayment, setRemotePayment] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (coinOptions && activeNetwork) {
      const _coins = [];
      coinOptions[activeNetwork?.key].tokens?.forEach((item) => {
        // if (item === "BTC") {
        //   _coins.push({ name: "BTC", short: "BTC", icon: BitcoinLogo });
        // } else if (item === "ETH") {
        //   _coins.push({
        //     name: "ETH",
        //     short: "ETH",
        //     icon: EthereumLogo,
        //   });
        // } else if (item === "BUSD") {
        //   _coins.push({
        //     name: "BUSD",
        //     short: "BUSD",
        //     icon: Busd,
        //   });
        // }
        // if (item === "USDC") {
        //   _coins.push({
        //     name: "USDC",
        //     short: "USDC",
        //     icon: Usdc,
        //   });
        // }
        //else if (item === "BNB") {
        //   _coins.push({
        //     name: "BNB",
        //     short: "BNB",
        //     icon: BNBLogo,
        //   });
        // } else if (item === "DAI") {
        //   _coins.push({
        //     name: "DAI",
        //     short: "DAI",
        //     icon: Dai,
        //   });
        // } else if (item === "MATIC") {
        //   _coins.push({
        //     name: "MATIC",
        //     short: "MATIC",
        //     icon: PolygonLogo,
        //   });
        // }
        if (item === "USDT" && activeNetwork?.key === "BSC_MAINNET") {
          _coins.push({
            name: "USDT",
            short: "USDT",
            icon: USDT_BSC,
          });
        } else if (item === "USDT" && activeNetwork?.key !== "BSC_MAINNET") {
          console.log(activeNetwork?.key);
          _coins.push({
            name: "USDT",
            short: "USDT",
            icon: USDT_OTHER,
          });
        } else {
          // _coins.push({
          //   name: item,
          //   short: item,
          //   icon: EthereumLogo,
          // });
        }
      });
      setCoins(_coins);
      setActiveCoin(_coins[0]);
    }
  }, [activeNetwork, coinOptions]);

  const { isLoading, mutate: _sendFaitTransfer } = useMutation(
    ["send fait"],
    (payload) => sendFaitTransfer(payload),
    {
      onSuccess: (data) => {
        console.log(data);
        const _rate = formatUnits(
          data?.customerTransaction?.tokenAmount,
          data?.customerTransaction?.tokenUnits
        );
        console.log(data, _rate);

        const _details = {
          ...details,
          amount: data?.customerTransaction?.fiatAmount,
          network: activeNetwork,
          coin: activeCoin,
          rate: _rate,
          tokenName: activeWallet?.tokenName,
          address: data?.transaction?.toAddress,
          date: data?.transaction?.createdAt,
          transactionId: data?.customerTransaction?.id,
        };

        navigate(
          `/dashboard/send/transfer-details?details=${JSON?.stringify(
            _details
          )}`
        );
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const { isLoading: isGettingRate, data: exchangeRate } = useQuery(
    ["get exchange rate"],
    () => getExchangeRate(details?.currency ?? details?.beneficiaryCurrency),
    {
      enabled: details ? true : false,
      onSuccess: (data) => {
        console.log(data, "exchange rate");
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleSubmit = () => {
    if (!amount || amount === 0) {
      handleNotify("error", "Amount is required");
      return;
    }
    if (!exchangeRate?.sellRate) {
      handleNotify(
        "error",
        "Rates not found, check your internet connection and try again"
      );
      return;
    }
    const { beneficiaryName, beneficiaryCurrency, ...updatedDetails } = details;
    const _details = {
      ...updatedDetails,
      amount: `${Number(Number(amount / 100) * exchangeRate?.sellRate)?.toFixed(
        2
      )}`,
      network: activeNetwork?.key,
      accountType: type,
      coin: activeCoin?.short,
      tokenName: activeWallet?.tokenName,
      reference,
    };

    _sendFaitTransfer(_details);
  };

  useEffect(() => {
    const _details = searchParams.get("details");
    if (_details) {
      console.log(JSON?.parse(_details));
      setDetails(JSON?.parse(_details));
      return;
    }
    navigate("/dashboard/send");
  }, []);

  return (
    <>
      {!coinOptions ? null : (
        <>
          <p className={styles.sendForm__transfer__desc}>
            Transfer equivalent stablecoin from non-custodial wallets and
            exchanges to complete your transaction.
          </p>
          <Select
            active={activeNetwork}
            setActive={setActiveNetwork}
            options={networks}
            isRemote={remotePayment}
            heading="Network"
          />
          <Select
            heading="Coin"
            active={activeCoin}
            setActive={setActiveCoin}
            isRemote={remotePayment}
            options={coins ? coins : []}
          />

          <div className={styles.sendForm__form__item}>
            <label>Amount({activeCoin?.name})</label>
            <input
              className={styles.sendForm__form__item__input}
              type="tel"
              value={inputValue}
              placeholder="0.00"
              onChange={(e) => handleAmountChange(e?.target?.value)}
            />
          </div>

          <div className={styles.sendForm__form__item}>
            <label>
              Amount(
              {getSymbol(details?.currency ?? details?.beneficiaryCurrency)})
            </label>
            <input
              className={styles.sendForm__form__item__input}
              type="tel"
              value={
                amount > 0 && exchangeRate?.sellRate
                  ? `${Number(
                      Number(
                        Number(amount / 100) * exchangeRate?.sellRate
                      )?.toFixed(2)
                    )?.toLocaleString()}`
                  : 0
              }
              placeholder="0.00"
              // onChange={(e) => handleAmountChange(e?.target?.value)}
            />
            <p>
              {`1${activeCoin?.name ?? "USD"} ~ ${getSymbol(
                details?.currency ?? details?.beneficiaryCurrency
              )}${exchangeRate?.sellRate?.toFixed(2) ?? 0}`}
            </p>
          </div>

          <div className={styles.sendForm__form__item}>
            <label>Reference</label>
            <input
              className={styles.sendForm__form__item__input}
              type="text"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </div>

          <div
            onClick={() => handleSubmit()}
            className={styles.sendForm__form__submit}
          >
            {isLoading ? (
              <ReactLoading type="spin" width={28} height={28} />
            ) : (
              "Continue"
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Transfer;
