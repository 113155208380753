import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import useOutOfFocus from "../../Hooks/useOutOfFocus.js";
import { userDetails } from "../../services/index.js";
import handleNotify from "../../helpers/handleNotify.js";
import cookie from "../../utils/cookie";
import styles from "./Processing.module.scss";
import Rocket from "../../assets/images/rocket.svg";
import Menu from "../../assets/icons/menu-alt.svg";
import SupportIcon from "../../assets/icons/support.png";
import LogoutIcon from "../../assets/icons/power.png";

const Processing = ({
  data: user,
  getUserDetails: getUserDetailsMain,
  setEnabled: setUserFetchEnabled,
}) => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [kycTier, setKycTier] = useState();
  const [searchParams] = useSearchParams();
  const menuRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(menuRef);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowMenu(false);
    }
  }, [isOutOfFocus, setIsOutOfFocus]);

  const { refetch: getUserDetails } = useQuery(
    ["get user details"],
    userDetails,
    {
      enabled: enabled,
      onSuccess: (data) => {
        if (
          kycTier === "TEASER" &&
          (data?.user?.userSettings?.kycTeaserStatus === "FAILED" ||
            data?.user?.userSettings?.kycStandardStatus === "PENDING" ||
            !data?.user?.userSettings?.kycTeaserStatus)
        ) {
          handleNotify("error", "KYC TEASER verification failed");
          navigate(`/auth/kyc/failed?kyc_tier=${kycTier}`);
          return;
        }

        if (
          kycTier === "STANDARD" &&
          (data?.user?.userSettings?.kycStandardStatus === "FAILED" ||
            data?.user?.userSettings?.kycStandardStatus === "PENDING" ||
            !data?.user?.userSettings?.kycStandardStatus)
        ) {
          handleNotify("error", "KYC STANDARD verification failed");
          navigate(`/auth/kyc/failed?kyc_tier=${kycTier}`);
          return;
        }
        if (
          kycTier === "TEASER" &&
          data?.user?.userSettings?.kycTeaserStatus === "SUCCESSFUL"
        ) {
          handleNotify("success", "KYC TEASER verification successful");
          navigate("/dashboard");
          return;
        }
        if (
          kycTier === "STANDARD" &&
          data?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL"
        ) {
          handleNotify("success", "KYC STANDARD verification successful");
          navigate("/dashboard");
          return;
        }
        if (
          data?.user?.userSettings?.kycTeaserStatus === "PROCESSING" ||
          data?.user?.userSettings?.kycStandardStatus === "PROCESSING"
        ) {
          handleNotify(
            "success",
            "We're verifying your identity, kindly check back later"
          );
          return;
        }
      },
      onError: (error) => {
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  useEffect(() => {
    setTimeout(() => {
      setEnabled(true);
      getUserDetails();
      getUserDetailsMain();
    }, 7000);
  }, []);

  const handleLogout = () => {
    cookie().deleteCookie("spend-token");
    setUserFetchEnabled(false);
    navigate("/auth/login");
  };

  useEffect(() => {
    const _kycTier = searchParams.get("kyc_tier");
    if (_kycTier && _kycTier !== "undefined") {
      setKycTier(_kycTier);
      return;
    }
    handleNotify("error", "KYC tier not found");
  }, []);

  return (
    <div className={styles.processing}>
      <div
        ref={menuRef}
        onClick={() => setShowMenu(!showMenu)}
        className={styles.processing__menu}
      >
        <img
          className={styles.processing__menu__icon}
          alt="menu icon"
          src={Menu}
        />

        {showMenu && (
          <div className={styles.processing__menu__items}>
            <div
              id="launcher"
              onClick={() => {
                window.Intercom("boot", {
                  api_base: "https://api-iam.intercom.io",
                  custom_launcher_selector: "#launcher",
                  hide_default_launcher: true,
                  app_id: "yvi7xvf6",
                  user_id: user?.user?.id,
                  email: user?.user?.email,
                });
                // window.Intercom("hide");
                window.Intercom("showMessages");
              }}
              className={styles.processing__menu__item}
            >
              <img src={SupportIcon} alt="support icon" />
              <p>Support</p>
            </div>
            <div
              onClick={() => handleLogout()}
              className={styles.processing__menu__item}
            >
              <img src={LogoutIcon} alt="logout icon" />
              <p>Log Out</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles.processing__main}>
        <img src={Rocket} alt="rocket illustration" />
        <div className={styles.processing__content}>
          <h3>Sit tight for takeoff!</h3>
          <p>
            We are verifying your identity. You’re about to experience
            awesomeness.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Processing;
