import USDTIcon from "../assets/icons/usdt.svg";
import USDCIcon from "../assets/icons/usdc.png";
import BSCIcon from "../assets/icons/bsc.svg";
import ARBIcon from "../assets/icons/Arbitrum.svg";
import MaticIcon from "../assets/icons/polygon.svg";
import NigeriaFlag from "../assets/images/Nigeria.svg";
import UnitedStatesFlag from "../assets/images/united-states-3 1.svg";
import CanadaFlag from "../assets/images/canada-2 1.svg";
import UAEFlag from "../assets/images/united-arab-emirates 1.svg";
import UKFlag from "../assets/images/united-kingdom-3 1.svg";
import AustraliaFlag from "../assets/images/australia-2 1.svg";
import EUFlag from "../assets/images/european 1.svg";
import SwizFlag from "../assets/images/switzerland 1.svg";
import GhanaFlag from "../assets/images/Ghana.svg";
import KenyaFlag from "../assets/images/Kenya.svg";
import EthereumLogo from "../assets/icons/ethereum.png";
import BinanceLogo from "../assets/icons/binance.png";
import BitcoinLogo from "../assets/icons/bitcoin.png";
import BNBLogo from "../assets/icons/bnb.jpg";
import Dai from "../assets/icons/dai.jpg";
import Busd from "../assets/icons/busd.png";
import SAFlag from "../assets/icons/South Africa (ZA).svg";

export default function getIcon(name) {
  switch (name) {
    // case "BSC_MAINNET":
    //   return BitcoinLogo;
    case "ETHEREUM_MAINNET":
      return EthereumLogo;
    case "BTC":
      return BitcoinLogo;
    case "USDT":
      return USDTIcon;
    case "DAI":
      return Dai;
    case "BUSD":
      return Busd;
    case "BNB":
      return BNBLogo;
    case "USDC":
      return USDCIcon;
    case "MATIC_MAINNET":
      return MaticIcon;
    case "MATIC":
      return MaticIcon;
    case "BSC_MAINNET":
      return BSCIcon;
    case "ARBITRUM_MAINNET":
      return ARBIcon;
    case "NGN":
      return NigeriaFlag;
    case "NG":
      return NigeriaFlag;
    case "GHS":
      return GhanaFlag;
    case "GH":
      return GhanaFlag;
    case "KES":
      return KenyaFlag;
    case "KE":
      return KenyaFlag;
    case "USD":
      return UnitedStatesFlag;
    case "US":
      return UnitedStatesFlag;
    case "CAD":
      return CanadaFlag;
    case "UAE":
      return UAEFlag;
    case "GBP":
      return UKFlag;
    case "GB":
      return UKFlag;
    case "EUR":
      return EUFlag;
    case "S":
      return SwizFlag;
    case "AUD":
      return AustraliaFlag;
    case "ZAR":
      return SAFlag;
    default:
      return null;
  }
}
