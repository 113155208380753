import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import QRCode from "react-qr-code";
import ReactLoading from "react-loading";
import getSymbol from "../../../helpers/getSymbol";
import getIcon from "../../../helpers/getIcon";
import styles from "./TransferDetails.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
import InfoIcon from "../../../assets/icons/info-fill.svg";

//components

const TransferDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const [countdown, setCountdown] = useState();
  const [secondsRemaining, setSecondsRemaining] = useState();
  const [minutesRemaining, setMinutesRemaining] = useState();
  const interval = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const _details = searchParams.get("details");
    if (_details) {
      setDetails(JSON.parse(_details));
      return;
    }
    // navigate("-1");
  }, [navigate, searchParams]);

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);

    toast.success("Link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    if (details?.date) {
      const timeDiff = Math.abs(
        new Date(details?.date)?.getTime() - new Date()?.getTime()
      );
      const timeDiffSec = Math.ceil(timeDiff / 1000);
      setCountdown(timeDiffSec);
    }
  }, [details?.date]);

  useEffect(() => {
    if (countdown) {
      const handleCountdown = () => {
        const timeDiff = Math.abs(
          new Date(details?.date)?.getTime() - new Date()?.getTime()
        );

        const _countdown = Math.ceil(timeDiff / 1000);
        const _time = 15 * 60 - _countdown > 0 ? 15 * 60 - _countdown : 0;
        const minutes = _time > 0 ? Math.floor(_time / 60) : 0;
        const seconds = _time > 0 ? _time - minutes * 60 : 0;

        setMinutesRemaining(minutes);
        setSecondsRemaining(seconds);
      };
      handleCountdown();
      interval.current = setInterval(() => {
        handleCountdown();
      }, 1000);

      if (15 * 60 - countdown < 1) {
        clearInterval(interval?.current);
      }
    }
  }, [countdown, secondsRemaining, minutesRemaining]);

  return (
    <>
      <div className={styles.transferDetails}>
        {/* <div
          onClick={() => navigate(-1)}
          className={styles.transferDetails__back}
        >
          <img src={BackIcon} alt="back icon" />
        </div> */}

        <div className={styles.transferDetails__heading}>
          <h3>Transfer Details</h3>
        </div>

        <div className={styles.transferDetails__info}>
          <img src={InfoIcon} />
          <p>
            <b>
              {Number(details?.rate)?.toFixed(3)?.toLocaleString()}
              {details?.coin?.name ?? ""}
            </b>{" "}
            to{" "}
            <b>
              {getSymbol(details?.currency ?? details?.beneficiaryCurrency)}
              {Number(details?.amount)?.toLocaleString()}
            </b>{" "}
            ({details?.accountName ?? details?.beneficiaryName})
          </p>
          {/* ${Number(details?.amount / details?.rate)
              ?.toFixed(3)
              ?.toLocaleString()}${details?.tokenName ?? ""} */}
        </div>
        <div className={styles.transferDetails__qrCode}>
          <QRCode
            value={details?.address ?? ""}
            size={100}
            style={{
              height: "auto",
              maxWidth: "150px",
              width: "100%",
              margin: "auto",
            }}
            viewBox={`0 0 156 156`}
          />
        </div>
        <div className={styles.transferDetails__form__item}>
          <label>Address</label>
          <div className={styles.transferDetails__form__item__input}>
            <input
              // className={styles.transferDetails__form__item__input}
              type="text"
              value={details?.address}
            />
            <button onClick={() => handleCopy(details?.address)}>Copy</button>
          </div>
        </div>
        <div className={styles.transferDetails__others}>
          <div className={styles.transferDetails__others__item}>
            <label>Network</label>
            <div className={styles.transferDetails__others__item__content}>
              <img src={getIcon(details?.network?.key)} alt={`network`} />
              <input
                type="string"
                value={details?.network?.name}
                onChange={() => {}}
              />
            </div>
          </div>
          <div className={styles.transferDetails__others__item}>
            <label>Coin</label>
            <div className={styles.transferDetails__others__item__content}>
              <img src={getIcon(details?.coin?.name)} alt={`coin`} />
              <input
                type="string"
                value={details?.coin?.name}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
        {countdown > 0 && minutesRemaining < 1 && secondsRemaining < 1 ? (
          <div className={styles.transferDetails__warning}>
            <h3 style={{ color: "#E74C3C" }}>
              Address generated for this transaction has expired!
            </h3>
            <p>Please initiate another transaction</p>
          </div>
        ) : (
          <div className={styles.transferDetails__warning}>
            <h3>
              Wallet address and transaction will expire in{" "}
              <b
                style={
                  countdown > 0 && minutesRemaining < 1 && secondsRemaining < 1
                    ? { color: "tomato" }
                    : {}
                }
              >
                <span>{minutesRemaining ?? 0}</span>:
                <span>{secondsRemaining ?? 0}</span>
              </b>
            </h3>
            <p>
              Please only send crypto to this address within the stipulated
              time.
            </p>
          </div>
        )}
        <div className={styles.transferDetails__actions}>
          <button
            onClick={() =>
              navigate(`/dashboard/transaction?id=${details?.transactionId}`)
            }
            className={styles.transferDetails__actions__complete}
          >
            I have made transfer
          </button>
          <button
            onClick={() => navigate("/dashboard")}
            className={styles.transferDetails__actions__redirect}
          >
            Back To Dashboard
          </button>
        </div>
      </div>
    </>
  );
};

export default TransferDetails;
