import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useQuery } from "react-query";

import styles from "./Home.module.scss";

//helpers
import { getTransactions } from "../../../services";
import getProfile from "../../../helpers/getProfile";
import handleNotify from "../../../helpers/handleNotify";
import useOutOfFocus from "../../../Hooks/useOutOfFocus";
import cookie from "../../../utils/cookie";

//components
import Transaction from "../../../components/Transaction/Transaction";
import QuickAccess from "../../../components/QuickAccess/QuickAccess";
import Modal from "../../../components/Modal/Modal";
import AddCard from "../../../components/AddCard/AddCard";
import UnlinkCard from "../../../components/UnlinkCard/UnlinkCard";
import DeleteCard from "../../../components/DeleteCard/DeleteCard";
import Loader from "../../../components/Loader/Loader";
import BottomNav from "../../../components/BottomNav/BottomNav";
import Notification from "../../../components/Notification/Notification";
import Tag from "../../../components/Tag/Tag";
import ReferTag from "../../../components/ReferTag/ReferTag";
//images

import Menu from "../../../assets/icons/menu-alt.svg";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import SupportIcon from "../../../assets/icons/support.png";
import LogoutIcon from "../../../assets/icons/power.png";
import TransactionEmptyImage from "../../../assets/images/transaction-empty.png";

//card images
import CardReaderImage from "../../../assets/icons/card-reader.svg";
import CardProviderImage from "../../../assets/icons/card-provider.svg";
import SpendLogo from "../../../assets/images/knit logo 2 2.svg";
import WifiIcon from "../../../assets/icons/wifi.svg";

//action images
import FreezeIcon from "../../../assets/icons/snow-flake-svgrepo-com 1.svg";
import DetailsIcon from "../../../assets/icons/icons8-eye 1.svg";
import LinkIcon from "../../../assets/icons/link.svg";
import DeleteIcon from "../../../assets/icons/delete-alt.svg";
import PlusIcon from "../../../assets/icons/plus-variant.svg";

const Home = ({
  getUserDetails,
  setEnabled,
  data: user,
  isLoadingUserDetails,
}) => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [showUnlinkCardModal, setShowUnlinkCardModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [quickAccessPending, setQuickAccessPending] = useState(false);
  const [logo, setLogo] = useState(getProfile());
  const menuRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(menuRef);
  const [showMenu, setShowMenu] = useState(false);
  const [activeCard, setActiveCard] = useState(1);

  const { address, isConnected } = useAccount();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowMenu(false);
    }
  }, [isOutOfFocus, setIsOutOfFocus]);

  const scrollRef = useRef(null);
  useEffect(() => {
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  useEffect(() => {
    if (user) {
      if (
        !user?.user?.userSettings?.hasSetTransactionPin ||
        user?.user?.userSettings?.hasSetTransactionPin !== true
      ) {
        navigate("/auth/set-pin");
      }
      //  else if (user?.user?.userSettings?.kycTeaserStatus !== "SUCCESSFUL") {
      //   if (user?.user?.userSettings?.kycStandardStatus === "SUCCESSFUL") {
      //     return;
      //   }
      //   if (
      //     user?.locale === "NG" ||
      //     user?.locale === "KE" ||
      //     user?.locale === "GH"
      //   ) {
      //     navigate(`/auth/kyc?kyc_tier=TEASER`);
      //   } else {
      //     navigate(`/auth/kyc?kyc_tier=STANDARD`);
      //   }
      // }
    }
  }, [user, navigate]);

  const [cards, setCards] = useState([
    {
      label: "-",
      frozen: false,
      empty: true,
    },
    {
      number: "1234",
      provider: "sample",
      label: "-",
      frozen: false,
    },

    // {
    //   label: "-",
    //   frozen: false,
    //   empty: true,
    // },
  ]);

  const {
    isLoading: isGettingTransactions,
    data: transactions,
    refetch: _getTransactions,
  } = useQuery(["get transactions"], () => getTransactions(), {
    onSuccess: (data) => {
      console.log(data, "transactions");
    },
    onError: (error) => {
      console.log({ error });
      handleNotify(
        "error",
        error?.response?.data?.message ?? "An error occurred"
      );
    },
  });

  const handleScroll = (ref, position) => {
    if (position === "initial") {
      const initialPosition = ref.current.scrollWidth / cards?.length;
      ref.current.scrollLeft += initialPosition - 70;
      console.log(initialPosition, "initial");
    } else if (position === "right") {
      ref.current.scrollLeft -= ref.current.scrollWidth / cards?.length;
    } else {
      ref.current.scrollLeft += ref.current.scrollWidth / cards?.length;
    }
  };

  const handleLogout = () => {
    cookie().deleteCookie("spend-token");
    setEnabled(false);
    navigate("/auth/login");
  };

  const handleScrollAction = (ref) => {
    const _activeCard = Math.round(
      ref.current.scrollLeft / (ref.current.scrollWidth / cards?.length)
    );
    setActiveCard(_activeCard + 1);
  };

  const handleCardFreeze = () => {
    let _cards = [...cards];
    _cards[activeCard - 1].frozen = !_cards[activeCard - 1].frozen;
    console.log(_cards);
    setCards(_cards);
  };

  useEffect(() => {
    handleScroll(scrollRef, "initial");
  }, []);

  useEffect(() => {
    return () => {
      window.Intercom("shutdown");
    };
  }, [user]);

  return (
    <>
      <div className={styles.home}>
        <div className={styles.home__content}>
          <div className={styles.home__heading}>
            <img src={logo} className={styles.home__heading__logo} alt="logo" />
            {/* <button
            onClick={() => navigate("/dashboard/authorizations")}
            className={styles.home__heading__authorizations}
          >
            Authorizations
          </button> */}

            <div
              ref={menuRef}
              onClick={() => setShowMenu(!showMenu)}
              className={styles.home__heading__menu}
            >
              <img
                className={styles.home__heading__menu__icon}
                alt="menu icon"
                src={Menu}
              />

              {showMenu && (
                <div className={styles.home__heading__menu__items}>
                  <div
                    id="launcher"
                    onClick={() => {
                      window.Intercom("boot", {
                        api_base: "https://api-iam.intercom.io",
                        custom_launcher_selector: "#launcher",
                        hide_default_launcher: true,
                        app_id: "yvi7xvf6",
                        user_id: user?.user?.id,
                        email: user?.user?.email,
                      });
                      // window.Intercom("hide");
                      console.log("called");
                      window.Intercom("showMessages");
                    }}
                    className={styles.home__heading__menu__item}
                  >
                    <img src={SupportIcon} alt="support icon" />
                    <p>Support</p>
                  </div>
                  <div
                    onClick={() => handleLogout()}
                    className={styles.home__heading__menu__item}
                  >
                    <img src={LogoutIcon} alt="logout icon" />
                    <p>Log Out</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.home__select}>
            <div
              onClick={() => handleScroll(scrollRef, "right")}
              className={styles.home__select__arrow}
            >
              <img alt="select arrow" src={ArrowLeft} />
            </div>
            <div className={styles.home__select__content}>
              <p>{cards[activeCard - 1]?.label}</p>
            </div>
            <div
              onClick={() => handleScroll(scrollRef)}
              className={styles.home__select__arrow}
            >
              <img alt="select arrow" src={ArrowRight} />
            </div>
          </div>

          <div
            onScroll={() => handleScrollAction(scrollRef)}
            ref={scrollRef}
            className={styles.home__cards}
          >
            {/* <div className={styles.home__card__filler}></div> */}
            {cards?.map((card) =>
              card?.empty ? (
                <div className={styles.home__card__filler}>
                  <div
                    onClick={() =>
                      // setShowAddCard(true)
                      setShowNotificationModal(true)
                    }
                    className={styles.home__card__filler__add}
                  >
                    <img src={PlusIcon} />
                  </div>
                </div>
              ) : (
                <div
                  className={
                    card?.frozen
                      ? `${styles.home__card} ${styles.home__card__frozen}`
                      : `${styles.home__card}`
                  }
                >
                  <div className={styles.home__card__heading}>
                    <img src={SpendLogo} alt="spend logo" />
                    <p>****</p>
                    {/* 7y6z */}
                  </div>

                  <div className={styles.home__card__reader}>
                    <img src={CardReaderImage} alt="card reader" />
                    <img src={WifiIcon} alt="wifi" />
                  </div>

                  <div className={styles.home__card__details}>
                    <h3>**** **** **** ****</h3>
                    <img src={CardProviderImage} alt="card provider" />
                  </div>
                </div>
              )
            )}
            <div className={styles.home__card__nill}></div>

            {/* <div className={styles.home__card__filler}></div> */}
          </div>

          <div className={styles.home__actions}>
            <div
              // onClick={() => navigate("/dashboard/card-details")}
              onClick={() => setShowNotificationModal(true)}
              className={styles.home__action}
            >
              <div className={styles.home__action__icon}>
                <img src={DetailsIcon} alt="action icon" />
              </div>
              <p className={styles.home__action__title}>Details</p>
            </div>

            <div
              className={styles.home__action}
              // onClick={() => setShowUnlinkCardModal(true)}
              onClick={() => setShowNotificationModal(true)}
            >
              <div className={styles.home__action__icon}>
                <img src={LinkIcon} alt="action icon" />
              </div>
              <p className={styles.home__action__title}>Link</p>
            </div>

            <div
              // onClick={handleCardFreeze}
              onClick={() => setShowNotificationModal(true)}
              className={styles.home__action}
            >
              <div className={styles.home__action__icon}>
                <img src={FreezeIcon} alt="action icon" />
              </div>
              <p className={styles.home__action__title}>
                {cards[activeCard - 1]?.frozen ? "Unfreeze" : "Freeze"}
              </p>
            </div>

            <div
              className={styles.home__action}
              // onClick={() => setShowDeleteCardModal(true)}
              onClick={() => setShowNotificationModal(true)}
            >
              <div className={styles.home__action__icon}>
                <img src={DeleteIcon} alt="action icon" />
              </div>
              <p className={styles.home__action__title}>Delete</p>
            </div>
          </div>
          <div className={styles.home__tags}>
            {user?.user?.userSettings?.kycTeaserStatus !== "SUCCESSFUL" &&
            user?.user?.userSettings?.kycStandardStatus !== "SUCCESSFUL" ? (
              <Tag
                heading="Verify your account"
                desc="Submit your ID and selfie to get your account verified."
                action={() => navigate("/dashboard/verification")}
              />
            ) : (
              <ReferTag action={() => navigate("/dashboard/earn-money")} />
            )}
          </div>
          <div className={styles.home__quickAccess}>
            <QuickAccess setQuickAccessPending={setQuickAccessPending} />
          </div>

          <div className={styles.home__transactions}>
            <div className={styles.home__transactions__heading}>
              <h3>Transactions</h3>
              <p
                onClick={() => navigate("/dashboard/transactions")}
                className={styles.home__transactions__more}
              >
                See all
              </p>
            </div>

            {transactions && transactions?.length > 0 ? (
              <div className={styles.home__transactions__content}>
                {transactions &&
                  transactions
                    ?.slice(0, 3)
                    ?.map((transaction) => (
                      <Transaction transaction={transaction} />
                    ))}
              </div>
            ) : (
              <div className={styles.home__transactions__empty}>
                <img
                  src={TransactionEmptyImage}
                  alt="transaction is empty icon"
                />
                <p>No transaction yet</p>
              </div>
            )}
          </div>
        </div>

        <BottomNav active="home" />
      </div>
      {showDeleteCardModal && (
        <Modal setShowModal={setShowDeleteCardModal}>
          <DeleteCard />
        </Modal>
      )}
      {showUnlinkCardModal && (
        <Modal setShowModal={setShowUnlinkCardModal}>
          <UnlinkCard />
        </Modal>
      )}
      {showAddCard && (
        <Modal setShowModal={setShowAddCard}>
          <AddCard />
        </Modal>
      )}
      {showNotificationModal && (
        <Modal setShowModal={setShowNotificationModal}>
          <Notification
            heading="Coming Soon"
            content="Knit cards enable direct spending with virtual cards from self-custody wallets. We're gradually rolling out this feature, and we'll notify you
        when it's available to you."
          />
        </Modal>
      )}
      {quickAccessPending && (
        <Modal setShowModal={setQuickAccessPending}>
          <Notification
            heading="Coming Soon"
            content="Buy local airtime, data bundle and more directly with crypto"
          />
        </Modal>
      )}
      {isLoadingUserDetails || isGettingTransactions ? <Loader /> : null}
    </>
  );
};

export default Home;
