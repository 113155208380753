import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useMutation, useQueries } from "react-query";
import { toast } from "react-toastify";
import getIcon from "../../../helpers/getIcon";
import handleNotify from "../../../helpers/handleNotify";
import {
  getVirtualAccounts,
  getWhitelist,
  getAuthorizations,
  getExchangeRate,
} from "../../../services";
import styles from "./Receive.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";

//components
import Loader from "../../../components/Loader/Loader";
import BottomNav from "../../../components/BottomNav/BottomNav";
import Modal from "../../../components/Modal/Modal";
import UpgradeAccount from "../../../components/UpgradeAccount/UpgradeAccount";
import ReceiveDirectDebit from "../../../components/ReceiveDirectDebit/ReceiveDirectDebit";

const Receive = ({
  data: user,
  setEnabled,
  getUserDetails,
  isLoadingUserDetails,
}) => {
  const [active, setActive] = useState({});
  const navigate = useNavigate();
  const [upgradeRequired, setUpgradeRequired] = useState(false);
  const [shouldAddDirectDebit, setShouldAddDirectDebit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExternalRedirect, setIsExternalRedirect] = useState(false);

  const [
    {
      isLoading: isGettingAccounts,
      refetch: _getVirtualAccounts,
      data: accountDetails,
    },
    { isLoading: isGettingRate, 
      refetch: _getExchangeRate, 
      data: exchangeRate },
    {
      isLoading: isGettingAuthorizations,
      refetch: _getAuthorizations,
      data: authorizations,
    },
    { isLoading: isGettingWhitelist, 
      refetch: _getWhitelist, 
      data: whitelist },
  ] = useQueries([
    {
      queryKey: ["get virtual accounts", active],
      queryFn: () => getVirtualAccounts(active?.short),
      enabled: active?.short === "NG" ? true : false,
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    },
    {
      queryKey: ["get exchange rate", active],
      queryFn: () => getExchangeRate(active?.title),
      enabled: active?.short === "NG" ? true : false,
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    },
    {
      queryKey: ["get authorizations", active],
      queryFn: () => getAuthorizations(),
      enabled: active?.short === "NG" ? true : false,
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    },
    {
      queryKey: ["get whitelist", active],
      queryFn: () => getWhitelist(),
      enabled: active?.short === "NG" ? true : false,
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    },
  ]);

 

  useEffect(() => {
    if (!user) {
      setEnabled(true);
      getUserDetails();
    }
  }, [user, setEnabled, getUserDetails]);

  useEffect(() => {
    if (user && user?.user?.userSettings?.kycStandardStatus !== "SUCCESSFUL") {
      setUpgradeRequired(true);
    }
  }, [user]);

  const handleRedirect = () => {
    if (accountDetails && authorizations && exchangeRate) {
      const _activeAccount = accountDetails?.find(
        (item) => item?.currency === "NGN"
      );
      const _defaultWallet = authorizations?.find(
        (authorization) => authorization?.isDefault
      );
      const _defaultWhitelist = whitelist?.find((item) => item?.isDefault);
      if (!_activeAccount) {
        // _createVirtualAccount();
        handleNotify("error", "Account not found");
        return;
      }

      if (!_defaultWallet && !_defaultWhitelist) {
        console.log("called");
        setShouldAddDirectDebit(true);
        return;
      }

      const details = {
        account: _activeAccount,
        walletAddress:
          _defaultWallet?.walletAddress ?? _defaultWhitelist?.address,
        tokenName: _defaultWallet?.tokenName ?? _defaultWallet?.coin,
        exchangeRate: exchangeRate,
      };

      isExternalRedirect
        ? navigate(
            `/dashboard/account-details?details=${JSON.stringify(details)}`,
            {
              replace: true,
            }
          )
        : navigate(
            `/dashboard/account-details?details=${JSON.stringify(details)}`
          );
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [authorizations, exchangeRate, accountDetails]);

  const destinations = [
    {
      title: "NGN",
      desc: "Nigerian Naira",
      short: "NG",
      onClick: () => {
        _getVirtualAccounts();
        handleRedirect();
      },
    },
    {
      title: "CAD",
      desc: "Coming Soon",
      short: "CAD",
      onClick: () => {
        console.log("clicked cad");
      },
    },
    {
      title: "GBP",
      short: "GBP",
      desc: "Coming Soon",
      onClick: () => {},
    },
    {
      title: "EUR",
      short: "EUR",
      desc: "Coming Soon",
      onClick: () => {},
    },
    // {
    //   title: "CAD",
    //   flag: CanadaFlag,
    //   short: "CAD",
    //   desc: "Coming Soon",
    //   onClick: () => {},
    // },
    // {
    //   title: "AED",
    //   flag: UAEFlag,
    //   short: "AED",
    //   desc: "Coming Soon",
    //   onClick: () => {},
    // },
  ];

  useEffect(() => {
    const _active = searchParams.get("active");
    //setSearchParams({});
    if (_active && _active === "NGN") {
      setIsExternalRedirect(true);
      setActive(destinations[0]);
      destinations[0]?.onClick();
      return;
    }
  }, []);

  return (
    <>
      <div className={styles.receive}>
        <div className={styles.receive__content}>
          <div onClick={() => navigate(-1)} className={styles.receive__back}>
            <img src={BackIcon} alt="back icon" />
          </div>

          <h3 className={styles.receive__heading}>Receive</h3>
          <p className={styles.receive__desc}>
            Receive money from anyone in any of these currencies and have it
            deposited in your crypto wallet automatically.
          </p>

          <div className={styles.receive__destinations}>
            {destinations?.map((destination) => (
              <div
                onClick={() => {
                  setActive(destination);
                  destination?.onClick();
                }}
                className={
                  destination?.desc === "Coming Soon"
                    ? `${styles.receive__destination} ${styles.receive__destination__blurred}`
                    : `${styles.receive__destination}`
                }
              >
                <img src={getIcon(destination?.title)} alt="united states" />
                <div className={styles.receive__destination__content}>
                  <h4>{destination?.title}</h4>
                  <p>{destination?.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BottomNav active="receive" />
      </div>

      {isGettingAccounts ||
      isGettingAuthorizations ||
      isGettingRate ||
      isGettingWhitelist ||
      isLoadingUserDetails ? (
        <Loader />
      ) : null}
      {upgradeRequired && (
        <Modal
          showModal={upgradeRequired}
          setShowModal={setUpgradeRequired}
          cancelDisabled={true}
        >
          <UpgradeAccount setShowModal={setUpgradeRequired} />
        </Modal>
      )}
      {shouldAddDirectDebit && (
        <Modal
          showModal={shouldAddDirectDebit}
          setShowModal={setShouldAddDirectDebit}
        >
          <ReceiveDirectDebit setShowModal={setShouldAddDirectDebit} />
        </Modal>
      )}
    </>
  );
};

export default Receive;
