import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import SelectField from "../SelectCustom/Select";

import { parseEther, parseUnits } from "viem";
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
} from "wagmi";

import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import handleNotify from "../../helpers/handleNotify";
import {
  verifyApproval,
  getSpenderAddress,
  getContractAddress,
} from "../../services";
import styles from "./IncreaseLimit.module.scss";

const IncreaseLimit = ({
  account,
  user,
  setShowIncreaseLimit,
  _getAuthorizations,
  activeAuthorization,
}) => {
  const { chain } = useNetwork();
  const [amount, setAmount] = useState();
  const [amountWithComma, setAmountWithComma] = useState();
  const [enabled, setEnabled] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [contractAddress, setContractAddress] = useState();
  const [tokenUnit, setTokenUnit] = useState();
  const selectOptions = [activeAuthorization?.tokenName];
  const [token, setToken] = useState(selectOptions[0]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activeAuthorization) {
      setRecipientAddress(activeAuthorization?.spenderAddress);
    }
  }, [activeAuthorization]);

  const getNetwork = (name) => {
    switch (name) {
      case "matic":
        return "MATIC_MAINNET";
      case "bsc":
        return "BSC_MAINNET";
      case "arbitrum":
        return "ARBITRUM_MAINNET";
      default:
        return name;
    }
  };

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: erc20ABI,
    functionName: "approve",
    enabled: contractAddress ? true : false,
    args: [recipientAddress, parseUnits(`${amount ?? 0}`, tokenUnit)],

    onSuccess(data) {
      console.log(data, "success");
    },
    onError(error) {
      console.log(error, "error");
    },
    //value: parseEther(`${amount}`),
  });

  const { isLoading, mutate } = useMutation(
    ["verify approval"],
    (payload) => verifyApproval(payload),
    {
      onSuccess: (data) => {
        console.log(data, "verify approval response");
        handleNotify("success", "Approval  transfer completed");

        setLoading(false);
        setShowIncreaseLimit(false);
        _getAuthorizations();
      },
      onError: (error) => {
        console.log({ error });
        setLoading(false);
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const {
    data: writeContractResult,
    write,
    error,
    isLoading: isWritingTransaction,
  } = useContractWrite(config);
  // {
  //   addressOrName: recipientAddress,
  //   contractInterface: erc20ABI,
  // },
  // "approve",
  // { args: [contractAddress, amount] }

  const { isLoading: transactionLoading, isSuccess: transactionSuccess } =
    useWaitForTransaction({
      hash: writeContractResult?.hash,
    });

  useEffect(() => {
    if (transactionSuccess) {
      console.log("success");
      setLoading(true);
      setTimeout(() => {
        mutate({
          id: activeAuthorization?.id,
          tokenName: token,
          amountLimit: amount,
          network: getNetwork(chain?.network),
        });
      }, 10000);
    }
    if (error) {
      setShowIncreaseLimit(false);
      handleNotify(
        "error",
        error?.error?.shortMessage ||
          error?.error?.message ||
          "An error occurred"
      );
    }
  }, [transactionSuccess, error, writeContractResult]);

  //call write
  useEffect(() => {
    if (contractAddress) {
      console.log("write called");
      // { args: 2, overrides: { value: parseEther(`0`) } }s
      write?.();
    }
  }, [contractAddress, write]);

  const { isLoading: isGettingContractAddress, refetch: _getContractAddress } =
    useQuery(
      ["get contract address", token],
      () => getContractAddress(getNetwork(chain?.network), token),
      {
        enabled: enabled,
        onSuccess: (data) => {
          console.log(data, "contract address");
          setContractAddress(data?.contractAddress);
          setTokenUnit(data?.tokenUnits);
        },
        onError: (error) => {
          console.log({ error });
          handleNotify(
            "error",
            error?.response?.data?.message ?? "An error occurred"
          );
        },
      }
    );

  const increaseLimit = async () => {
    console.log(amount);
    if (!account) {
      handleNotify("error", "Please connect to the wallet first.");
      return;
    }

    if (!amount || !token) {
      handleNotify("error", "Amount and token is required");
      return;
    }

    setEnabled(true);
    _getContractAddress();
  };

  const handleAmountChange = (_amount) => {
    const _amountFormatted = _amount.toString().replace(/\,/g, "");
    if (isNaN(Number(_amountFormatted))) {
      return;
    }
    setAmountWithComma(Number(_amountFormatted)?.toLocaleString());
    setAmount(Number(_amountFormatted));
  };

  return (
    <>
      {
        // isGettingSpenderAddress
        false ? (
          <div className={styles.increaseLimit__loader}>
            <p>Loading ...</p>
          </div>
        ) : (
          <div className={styles.increaseLimit}>
            <h3 className={styles.increaseLimit__heading}>
              Increase Direct Debit Limit
            </h3>
            <p className={styles.increaseLimit__desc}>
              This transfer is required for us to be able to debit this account
            </p>
            <div className={styles.increaseLimit__form}>
              <div className={styles.increaseLimit__form__heading}>
                <p>Coin</p>
                <p>Amount (Limit)</p>
              </div>
              <div className={styles.increaseLimit__form__input}>
                <SelectField
                  options={[activeAuthorization?.tokenName]}
                  active={token}
                  setActive={setToken}
                  style={{ background: "transparent" }}
                />
                <div className={styles.increaseLimit__form__input__line}></div>
                <input
                  type="tel"
                  maxLength={12}
                  value={amountWithComma}
                  onChange={(e) => handleAmountChange(e?.target?.value)}
                />
              </div>
              {/* <div className={styles.increaseLimit__input}>
              <label>Amount</label>
              <input
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </div>

            <div className={styles.increaseLimit__input}>
              <label>Token</label>
              <SelectField options={selectOptions} setValue={setToken} />
            </div> */}
            </div>

            <button
              onClick={() => increaseLimit()}
              className={styles.increaseLimit__submit}
            >
              {isLoading ||
              loading ||
              transactionLoading ||
              isGettingContractAddress ||
              isWritingTransaction ? (
                <ReactLoading type="spin" width={32} height={32} />
              ) : (
                "Increase"
              )}
            </button>
          </div>
        )
      }
    </>
  );
};

export default IncreaseLimit;
