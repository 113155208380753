import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CardDetails.module.scss";

//card images
import CardReaderImage from "../../../assets/icons/card-reader.svg";
import CardProviderImage from "../../../assets/icons/card-provider.svg";
import WifiIcon from "../../../assets/icons/wifi.svg";
import SpendLogo from '../../../assets/images/knit logo 2 2.svg'

//action images
import BackIcon from "../../../assets/icons/back.svg";
import CopyIcon from "../../../assets/icons/copy-4.svg";

const CardDetails = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.cardDetails}>
        <div onClick={() => navigate(-1)} className={styles.cardDetails__back}>
          <img src={BackIcon} alt="back icon" />
        </div>
        <div className={styles.cardDetails__select}>
          <div className={styles.cardDetails__select__content}>
            <p>GBP - Travel</p>
          </div>
        </div>

        <div className={styles.cardDetails__cards}>
          <div className={styles.cardDetails__card}>
            <div className={styles.cardDetails__card__heading}>
              <img src={SpendLogo} alt="spend logo" />
              <p>7y6z</p>
            </div>

            <div className={styles.cardDetails__card__reader}>
              <img src={CardReaderImage} alt="card reader" />
              <img src={WifiIcon} alt="wifi" />
            </div>

            <div className={styles.cardDetails__card__details}>
              <h3>**** **** **** 5132</h3>
              <img src={CardProviderImage} alt="card provider" />
            </div>
          </div>
        </div>

        <div className={styles.cardDetails__details}>
          <div className={styles.cardDetails__detail}>
            <div>
              <h3 className={styles.cardDetails__detail__heading}>
                Card number
              </h3>
              <p className={styles.cardDetails__detail__content}>
                5168 5901 6038 5132
              </p>
            </div>
            <div className={styles.cardDetails__detail__copy}>
              <img src={CopyIcon} alt="copy detail" />
            </div>
          </div>

          <div className={styles.cardDetails__detail}>
            <div>
              <h3 className={styles.cardDetails__detail__heading}>
                Card number
              </h3>
              <p className={styles.cardDetails__detail__content}>
                5168 5901 6038 5132
              </p>
            </div>
            <div className={styles.cardDetails__detail__copy}>
              <img src={CopyIcon} alt="copy detail" />
            </div>
          </div>

          <div className={styles.cardDetails__detail}>
            <div>
              <h3 className={styles.cardDetails__detail__heading}>
                Card number
              </h3>
              <p className={styles.cardDetails__detail__content}>
                5168 5901 6038 5132
              </p>
            </div>
            <div className={styles.cardDetails__detail__copy}>
              <img src={CopyIcon} alt="copy detail" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetails;
