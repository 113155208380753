import React from "react";
import dateFormat from "dateformat";
import styles from "./TransactionGroup.module.scss";

import Transaction from "../Transaction/Transaction";

const TransactionGroup = ({ transactions, label }) => {
  const getLabel = (label) => {
    const _dayLabel = dateFormat(label, "DDDD");

    if (_dayLabel === "Today" || _dayLabel === "Yesterday") {
      return _dayLabel;
    }
    return dateFormat(label, "dd mmmm, yyyy");
  };
  return (
    <>
      {transactions ? (
        <div className={styles.transactionGroup}>
          <h3 className={styles.transactionGroup__heading}>
            {getLabel(label)}
          </h3>
          <div className={styles.transactionGroup__content}>
            {transactions?.map((transaction) => (
              <Transaction transaction={transaction} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TransactionGroup;
