import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getWhitelist } from "../../services";
import { toast } from "react-toastify";
import getIcon from "../../helpers/getIcon";
import styles from "./AccountDetails.module.scss";

//images and icons
import BackIcon from "../../assets/icons/back.svg";
import Copy from "../../assets/icons/copy.svg";
import InfoIcon from "../../assets/icons/info-fill.svg";

const AccountDetails = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountDetails, setAccountDetails] = useState();
  const [defaultWhitelist, setDefaultWhitelist] = useState();

  const handleCopy = (details) => {
    navigator.clipboard.writeText(details);
    toast.success("Account number copied!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const details = searchParams.get("details");
    if (details) {
      setAccountDetails(JSON.parse(details));
      return;
    }
    navigate("/dashboard/receive");
  }, []);

  const {} = useQuery(["get whitelist"], getWhitelist, {
    onSuccess: (data) => {
      console.log(data, "whitelist");
      const _defaultWhitelist = data?.find((item) => item?.isDefault);

      if (_defaultWhitelist) {
        setDefaultWhitelist(_defaultWhitelist?.address);
      }
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  return (
    <>
      <div className={styles.accountDetails}>
        <div
          onClick={() => navigate(-1)}
          className={styles.accountDetails__back}
        >
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.accountDetails__heading}>Receive</h3>
        <p className={styles.accountDetails__desc}>
          Receive money from anyone in any of these currencies and have
          deposited in your self-custody crypto wallet automatically.
        </p>

        <div className={styles.accountDetails__details}>
          <div className={styles.accountDetails__details__heading}>
            <div className={styles.accountDetails__details__heading__currency}>
              <img
                src={getIcon(accountDetails?.account?.currency)}
                alt="currency icon"
              />
              <h4>{accountDetails?.account?.currency}</h4>
            </div>

            <div
              onClick={() => handleCopy(accountDetails?.account?.accountNumber)}
              className={styles.accountDetails__details__heading__copy}
            >
              <p>Copy Details</p>
              <img src={Copy} alt="copy" />
            </div>
          </div>
          <div className={styles.accountDetails__detail}>
            <h4>Bank</h4>
            <p>{accountDetails?.account?.bankName}</p>
          </div>

          <div className={styles.accountDetails__detail}>
            <h4>Account number</h4>
            <p>{accountDetails?.account?.accountNumber}</p>
          </div>

          <div className={styles.accountDetails__detail}>
            <h4>Account name</h4>
            <p>
              {accountDetails?.account?.accountName
                ?.replace("PAYAZA(", "")
                ?.replace(")", "")}
            </p>
          </div>
          {/* 
          <div className={styles.accountDetails__detail}>
            <h4>Exchange Rate</h4>
            <p>
              {`1${accountDetails?.tokenName ?? "USD"} ~ ${
                accountDetails?.exchangeRate?.buyRate
              }₦`}
            </p>
          </div> */}

          <div className={styles.accountDetails__info}>
            <img src={InfoIcon} />
            <p>
              {defaultWhitelist
                ? `NGN will be deposited instantly into your default Whitelist wallet ${defaultWhitelist?.slice(
                    0,
                    6
                  )}...${defaultWhitelist?.slice(-6)} as ${
                    accountDetails?.tokenName ?? "-"
                  } at `
                : `NGN will be deposited instantly into your default Direct Debit wallet ${accountDetails?.walletAddress?.slice(
                    0,
                    6
                  )}...${accountDetails?.walletAddress?.slice(-6)} as ${
                    accountDetails?.tokenName ?? "-"
                  } at `}
              <b>
                {`1${accountDetails?.tokenName ?? "USD"} ~ ${Number(
                  accountDetails?.exchangeRate?.buyRate
                )?.toFixed(2)}₦`}
              </b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetails;
