import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import ReactLoading from "react-loading";
import getSymbol from "../../../helpers/getSymbol";
import { sendFait, sendToMobileMoney } from "../../../services";
import handleNotify from "../../../helpers/handleNotify";
import styles from "./SendOTP.module.scss";

//images and icons
import BackIcon from "../../../assets/icons/back.svg";
import InfoIcon from "../../../assets/icons/info-fill.svg";

//components
import InputPin from "../../../components/InputPin/InputPin";

const SendOTP = () => {
  const [pin, setPin] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const [details, setDetails] = useState({});
  const navigate = useNavigate();

  const { isLoading, mutate: _sendFait } = useMutation(
    ["send fait"],
    (payload) =>
      details?.beneficiaryCurrency === "GHS" ||
      details?.beneficiaryCurrency === "KES"
        ? sendToMobileMoney(payload)
        : sendFait(payload),
    {
      onSuccess: (data) => {
        console.log(data);
        handleNotify("success", "Transaction successful");
        navigate("/dashboard");
      },
      onError: (error) => {
        console.log({ error });
        handleNotify(
          "error",
          error?.response?.data?.message ?? "An error occurred"
        );
      },
    }
  );

  const handleVerification = () => {
    if (!pin || pin?.length !== 6) {
      handleNotify("error", "Kindly provided a valid OTP code");
      return;
    }

    _sendFait({
      ...details,
      amount: `${details?.amount}`,
      transactionPin: pin,
      mobileNumber: `+${decodeURIComponent(details?.mobileNumber)?.trim()}`,
    });
  };

  useEffect(() => {
    const _details = searchParams.get("details");
    if (_details) {
      setDetails(JSON.parse(_details));
      return;
    }
    navigate("-1");
  }, [navigate, searchParams]);

  useEffect(() => {
    if (pin?.length === 6) {
      handleVerification();
    }
  }, [pin]);

  return (
    <>
      <div className={styles.sendOtp}>
        <div onClick={() => navigate(-1)} className={styles.sendOtp__back}>
          <img src={BackIcon} alt="back icon" />
        </div>

        <h3 className={styles.sendOtp__heading}>Enter Pin</h3>
        <p className={styles.sendOtp__desc}>
          Enter your 6 digit pin to complete transaction.
        </p>

        <div className={styles.sendOtp__info}>
          <img src={InfoIcon} />
          <p>
            <b>
              {Number(details?.amount / details?.rate)
                ?.toFixed(2)
                ?.toLocaleString()}
              {details?.tokenName ?? ""}
            </b>{" "}
            to{" "}
            <b>
              {getSymbol(details?.currency ?? details?.beneficiaryCurrency)}
              {Number(details?.amount)?.toLocaleString()}
            </b>{" "}
            ({details?.accountName ?? details?.beneficiaryName})
          </p>
        </div>

        <div className={styles.sendOtp__input}>
          <InputPin
            value={pin}
            setValue={setPin}
            style={{ background: "#fff" }}
          />
        </div>

        <button
          onClick={() => handleVerification()}
          className={styles.sendOtp__submit}
        >
          {isLoading ? (
            <ReactLoading type="spin" width={28} height={28} />
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </>
  );
};

export default SendOTP;
