import userEvent from "@testing-library/user-event";
import React, { useState, useRef, useEffect } from "react";
import styles from "./InputPin.module.scss";

const InputPin = ({ value, setValue, ...props }) => {
  const [PinOne, setPinOne] = useState();
  const [PinTwo, setPinTwo] = useState();
  const [PinThree, setPinThree] = useState();
  const [PinFour, setPinFour] = useState();
  const [PinFive, setPinFive] = useState();
  const [PinSix, setPinSix] = useState();

  const PinOneRef = useRef(null);
  const PinTwoRef = useRef(null);
  const PinThreeRef = useRef(null);
  const PinFourRef = useRef(null);
  const PinFiveRef = useRef(null);
  const PinSixRef = useRef(null);

  const refs = [
    PinOneRef,
    PinTwoRef,
    PinThreeRef,
    PinFourRef,
    PinFiveRef,
    PinSixRef,
  ];

  const style = {};

  const updatePin = (e, index, setStateFunction) => {
    const value = e.target.value;
    if (isNaN(value) || isNaN(parseFloat(value))) {
      setStateFunction("");
      return;
    }

    if (index < 6 && value.length > 0) {
      setStateFunction(value);
      refs[index === 5 ? index : index + 1].current?.focus();
    }
  };

  const handleKeyDown = (e, index, setStateFunction) => {
    if ((e.key === "Backspace" || e.key === "Delete") && index > 0) {
      setStateFunction("");
      refs[index === 0 ? index : index - 1].current?.focus();
    }
  };

  useEffect(() => {
    if (PinOne && PinTwo && PinThree && PinFour && PinFive && PinSix) {
      setValue(`${PinOne}${PinTwo}${PinThree}${PinFour}${PinFive}${PinSix}`);
    }
  }, [PinOne, PinTwo, PinThree, PinFour, PinFive, PinSix, setValue]);

  return (
    <div className={styles?.inputPin}>
      <input
        {...props}
        ref={PinOneRef}
        value={PinOne}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 0, setPinOne)}
        onKeyDown={(e) => handleKeyDown(e, 0, setPinOne)}
      />
      <input
        {...props}
        ref={PinTwoRef}
        value={PinTwo}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 1, setPinTwo)}
        onKeyDown={(e) => handleKeyDown(e, 1, setPinTwo)}
      />
      <input
        {...props}
        ref={PinThreeRef}
        value={PinThree}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 2, setPinThree)}
        onKeyDown={(e) => handleKeyDown(e, 2, setPinThree)}
      />
      <input
        {...props}
        ref={PinFourRef}
        value={PinFour}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 3, setPinFour)}
        onKeyDown={(e) => handleKeyDown(e, 3, setPinFour)}
      />
      <input
        {...props}
        ref={PinFiveRef}
        value={PinFive}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 4, setPinFive)}
        onKeyDown={(e) => handleKeyDown(e, 4, setPinFive)}
      />
      <input
        {...props}
        ref={PinSixRef}
        value={PinSix}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 5, setPinSix)}
        onKeyDown={(e) => handleKeyDown(e, 5, setPinSix)}
      />
      
    </div>
  );
};

export default InputPin;
