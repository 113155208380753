export default function sendIcon(color) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="primary"
        d="M11 16.5556V11M11 21C9.02219 21 7.08879 20.4135 5.4443 19.3147C3.79981 18.2159 2.51809 16.6541 1.76121 14.8268C1.00433 12.9996 0.8063 10.9889 1.19215 9.0491C1.578 7.10929 2.53041 5.32746 3.92894 3.92894C5.32746 2.53041 7.10929 1.578 9.0491 1.19215C10.9889 0.8063 12.9996 1.00433 14.8268 1.76121C16.6541 2.51809 18.2159 3.79981 19.3147 5.4443C20.4135 7.08879 21 9.02219 21 11C21 13.6522 19.9464 16.1957 18.0711 18.0711C16.1957 19.9464 13.6522 21 11 21ZM11.7667 5.85556L14.1444 9.33334C14.2374 9.48835 14.2911 9.66369 14.3009 9.84416C14.3108 10.0246 14.2764 10.2048 14.2009 10.369C14.1254 10.5332 14.0109 10.6765 13.8675 10.7864C13.724 10.8964 13.556 10.9697 13.3778 11H8.62223C8.44405 10.9697 8.27596 10.8964 8.13253 10.7864C7.98909 10.6765 7.87465 10.5332 7.79912 10.369C7.72359 10.2048 7.68925 10.0246 7.69909 9.84416C7.70892 9.66369 7.76263 9.48835 7.85556 9.33334L10.2333 5.86667C10.3149 5.73762 10.4275 5.63113 10.561 5.55697C10.6944 5.48282 10.8443 5.44338 10.997 5.44227C11.1496 5.44116 11.3001 5.47843 11.4346 5.55064C11.5691 5.62286 11.6833 5.72771 11.7667 5.85556Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
