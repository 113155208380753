import React, { useState } from "react";
import styles from "./AddCard.module.scss";

const AddCard = () => {
  const [cardLabel, setCardLabel] = useState();
  return (
    <div className={styles.addCard}>
      <h3 className={styles.addCard__heading}>Add Virtual Card</h3>
      <p className={styles.addCard__desc}>
        Create a new virtual card and give it a label.
      </p>

      <div className={styles.addCard__input}>
        <label>Card Label</label>
        <input
          value={cardLabel}
          onChange={(e) => setCardLabel(e.target.value)}
        />
      </div>

      <button className={styles.addCard__submit}>Add Virtual Card</button>
    </div>
  );
};

export default AddCard;
