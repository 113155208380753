import React, { cloneElement, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { userDetails, getWallets } from "../services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Layout.module.scss";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [newPin, setNewPin] = useState("");

  const {
    isLoading: isLoadingUserDetails,
    data,
    refetch: getUserDetails,
  } = useQuery(["get user details"], userDetails, {
    enabled: enabled,
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error) => {
      console.log({ error });
      if (
        error?.response?.data?.message === "Unauthorized" ||
        error?.response?.data?.message === "Unauthorised" ||
        error.response.status === 401 ||
        error?.response?.data?.statusCode === 401 ||
        error.response.status === 403 ||
        error?.response?.data?.statusCode === 403
      ) {
        setEnabled(false);
        navigate("/auth/login");
      }
      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  // const {
  //   isLoading: isLoadingWallets,
  //   data: wallets,
  //   refetch: _getWallets,
  // } = useQuery(["get wallets"], getWallets, {
  //   enabled: enabled,
  //   onSuccess: (data) => {
  //     console.log(data, "wallets");
  //   },
  //   onError: (error) => {
  //     console.log({ error });
  //     if (error?.response?.data?.message === "Unauthorized") {
  //       navigate("/auth/login");
  //     }
  //     toast.error(error?.response?.data?.message ?? "An error occurred", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "dark",
  //     });
  //   },
  // });

  return (
    <div className={styles.layout}>
      <div className={styles.layout__main}>
        {" "}
        {cloneElement(children, {
          data,
          isLoadingUserDetails,
          getUserDetails,
          setEnabled,
          // _getWallets,
          // wallets,
          newPin,
          setNewPin,
        })}
      </div>
    </div>
  );
};

export default Layout;
