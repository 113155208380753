import React from "react";
import styles from "./NotAvailable.module.scss";

//images
import SadFaceImage from "../../assets/images/sad emoji 1 (1).png";
import SpendLogo from "../../assets/images/knit-logo-dark.svg";

const NotAvailable = () => {
  return (
    <div className={styles.notAvailable}>
      <div className={styles.notAvailable__logo}>
        <img alt="spend logo" src={SpendLogo} />
        {/* <h4>Knit</h4> */}
      </div>
      <div className={styles.notAvailable__main}>
        <div className={styles.notAvailable__main__content}>
          <img src={SadFaceImage} alt="sad face" />
          {/* ☹️ */}
          <h1> Oh no!</h1>
          <p>
            Knit is only optimised for mobile at this time. Please visit Knit
            on your mobile phone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotAvailable;
