import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import dateFormat from "dateformat";
import html2pdf from "html2pdf.js";
import handleNotify from "../../../helpers/handleNotify";
import { formatUnits } from "viem";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { getTransaction } from "../../../services/index";
import getSymbol from "../../../helpers/getSymbol";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./TransactionDetails.module.scss";

//icons
import BackIcon from "../../../assets/icons/back.svg";
import Logo from "../../../assets/images/knit-logo-dark.svg";
import TypeArrow from "../../../assets/icons/left-arrow-circle.svg";
import ShareIcon from "../../../assets/icons/share.svg";

const TransactionDetails = ({ data: userData }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionId, setTransactionId] = useState();
  const [imageIsBroken, setImageIsBroken] = useState(false);
  const [countdown, setCountdown] = useState();
  const [secondsRemaining, setSecondsRemaining] = useState();
  const [minutesRemaining, setMinutesRemaining] = useState();
  const [downloadPDF, setDownloadPDF] = useState(false);
  const interval = useRef();

  //get latest btc value to naira
  const { data: transaction, isLoading } = useQuery(
    ["get transaction", transactionId],
    () => getTransaction(transactionId),
    {
      enabled: transactionId ? true : false,
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);

    toast.success("Item copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  useEffect(() => {
    const id = searchParams.get("id");

    if (id) {
      setTransactionId(id);
      return;
    }
    navigate(-1);
  }, []);

  useEffect(() => {
    if (transaction?.createdAt) {
      const timeDiff = Math.abs(
        new Date(transaction?.createdAt)?.getTime() - new Date()?.getTime()
      );
      const timeDiffSec = Math.ceil(timeDiff / 1000);
      setCountdown(timeDiffSec);
    }
  }, [transaction?.createdAt]);

  useEffect(() => {
    if (countdown) {
      const handleCountdown = () => {
        const timeDiff = Math.abs(
          new Date(transaction?.createdAt)?.getTime() - new Date()?.getTime()
        );

        const _countdown = Math.ceil(timeDiff / 1000);
        const _time = 15 * 60 - _countdown > 0 ? 15 * 60 - _countdown : 0;
        const minutes = _time > 0 ? Math.floor(_time / 60) : 0;
        const seconds = _time > 0 ? _time - minutes * 60 : 0;

        setMinutesRemaining(minutes);
        setSecondsRemaining(seconds);
        console.log(minutes, seconds);
      };
      handleCountdown();
      interval.current = setInterval(() => {
        handleCountdown();
      }, 1000);

      if (15 * 60 - countdown < 1) {
        clearInterval(interval?.current);
      }
    }
  }, [countdown, secondsRemaining, minutesRemaining]);

  const getColorCode = (status) => {
    const colorCode = {
      pending: {
        color: "#333333",
        background: "#33333309",
      },
      success: {
        color: " #27AE60 ",
        background: "#27ae5f09",
      },
      successful: {
        color: " #27AE60 ",
        background: "#27ae5f09",
      },
      failed: {
        color: "#E74C3C",
        background: "#E74C3C09",
      },
      unsuccessful: {
        color: "#E74C3C",
        background: "#E74C3C09",
      },
      initiated: {
        color: "#333333",
        background: "#33333309",
      },
      processing: {
        color: "#ffc400",
        background: "#ffc40009",
      },
    };
    return colorCode[status];
  };

  const getNetwork = (name) => {
    switch (name) {
      case "MATIC_MAINNET":
        return "Polygon";
      case "BSC_MAINNET":
        return "Binance Smart Chain";
      case "ARBITRUM_MAINNET":
        return "arbitrum";
      default:
        return name;
    }
  };

  const generatePDF = () => {
    handleNotify("success", "Transaction PDF download in progress");

    const content = document.getElementById("transaction"); // Replace 'transaction-details' with the ID of your component

    const options = {
      margin: 0,
      enableLinks: true,
      filename: "transaction.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(content)
      .set(options)
      .outputPdf((pdf) => {
        const blob = new Blob([pdf], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = options.filename;
        link.click();
      })
      .save();
    setDownloadPDF(false);
  };

  return (
    <>
      <div className={styles.transactionDetails}>
        <div className={styles.transactionDetails__actions}>
          <div
            onClick={() => navigate(-1)}
            className={styles.transactionDetails__back}
          >
            <img src={BackIcon} alt="back icon" />
          </div>

          <div
            onClick={() => {
              setDownloadPDF(true);
              setTimeout(() => {
                generatePDF();
              }, 100);
            }}
            className={styles.transactionDetails__share}
          >
            <img src={ShareIcon} alt="share icon" />
          </div>
        </div>
        <div id="transaction" className={styles.transactionDetails__main}>
          <div className={styles.transactionDetails__insight}>
            <div>
              <h2 className={styles.transactionDetails__insight__amount}>
                {transaction?.type === "DEBIT" ? "-" : "+"}
                {getSymbol(transaction?.fiatCurrency)}
                {Number(
                  Number(transaction?.fiatAmount)?.toFixed(2)
                )?.toLocaleString()}
              </h2>
              <p className={styles.transactionDetails__insight__account}>
                {transaction?.tokenAmount && transaction?.tokenUnits
                  ? formatUnits(
                      transaction?.tokenAmount,
                      transaction?.tokenUnits
                    )
                  : "-"}
                {transaction?.tokenName}
              </p>
              <h6 className={styles.transactionDetails__insight__date}>
                {transaction?.type === "DEBIT" ? "Sent to" : "Received from"}{" "}
                {transaction?.info ?? "UNKNOWN"}
              </h6>
            </div>
            <div className={styles.transactionDetails__insight__logoBox}>
              {transaction?.logo || imageIsBroken ? (
                <img
                  className={styles.transactionDetails__insight__logoBox__image}
                  src={transaction?.logo}
                  alt="brand logo"
                  onError={(e) => setImageIsBroken(true)}
                />
              ) : (
                <div
                  className={styles.transactionDetails__insight__logoBox__logo}
                >
                  <h3>
                    {transaction?.info
                      ? `${transaction?.info[0]}${transaction?.info[1]}`
                      : `Ni`}
                  </h3>
                </div>
              )}
              <div
                className={
                  transaction?.category === "SEND" ||
                  transaction?.type === "DEBIT"
                    ? `${styles.transactionDetails__insight__logoBox__arrow}`
                    : `${styles.transactionDetails__insight__logoBox__arrow} ${styles.transactionDetails__insight__logoBox__arrow__received}`
                }
              >
                <img src={TypeArrow} alt="arrow type" />
              </div>
            </div>
          </div>
          {transaction &&
          transaction?.paymentMethod === "TRANSFER" &&
          transaction?.status === "INITIATED" ? (
            countdown > 0 && minutesRemaining < 1 && secondsRemaining < 1 ? (
              <div className={styles.transactionDetails__warning}>
                <h3 style={{ color: "#E74C3C" }}>
                  Address generated for this transaction has expired!
                </h3>
                <p>Please initiate another transaction</p>
              </div>
            ) : (
              <div className={styles.transactionDetails__warning}>
                <h3>
                  Wallet address and transaction will expire in{" "}
                  <b
                    style={
                      countdown > 0 &&
                      minutesRemaining < 1 &&
                      secondsRemaining < 1
                        ? { color: "tomato" }
                        : {}
                    }
                  >
                    <span>{minutesRemaining ?? 0}</span>:
                    <span>{secondsRemaining ?? 0}</span>
                  </b>
                </h3>
                <p>
                  Please only send crypto to this address within the stipulated
                  time.
                </p>
              </div>
            )
          ) : null}

          <div className={styles.transactionDetails__memo}>
            <h4>Note</h4>
            <p>{transaction?.note ?? "-"}</p>
          </div>
          <div className={styles.transactionDetails__info}>
            <div className={styles.transactionDetails__info__content}>
              <h4>Timestamp</h4>
              <p> {dateFormat(transaction?.createdAt, "mmm dS, h:MM TT")} </p>
            </div>
          </div>
          <div className={styles.transactionDetails__info}>
            <div className={styles.transactionDetails__info__content}>
              <h4>Status</h4>
              <p
                style={{
                  background: getColorCode(
                    transaction?.status?.toLowerCase() ?? "pending"
                  )?.background,
                  color: getColorCode(
                    transaction?.status?.toLowerCase() ?? "pending"
                  )?.color,
                }}
                className={styles.transactionDetails__info__content__status}
              >
                {transaction?.status ?? "-"}
              </p>
            </div>
          </div>
          <div
            className={styles.transactionDetails__info}
            onClick={() => handleCopy(transaction?.transactionHash ?? "")}
          >
            <div className={styles.transactionDetails__info__content}>
              <h4>Transaction Hash</h4>
              <p>
                {transaction?.transactionHash
                  ? `${transaction?.transactionHash?.slice(
                      0,
                      5
                    )}...${transaction?.transactionHash?.slice(-5)}`
                  : "-"}
              </p>
            </div>
          </div>
          <div className={styles.transactionDetails__info}>
            <div className={styles.transactionDetails__info__content}>
              <h4>Transaction Type</h4>
              <p>{transaction?.type ?? "-"}</p>
            </div>
          </div>

          <div
            className={styles.transactionDetails__info}
            onClick={() =>
              handleCopy(transaction?.walletAuthorizationAddress ?? "")
            }
          >
            {/* <div className={styles.transactionDetails__info__content}>
            <h4>Sender Address</h4>
            <p>
              {transaction?.walletAuthorizationAddress
                ? `...${transaction?.walletAuthorizationAddress?.slice(-12)}`
                : "-"}
            </p>
          </div> */}

            <div className={styles.transactionDetails__info__content}>
              <h4>Address</h4>
              <p>
                {transaction?.walletAuthorizationAddress
                  ? `${transaction?.walletAuthorizationAddress?.slice(
                      0,
                      5
                    )}...${transaction?.walletAuthorizationAddress?.slice(-5)}`
                  : "-"}
              </p>
            </div>
          </div>

          <div className={styles.transactionDetails__info}>
            <div className={styles.transactionDetails__info__content}>
              <h4>Confirmation Count</h4>
              <p>
                {transaction?.confirmationCount
                  ? `${transaction?.confirmationCount}`
                  : "-"}
              </p>
            </div>
          </div>

          {transaction?.sendDetails && (
            <div
              className={`${styles.transactionDetails__info} ${styles.transactionDetails__info__sendDetails}`}
            >
              <div className={styles.transactionDetails__info__content}>
                <h4>Account Name</h4>
                <p>
                  {transaction?.sendDetails?.accountName
                    ? `${transaction?.sendDetails?.accountName}`
                    : "-"}
                </p>
              </div>
              <div className={styles.transactionDetails__info__content}>
                <h4>Account Number</h4>
                <p>
                  {transaction?.sendDetails?.accountNumber
                    ? `${transaction?.sendDetails?.accountNumber}`
                    : "-"}
                </p>
              </div>
              <div className={styles.transactionDetails__info__content}>
                <h4>Amount </h4>
                <p>
                  {transaction?.sendDetails?.amount
                    ? `${getSymbol(
                        transaction?.sendDetails?.beneficiaryCurrency
                      )}${Number(
                        transaction?.sendDetails?.amount
                      )?.toLocaleString()} `
                    : "-"}
                </p>
              </div>
              {transaction?.sendDetails?.mobileNumber && (
                <div className={styles.transactionDetails__info__content}>
                  <h4>Mobile Number</h4>
                  <p>
                    {transaction?.sendDetails?.mobileNumber
                      ? `${transaction?.sendDetails?.mobileNumber}`
                      : "-"}
                  </p>
                </div>
              )}

              <div className={styles.transactionDetails__info__content}>
                <h4>Exchange Rate</h4>
                <p>
                  {transaction?.sendDetails?.exchangeRate
                    ? `${Number(
                        transaction?.sendDetails?.exchangeRate
                      )?.toFixed(2)}`
                    : "-"}
                </p>
              </div>
            </div>
          )}

          {transaction?.sendDetails && (
            <div
              className={`${styles.transactionDetails__info} ${styles.transactionDetails__info__sendDetails}`}
            >
              <div className={styles.transactionDetails__info__content}>
                <h4>Address</h4>
                <p>
                  {transaction?.spenderAddress
                    ? `${transaction?.spenderAddress}`
                    : "-"}
                </p>
              </div>
              <div className={styles.transactionDetails__info__content}>
                <h4>Network</h4>
                <p>
                  {transaction?.networkName
                    ? `${getNetwork(transaction?.networkName)}`
                    : "-"}
                </p>
              </div>
              <div className={styles.transactionDetails__info__content}>
                <h4>Amount</h4>
                <p>
                  {transaction?.tokenAmount &&
                  transaction?.tokenName &&
                  transaction?.tokenUnits
                    ? `${formatUnits(
                        transaction?.tokenAmount,
                        transaction?.tokenUnits
                      )}${transaction?.tokenName}`
                    : "-"}
                </p>
              </div>
            </div>
          )}

          {transaction?.receiveDetails && (
            <div
              className={`${styles.transactionDetails__info} ${styles.transactionDetails__info__sendDetails}`}
            >
              <div className={styles.transactionDetails__info__content}>
                <h4>Sender Name</h4>
                <p>
                  {transaction?.receiveDetails?.senderName
                    ? `${transaction?.receiveDetails?.senderName}`
                    : "-"}
                </p>
              </div>
              <div className={styles.transactionDetails__info__content}>
                <h4>Sender Account Number</h4>
                <p>
                  {transaction?.receiveDetails?.senderAccountNumber
                    ? `${transaction?.receiveDetails?.senderAccountNumber}`
                    : "-"}
                </p>
              </div>
              <div className={styles.transactionDetails__info__content}>
                <h4>Sender Bank</h4>
                <p>
                  {transaction?.receiveDetails?.senderBankName
                    ? `${transaction?.receiveDetails?.senderBankName}`
                    : "-"}
                </p>
              </div>

              <div className={styles.transactionDetails__info__content}>
                <h4>Amount</h4>
                <p>
                  {transaction?.receiveDetails?.amount
                    ? `${getSymbol(
                        transaction?.receiveDetails?.currency
                      )}${Number(
                        transaction?.receiveDetails?.amount
                      )?.toLocaleString()} `
                    : "-"}
                </p>
              </div>

              <div className={styles.transactionDetails__info__content}>
                <h4>Token</h4>
                <p>
                  {transaction?.receiveDetails?.tokenAmount &&
                  transaction?.receiveDetails?.tokenUnits
                    ? formatUnits(
                        transaction?.receiveDetails?.tokenAmount,
                        transaction?.receiveDetails?.tokenUnits
                      )
                    : "-"}
                  {transaction?.receiveDetails?.tokenName
                    ? `${transaction?.receiveDetails?.tokenName}`
                    : "-"}
                </p>
              </div>

              <div className={styles.transactionDetails__info__content}>
                <h4>Payout Status</h4>
                <p
                  style={{
                    background: getColorCode(
                      transaction?.receiveDetails?.status?.toLowerCase() ??
                        "pending"
                    )?.background,
                    color: getColorCode(
                      transaction?.receiveDetails?.status?.toLowerCase() ??
                        "pending"
                    )?.color,
                  }}
                  className={styles.transactionDetails__info__content__status}
                >
                  {transaction?.receiveDetails?.status ?? "-"}
                </p>
              </div>
            </div>
          )}
          {downloadPDF && (
            <div className={styles.transactionDetails__logo}>
              <a href="https://app.useknit.io">
                <img src={Logo} alt="knit logo" />
                <p>Transaction Details</p>
              </a>
            </div>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default TransactionDetails;
