export default function getSymbol(name) {
  switch (name) {
    case "NGN":
      return "₦";
    case "USD":
      return "$";
    case "CAD":
      return "C$";
    case "UAE":
      return "AED";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "GHS":
      return "₵";
    case "KES":
      return "KSh";
    case "KE":
      return "KSh";
    default:
      return null;
  }
}
