import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import handleNotify from "../../helpers/handleNotify";
import styles from "./Failed.module.scss";

import Rocket from "../../assets/images/rocket.svg";
const Failed = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [kycTier, setKycTier] = useState();

  useEffect(() => {
    const _kycTier = searchParams.get("kyc_tier");
    if (_kycTier && _kycTier !== "undefined") {
      setKycTier(_kycTier);
      return;
    }
    handleNotify("error", "KYC tier not found");
  }, []);
  return (
    <div className={styles.failed}>
      {/* <img src={Rocket} alt="rocket illustration" /> */}
      <div className={styles.failed__main}>
        <h3>Ops!</h3>
        <p>KYC verification failed</p>
        <button
          onClick={() => {
            // navigate(`/auth/kyc?kyc_tier=${kycTier}`);
            navigate("/dashboard");
          }}
        >
          {"Back to Dashboard"}
        </button>
      </div>
    </div>
  );
};

export default Failed;
