import React, { useState, useEffect } from "react";
import styles from "./ConfirmPin.module.scss";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { setTransactionPin, getAccessToken } from "../../services";
import cookie from "../../utils/cookie";

//components
import InputPin from "../../components/InputPin/InputPin";

const ConfirmPin = ({
  data: user,
  setEnabled,
  getUserDetails,
  newPin,
  data,
}) => {
  const [pin, setPin] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setEnabled(true);
    getUserDetails();
  }, [setEnabled, getUserDetails]);

  useEffect(() => {
    if (!data || data?.user?.userSettings?.hasSetTransactionPin) {
      navigate("/dashboard")
      // if (
      //   user?.locale === "NG" ||
      //   user?.locale === "KE" ||
      //   user?.locale === "GH"
      // ) {
      //   navigate(`/auth/kyc?kyc_tier=TEASER`);
      // } else {
      //   navigate(`/auth/kyc?kyc_tier=STANDARD`);
      // }
    }
  }, [data, navigate]);

  const { isLoading, mutate: _setTransactionPin } = useMutation(
    ["set transaction pin"],
    (payload) => setTransactionPin(payload),
    {
      onSuccess: (result) => {
        console.log(result, "transaction pin");
        // if (
        //   user?.locale === "NG" ||
        //   user?.locale === "KE" ||
        //   user?.locale === "GH"
        // ) {
        //   navigate(`/auth/kyc?kyc_tier=TEASER`);
        // } else {
        //   navigate(`/auth/kyc?kyc_tier=STANDARD`);
        // }
        navigate("/dashboard")
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const handleSetPin = () => {
    if (!pin || pin?.length !== 6) {
      toast.error("Kindly provided a valid OTP code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (pin !== newPin) {
      toast.error("Pin does not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    _setTransactionPin({
      userId: data?.user?.id,
      transactionPin: newPin,
      transactionPinRepeat: pin,
    });
  };

  useEffect(() => {
    if (!newPin) {
      navigate("/auth/set-pin");
    }
  }, []);

  useEffect(() => {
    if (pin?.length === 6) {
      handleSetPin();
    }
  }, [pin]);

  return (
    <div className={styles.confirmPin}>
      <h2 className={styles.confirmPin__heading}>Confirm Pin</h2>
      <p className={styles.confirmPin__desc}>
        Enter your 6 digit pin to login to your Spend account.
      </p>

      <div className={styles.confirmPin__input}>
        <InputPin value={pin} setValue={setPin} />
      </div>

      <button
        onClick={() => handleSetPin()}
        className={styles.confirmPin__submit}
      >
        {isLoading ? (
          <ReactLoading type="spin" width={28} height={28} />
        ) : (
          "Continue"
        )}
      </button>
    </div>
  );
};

export default ConfirmPin;
