import userEvent from "@testing-library/user-event";
import React, { useState, useRef, useEffect } from "react";
import styles from "./InputPin.module.scss";

const InputPin = ({ value, setValue, ...props }) => {
  const [Pin1, setPin1] = useState();
  const [Pin2, setPin2] = useState();
  const [Pin3, setPin3] = useState();
  const [Pin4, setPin4] = useState();
  const [Pin5, setPin5] = useState();
  const [Pin6, setPin6] = useState();
  const [Pin7, setPin7] = useState();
  const [Pin8, setPin8] = useState();
  const [Pin9, setPin9] = useState();
  const [Pin10, setPin10] = useState();
  const [Pin11, setPin11] = useState();

  const Pin1Ref = useRef(null);
  const Pin2Ref = useRef(null);
  const Pin3Ref = useRef(null);
  const Pin4Ref = useRef(null);
  const Pin5Ref = useRef(null);
  const Pin6Ref = useRef(null);
  const Pin7Ref = useRef(null);
  const Pin8Ref = useRef(null);
  const Pin9Ref = useRef(null);
  const Pin10Ref = useRef(null);
  const Pin11Ref = useRef(null);

  const refs = [
    Pin1Ref,
    Pin2Ref,
    Pin3Ref,
    Pin4Ref,
    Pin5Ref,
    Pin6Ref,
    Pin7Ref,
    Pin8Ref,
    Pin9Ref,
    Pin10Ref,
    Pin11Ref,
  ];

  const updatePin = (e, index, setStateFunction) => {
    const value = e.target.value;
    if (isNaN(value) || isNaN(parseFloat(value))) {
      setStateFunction("");
      return;
    }

    if (index < 11 && value.length > 0) {
      setStateFunction(value);
      refs[index === 10 ? index : index + 1].current?.focus();
    }
  };

  const handleKeyDown = (e, index, setStateFunction) => {
    if ((e.key === "Backspace" || e.key === "Delete") && index > 0) {
      setStateFunction("");
      refs[index === 0 ? index : index - 1].current?.focus();
    }
  };

  useEffect(() => {
    if (
      Pin1 &&
      Pin2 &&
      Pin3 &&
      Pin4 &&
      Pin5 &&
      Pin6 &&
      Pin7 &&
      Pin8 &&
      Pin9 &&
      Pin10 &&
      Pin11
    ) {
      setValue(
        `${Pin1}${Pin2}${Pin3}${Pin4}${Pin5}${Pin6}${Pin7}${Pin8}${Pin9}${Pin10}${Pin11}`
      );
    }
  }, [
    Pin1,
    Pin2,
    Pin3,
    Pin4,
    Pin5,
    Pin6,
    Pin7,
    Pin8,
    Pin9,
    Pin10,
    Pin11,
    setValue,
  ]);

  return (
    <div className={styles?.inputPin}>
      <input
        {...props}
        ref={Pin1Ref}
        value={Pin1}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 0, setPin1)}
        onKeyDown={(e) => handleKeyDown(e, 0, setPin1)}
      />
      <input
        {...props}
        ref={Pin2Ref}
        value={Pin2}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 1, setPin2)}
        onKeyDown={(e) => handleKeyDown(e, 1, setPin2)}
      />
      <input
        {...props}
        ref={Pin3Ref}
        value={Pin3}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 2, setPin3)}
        onKeyDown={(e) => handleKeyDown(e, 2, setPin3)}
      />
      <input
        {...props}
        ref={Pin4Ref}
        value={Pin4}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 3, setPin4)}
        onKeyDown={(e) => handleKeyDown(e, 3, setPin4)}
      />
      <input
        {...props}
        ref={Pin5Ref}
        value={Pin5}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 4, setPin5)}
        onKeyDown={(e) => handleKeyDown(e, 4, setPin5)}
      />
      <input
        {...props}
        ref={Pin6Ref}
        value={Pin6}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 5, setPin6)}
        onKeyDown={(e) => handleKeyDown(e, 5, setPin6)}
      />
      <input
        {...props}
        ref={Pin7Ref}
        value={Pin7}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 6, setPin7)}
        onKeyDown={(e) => handleKeyDown(e, 6, setPin7)}
      />
      <input
        {...props}
        ref={Pin8Ref}
        value={Pin8}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 7, setPin8)}
        onKeyDown={(e) => handleKeyDown(e, 7, setPin8)}
      />
      <input
        {...props}
        ref={Pin9Ref}
        value={Pin9}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 8, setPin9)}
        onKeyDown={(e) => handleKeyDown(e, 8, setPin9)}
      />
      <input
        {...props}
        ref={Pin10Ref}
        value={Pin10}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 9, setPin10)}
        onKeyDown={(e) => handleKeyDown(e, 9, setPin10)}
      />
      <input
        {...props}
        ref={Pin11Ref}
        value={Pin11}
        type={props?.type ?? "tel"}
        maxLength={1}
        onChange={(e) => updatePin(e, 10, setPin11)}
        onKeyDown={(e) => handleKeyDown(e, 10, setPin11)}
      />
    </div>
  );
};

export default InputPin;
