import React, { useState } from "react";
import styles from "./DeleteWallet.module.scss";

const DeleteWallet = ({ removeAuth, setShowConfirmDeleteModal }) => {
  return (
    <div className={styles.deleteWallet}>
      <h3 className={styles.deleteWallet__heading}>
        Are you sure you want to delete this wallet?
      </h3>
      <p className={styles.deleteWallet__desc}>
        You can still create a new authorization with same details.
      </p>
      <div className={styles.deleteWallet__actions}>
        <button
          onClick={() => {
            removeAuth();
            setShowConfirmDeleteModal(false);
          }}
          className={styles.deleteWallet__submit}
        >
          Yes, delete Wallet
        </button>
        <button
          onClick={() => setShowConfirmDeleteModal(false)}
          className={styles.deleteWallet__cancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteWallet;
