import React from "react";
import handleNotify from "../../helpers/handleNotify";
import SelectField from "../SelectVariantTwo/SelectVariantTwo";
import ReactLoading from "react-loading";
import styles from "./CreateWhitelist.module.scss";

const StepOne = ({ network, setNetwork, coin, setCoin, setCurrentStep }) => {
  const networkOptions = [
    { label: "Polygon", value: "MATIC_MAINNET", icon: "MATIC_MAINNET" },
    { label: "BSC", value: "BSC_MAINNET", icon: "BSC_MAINNET" },
  ];
  const coinOptions = [
    //{ label: "USDC", value: "USDC", icon: "USDC" },
    { label: "USDT", value: "USDT", icon: "USDT" },
  ];

  const validateForm = () => {
    if(!network) {
       handleNotify("error", "Network is required");
       return;
    }
    if(!coin) {
       handleNotify("error", "Coin is required");
       return;
    }

    setCurrentStep(2);
  };

  return (
    <div>
      <div className={styles.createWhitelist__form__item}>
        <label>Network</label>
        <SelectField
          options={networkOptions}
          setActive={setNetwork}
          active={network}
          placeholder="Select Network"
        />
      </div>
      <div className={styles.createWhitelist__form__item}>
        <label>Coin</label>
        <SelectField
          options={coinOptions}
          setActive={setCoin}
          active={coin}
          placeholder="Select Coin"
        />
      </div>

      <button onClick={validateForm} className={styles.createWhitelist__submit}>
        {false ? (
          <ReactLoading type="spin" width={32} height={32} />
        ) : (
          "Continue"
        )}
      </button>
    </div>
  );
};

export default StepOne;
