import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//helpers

//stylesheet
import styles from "./VerificationStep.module.scss";

const VerificationStep = ({
  heading,
  limit,
  completed,
  tier,
  documents,
  action,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.verificationStep}>
      <h3 className={styles.verificationStep__heading}>{heading}</h3>
      {documents && (
        <div className={styles.verificationStep__item}>
          <h6>Documents</h6>
          <p>{documents}</p>
        </div>
      )}

      <div className={styles.verificationStep__item}>
        <h6>Limit</h6>
        <p>${limit?.toLocaleString()}/month</p>
      </div>

      {action && (
        <div className={styles.verificationStep__item}>
          <p>{action}</p>
        </div>
      )}

      {completed ? (
        <button className={styles.verificationStep__completed}>
          Completed
        </button>
      ) : tier === "PREMIUM" ? null : (
        <button
          className={styles.verificationStep__update}
          onClick={() => navigate(`/auth/kyc?kyc_tier=${tier}`)}
        >
          Start Verification
        </button>
      )}
    </div>
  );
};

export default VerificationStep;
